import React, { useEffect, useRef, useState } from "react";
import { SystemActionCategory,SystemDataLoadingStatus,SystemFieldType,SystemPageType } from "../../../Model/SystemModels";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { AdminCustomerCard } from "../AdminCustomerCard/AdminCustomerCard";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { useNavigate} from "react-router-dom";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { Loader } from "../../Common/Loader/Loader";
import { AdminCustomerDialog } from "../AdminCustomerDialog/AdminCustomerGroupDialog";
import { SystemCore } from "../../../Core/System/SystemCore";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { IComboBoxOption } from "@fluentui/react";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { CrmSetup, Partner } from "../../../Model/PartnerModels";
import { Customer } from "../../../Model/CustomerModels";
import { t } from "i18next";

export const AdminCustomerList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [customerId,setCustomerId]=useState('');
    const [partners,setPartners]=useState<Partner[]>([] as Partner[]);
    const [showCodeDialog, setShowCodeDialog] = useState(false);
    const [showDsCodeDialog, setShowDsCodeDialog] = useState(false);
    const [dsCmbOptions, setDsCmbOptions] = useState<IComboBoxOption[]>([]);
    const [dialogError, setDialogError] = useState("");
    const [loaderMessage,setLoaderMessage] = useState("");
    const [crmSetup,setCrmSetup] = useState(new CrmSetup());
    let listTitle = "";

    const adminService=new AdminService();
    const customerService = new CustomerService();
    const partnerService = new PartnerService();
    const cardRef = useRef<PageContainerBase>(null);
    const currentPage = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    useEffect(()=>{
        if(dataStatus == SystemDataLoadingStatus.ToLoad){
            buildDsCmbOptions();                        
            adminService.getPartners().then((resp)=>{
                setPartners(resp);
                
                partnerService.getCrmLeadData()
                .then((resp) =>{
                    setCrmSetup(resp);
                })
            })
        }
    },[dataStatus])
    
     const createBaseContract =(customerId:number,name:string)=>{
        setLoaderMessage("Creazione contratto base in corso...");
        setDataStatus(SystemDataLoadingStatus.Loading);
         adminService.createBaseContract(customerId)
         .then((srvData)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Contratto base per "+name+" creato con successo");
         })
         .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
             ToastService.showMessage(MessageType.Error,"Errore nella creazione del contratto: "+jqXHR.message);
         })
     }

     const sendEmailReadyEnvironments = (customer: Customer)=>{
        setLoaderMessage("Invio mail ambienti pronti in corso...");
        setDataStatus(SystemDataLoadingStatus.Loading);
         adminService.sendEmailReadyEnvironments(customer)
         .then((srvData)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Mail inviata con successo");
         })
         .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
             ToastService.showMessage(MessageType.Error,"Errore nell'invio della mail: "+jqXHR.message);
         })
     }
 
     const createFESetupChecklist =(customerId:number,name:string)=>{
        setLoaderMessage("Creazione CheckList Setup FE in corso...");
        setDataStatus(SystemDataLoadingStatus.Loading);
         adminService.createFESetupChecklist(customerId)
         .then((srvData)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"CheckList Setup FE per "+name+" creata con successo");
         })
         .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
             ToastService.showMessage(MessageType.Error,"Errore nella creazione della CheckList Setup FE: "+jqXHR.message);
         })
     }
 
     const createCustomerOnBCEos =(customerId:number,name:string)=>{
        setLoaderMessage("Creazione Cliente in BC EOS in corso...");
        setDataStatus(SystemDataLoadingStatus.Loading);
         adminService.createCustomerOnBCEos(customerId)
         .then((srvData)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Cliente "+name+" creato con successo in BC EOS");
         })
         .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
             ToastService.showMessage(MessageType.Error,"Errore nella creazione del Cliente in BC EOS: "+jqXHR.message);
         })
     }
 
     const syncCustomerOnCrmEos =(customerId:number,name:string)=>{
        setLoaderMessage("Sincronizzazione Cliente nel CRM EOS in corso...");
        setDataStatus(SystemDataLoadingStatus.Loading);
         adminService.syncCustomerOnCrmEos(customerId)
         .then((srvData)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Cliente "+name+" sincronizzato con successo nel CRM EOS");
         })
         .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
             ToastService.showMessage(MessageType.Error,"Errore nella sincronizzazione del Cliente nel CRM EOS: "+jqXHR.message);
         })
     }
 
     const createTaskGroup =(groupCode:string)=>{
        if(groupCode.trim() !== ''){
            adminService.createTaskGroup(groupCode,customerId)
            .then((srvData)=>{
                setShowCodeDialog(false);
                ToastService.showMessage(MessageType.Success,"Gruppo task: \""+groupCode+"\". Creato con successo");
            })
            .catch((jqXHR)=>{
                setShowCodeDialog(false);
                ToastService.showMessage(MessageType.Error,"Errore nella creazione del gruppo task: "+jqXHR.message);
            })
            setDialogError("");
        }
        else
            setDialogError("Devi inserire un Group Code");
     }
 
     const getTenantByCustomerId = (customerId: number, companyName: String) => {
        customerService.getCustomerSetupConfigurationFromCustomerId(customerId).then((resp) => {
            if (resp.tenantId)
                window.open("https://businesscentral.dynamics.com/" + resp.tenantId + "/admin");
            else
                ToastService.showMessage(MessageType.Error, "Tenant Id non configurato per il cliente " + companyName + ", Id: " + customerId);
        })
        .catch((jqXHR) => {
            ToastService.showMessage(MessageType.Error, "Errore nel recupero del Tenant Id: " + jqXHR.message);
        });
    }
 
    const openCustomerOnboardingWorkSheet = (customerId: number, companyName: String) => {
       customerService.getCustomerSetupConfigurationFromCustomerId(customerId).then((resp) => {
           if (resp.tenantId)
               window.open("https://businesscentral.dynamics.com/" + resp.tenantId + "/Production?page=68002");
           else
               ToastService.showMessage(MessageType.Error, "Tenant Id non configurato per il cliente " + companyName + ", Id: " + customerId);
       })
       .catch((jqXHR) => {
           ToastService.showMessage(MessageType.Error, "Errore nel recupero del Tenant Id: " + jqXHR.message);
       });
   }

    const changeCustomerDsStatus = (dsCode:string,customerId:string)=>{
        if(dsCode.trim() !== ''){
            setLoaderMessage("Aggiornamento stato DS in corso, attendere...");
            setDataStatus(SystemDataLoadingStatus.Loading);

            adminService.changeCustomerDsStatus(dsCode,customerId).then((resp)=>{
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                setShowDsCodeDialog(false);

                ToastService.showMessage(MessageType.Success,"Stato DS modificato con successo");
            })
            .catch((jqXHR)=>{
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                setShowDsCodeDialog(false);
                ToastService.showMessage(MessageType.Error,"Errore nella modifica dello stato DS: "+jqXHR);
            })
            setDialogError("");
        }
        else
            setDialogError("Devi inserire uno stato DS valido");
    }

    const buildDsCmbOptions = ()=>{
        setLoaderMessage("Stiamo caricando la lista dei clienti, attendere...");
        setDataStatus(SystemDataLoadingStatus.Loading);
        adminService.getDsStatusValue("Customer").then((resp)=>{
            let options : IComboBoxOption[] = [];
            resp.forEach((item:any)=>{
                options.push({key: item.code, text: item.description});
            })
            setDsCmbOptions(options);
            setDataStatus(SystemDataLoadingStatus.Loaded);
            setLoaderMessage("");
        })
        .catch(()=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            setLoaderMessage("");
            ToastService.showMessage(MessageType.Error,"Errore nel recupero degli stati DS");
        })
    }

    const updateCustomerApps = (customerId: number, companyName: string) => {
        const promise = adminService.updateCustomerApps(customerId);
        ToastService.showPromiseMessage(
          promise,
          "Aggiornamento app per "+companyName+" in corso",
          "Aggiornamento app per "+companyName+" completato con successo",
          "Errore durante l'aggiornamento app per "+companyName
        );
    }

    const scheduleCustomerEnvironments = () => {
        const dsStatus = HttpHelper.getParameter('dsStatus');
        
        if (!dsStatus) {
            ToastService.showMessage(MessageType.Error, "Deve essere selezionato un codice stato, impossibile proseguire");
            return;
        }

        const promise = adminService.scheduleCustomerEnvironments(dsStatus);
        ToastService.showPromiseMessage(
          promise,
          "Schedulazione aggiornamento clienti in stato " +
            dsStatus +
            " in corso",
          "Schedulazione aggiornamento clienti in stato " +
            dsStatus +
            " completata con successo",
          "Errore durante la schedulazione dell'aggiornamento clienti in stato " +
            dsStatus,
          () => {currentPage.current?.reloadData()}
        );
    }
    
    const buildListEndpoint = () : string =>{
        const dsStatus = HttpHelper.getParameter('dsStatus');
        const customerFilter = HttpHelper.getParameter('customerFilter');
        const filterPageName = HttpHelper.getParameter('filterPageName');
        const dsCodeName = HttpHelper.getParameter('dsCodeName');
        let endpoint = "/odata/customer";
        if((dsStatus !== null) && (dsStatus !== "")){
            switch(dsStatus){
                case "10":
                    listTitle +="Lista trial";
                    endpoint+="?$filter=statusCode eq \'10\'";
                    break;
                case "20":
                    listTitle +="Lista trial";
                    endpoint+="?$filter=statusCode eq \'20\'";
                    break;
                case "30":
                    listTitle +="Lista trial";
                    endpoint+="?$filter=statusCode eq \'30\'";
                    break;
                case "35":
                    listTitle +="Lista trial";
                    endpoint+="?$filter=statusCode eq \'35\'";
                    break;
                case "40":
                    listTitle +="Lista trial";
                    endpoint+="?$filter=statusCode eq \'40\'";
                    break;
                case "50":
                    listTitle +="Lista clienti";
                    endpoint+="?$filter=statusCode eq \'50\'";
                    break;
                case "60":
                    listTitle +="Lista clienti";
                    endpoint+="?$filter=statusCode eq \'60\'";
                    break;
                case "70":
                    listTitle +="Lista clienti";
                    endpoint+="?$filter=statusCode eq \'70\'";
                    break;
                case "80":
                    listTitle +="Lista clienti";
                    endpoint+="?$filter=statusCode eq \'80\'";
                    break;
                case "90":
                    listTitle +="Lista clienti";
                    endpoint+="?$filter=statusCode eq \'90\'";
                    break;
            }

            if((dsCodeName !== null) && (dsCodeName !== ""))
                listTitle += " ("+dsCodeName+")";

        }
        else if((customerFilter !== null) && (customerFilter !== "")){
            switch(customerFilter){
                case "risk":
                    listTitle +="Lista clienti";
                    endpoint+="?$filter=cancellationRisk eq true and statusCode ne \'35\' and statusCode le \'80\'";
                    break;
                case "active":
                    listTitle +="Lista clienti attivi";
                    endpoint+="?$filter=statusCode eq \'50\' or statusCode eq \'60\' or statusCode eq \'70\' or statusCode eq \'80\'";
                    break;
            }

            if((filterPageName !== null) && (filterPageName !== ""))
                listTitle += " ("+filterPageName+")";
        }

        return endpoint
    }

    const openCrmAccount = (crmAccountId: string) => {
        if (crmAccountId) {
            const baseAddress = crmSetup.baseAddress.replace("&etn=lead&id=", "&etn=account&id=");
            window.open(baseAddress+crmAccountId);
        }
        else
            ToastService.showMessage(MessageType.Warning, "CRM Account Id non presente");
    }

    const openCrmLead = (leadId: number) => {
        if (leadId > 0) {
            adminService.getLead(leadId)
            .then((resp) => {
                if (resp.crmLeadId)
                    window.open(crmSetup.baseAddress+resp.crmLeadId);
                else
                    ToastService.showMessage(MessageType.Warning, "CRM Lead Id non presente");
            })
            .catch((resp) => {
                console.log(resp.message);
                ToastService.showMessage(MessageType.Error, "Errore nel caricamento della lead associata al cliente");
            })
        }
        else
            ToastService.showMessage(MessageType.Warning, "Lead Id non presente");
    }

    const getCustomerOpenAPIData = (customer: Customer) => {        
        if (!customer.vat) {
            ToastService.showMessage(MessageType.Error, t('leadDashboard:errors:text00007Err')!);
            return;
        }

        const promise = adminService.getCustomerOpenAPIData(customer.id).then((data)=>{
            Object.assign(document.createElement('a'),{ href: 'data:application/json;charset=UTF-8,' + encodeURIComponent(JSON.stringify(data.data[0], null, 2)), download: customer.companyName+ ' advanced data.json'}).click();
        });
        ToastService.showPromiseMessage(promise, "Download in progress", "Download completed", "Error during download");
    }
 
     if (!SystemCore.isFinishedDataStatus(dataStatus))
         return <Loader text={loaderMessage}></Loader>;
     else
        return (
            <>
                <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={listTitle} ref={currentPage} cardRef={cardRef} backAllowed showAllowed updateAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="OpenCrmAccount" label="Account CRM" iconName="CRMServices" isPromoted runOnRec onClick={(req:any) => {
                                openCrmAccount(req.crmAccountId);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="OpenCrmLead" label="Lead CRM" iconName="CRMServices" isPromoted runOnRec onClick={(req:any) => {
                                openCrmLead(req.linkToLeadId);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="OpenBcAdmin" label="Admin Business Central" iconName="Admin" isPromoted runOnRec onClick={(req: any) => {
                                getTenantByCustomerId(req.id, req.companyName);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="OpenOnboardingWorksheet" label="Onboarding Worksheet" iconName="CheckList" isPromoted runOnRec onClick={(req: any) => {
                                openCustomerOnboardingWorkSheet(req.id, req.companyName);
                            }}>
                            </ActionEntry>                            
                            <ActionEntry name="OpenCustomerProgressChecklist" label="Avanzamenti cliente" iconName="CheckList" isPromoted runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/related/customer/progressChecklist?customerId="+req.id+"&name="+req.companyName);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="OpenCustomerInteractionTelemetry" label="Interazioni cliente" iconName="InternalInvestigation" runOnRec onClick={(req:any)=>{
                                    navigate("/app/admin/administrative/interactionTelemetryList?customerId="+req.id+"&name="+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenConfigurationCard" label="Configurazione" iconName="ConfigurationSolid" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/related/customerConfig?customerId='+req.id+'&name='+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenEnviromentCard" label="Ambienti" iconName="Articles" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/related/customerEnv?customerId='+req.id+'&name='+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenCustomerContractsCard" label="Contratti Cliente" iconName="DataConnectionLibrary" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/customerDigitalContract?customerId='+req.id+'&name='+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenUserGeneral List" label="Utenti" runOnRec iconName="People" onClick={(req:any)=>{
                                    navigate("/app/admin/administrative/userGeneral?customerId="+req.id+"&name="+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenCustomerCompany" label="Aziende" iconName="ClipboardList" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/customerCompany?customerId='+req.id+'&name='+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenCustomerDomain" label="Domini" iconName="ClipboardList" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/customerDomain?customerId='+req.id+'&name='+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenCustomerSubscription" label="Sottoscrizioni" iconName="Subscribe" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/subscriptionHeader?subscriptionFilter=customer&customerId='+req.id+'&name='+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenCSPSubscription" label="Sottoscrizioni CSP" iconName="Subscribe" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/cspSubscription?customerId='+req.id+'&name='+req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenLossRiskLine" label="Righe Rischio Perdita" iconName="AlertSolid" runOnRec onClick={(req:any)=>{
                                    navigate("/app/admin/related/lossRiskLine?sourceTable=Customer&sourceId="+req.id+"");
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenContentList" label="Contenuti condivisibili" iconName="Share" onClick={(req:any)=>{
                                    navigate("/app/admin/administrative/contentList");
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenRmrLedgerEntry" label="RMR Ledger Entry" iconName="GroupedList" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/customerRmrLedgerEntry?customerId='+req.id);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenQueuedOperations" label="Coda operazioni" iconName="BuildQueue" runOnRec onClick={(req:any)=>{
                                    const pageParams: string = btoa(JSON.stringify({openBy:'customer',customerId:req.id,customerName:req.companyName}));
                                    navigate('/app/admin/related/queuedOperations?pageParams='+pageParams);
                                }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="GetOpenAPIData" label={t("adminCenter:leadList:actions:downloadOpenAPI")} iconName="CloudDownload" onClick={(req:any)=>{
                                getCustomerOpenAPIData(req);
                            }}></ActionEntry>
                            <ActionEntry name="createBaseContract" label="Crea contratto base" iconName="DataConnectionLibrary" runOnRec onClick={(req:any)=>{
                                createBaseContract(req.id,req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="createFESetupChecklist" label="Crea CheckList Setup FE" iconName="AllApps" runOnRec onClick={(req:any)=>{
                                createFESetupChecklist(req.id,req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="createCustomerOnBCEos" label="Crea Cliente in BC EOS" iconName="Upload" runOnRec onClick={(req:any)=>{
                                createCustomerOnBCEos(req.id,req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="syncCustomerOnCRMEos" label="Sincronizza Cliente in CRM EOS" iconName="Upload" runOnRec onClick={(req:any)=>{
                                syncCustomerOnCrmEos(req.id,req.companyName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="sendEmailReadyEnvironments" label="Invia mail ambienti pronti" iconName="Send" runOnRec onClick={(req:any)=>{
                                sendEmailReadyEnvironments(req);
                                }}>
                            </ActionEntry>
                            {/* <ActionEntry name="updateCustomerKPIs" label="Aggiorna KPI clienti" iconName="SchoolDataSyncLogo" onClick={()=>{
                                adminService.updateCustomersKPI()
                                    .then(() => {
                                        ToastService.showMessage(MessageType.Success, "Operazione completata");
                                    })
                                    .catch((err) => {
                                        ToastService.showMessage(MessageType.Error, "Errore durente l'operazione. Dettaglio: " + err);
                                    })
                            }}>
                            </ActionEntry> */}
                            <ActionEntry name="CreateTaskGroup" label="Crea gruppo task" iconName="TaskGroup" runOnRec onClick={(req:any)=>{
                                setShowCodeDialog(true);
                                setCustomerId(req.id);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="ChangeCustomerDsStatus" label="Cambia stato DS" isPromoted iconName="SyncStatus" runOnRec onClick={(req:any)=>{
                                setCustomerId(req.id);
                                setShowDsCodeDialog(true);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="Challenge" label="Challenge" iconName="AuthenticatorApp" runOnRec onClick={(req:any)=>{
                                adminService.customerChallenge(req.id)
                                    .then((resp) => {
                                        window.open(resp);
                                    })
                                    .catch((err) => {
                                        ToastService.showMessage(MessageType.Error, "Errore durante il challenge: " + err);
                                    })
                            }}>
                            </ActionEntry>
                            <ActionEntry name="customerCreateSoSmartAdminUser" label="Aggiungi Utente Admin SoSmart" iconName="AddFriend" runOnRec onClick={(req:any)=>{
                                setLoaderMessage("Aggiunta Utente Admin SoSmart in corso ...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                adminService.customerCreateSoSmartAdminUser(req.id)
                                    .then((resp) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Success, "Utente Admin SoSmart creato correttamente");
                                    })
                                    .catch((err) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Error, "Errore durante la creazione dell'utente Admin SoSmart: " + err);
                                    })
                            }}>
                            </ActionEntry>
                            <ActionEntry name="syncEnvironments" label="Sincronizzazione Ambienti" iconName="SyncToPC" runOnRec onClick={(req:any)=>{
                                setLoaderMessage("Sincronizzazione Ambienti in corso ...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                adminService.syncEnvironments(req.id)
                                    .then((resp) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Success, "Sincronizzazione Ambienti terminata correttamente");
                                    })
                                    .catch((err) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Error, "Errore durante la Sincronizzazione degli Ambienti: " + err);
                                    })
                            }}>
                            </ActionEntry>
                            <ActionEntry name="copyProductionToEducational" label="Copia Production su Educational" iconName="Copy" runOnRec onClick={(req:any)=>{
                                setLoaderMessage("Copia Production su Educational in corso ...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                adminService.copyProductionToEducational(req.id)
                                    .then((resp) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Success, "Copia Production su Educational schedulata correttamente");
                                    })
                                    .catch((err) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Error, "Errore durante la Copia Production su Educational: " + err);
                                    })
                            }}>
                            </ActionEntry>
                            <ActionEntry name="removeAutorenew" label="Rimuovi Autorenew Licenze Trial" iconName="RemoveFromShoppingList" runOnRec onClick={(req:any)=>{
                                setLoaderMessage("Rimozione Autorenew Licenze Trial in corso ...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                adminService.removeAutorenew(req.id)
                                    .then((resp) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Success, "Rimozione Autorenew Licenze Trial terminata correttamente");
                                    })
                                    .catch((err) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Error, "Errore durante la Rimozione Autorenew Licenze Trial: " + err);
                                    })
                            }}>
                            </ActionEntry>
                            <ActionEntry name="updateCustomerApps" label="Aggiorna app cliente" iconName="AppIconDefaultList" runOnRec onClick={(req:any)=>{
                                updateCustomerApps(req.id, req.companyName);
                            }}></ActionEntry>
                            <ActionEntry name="scheduleEnvironmentUpgrade" label="Schedula upgrade ambienti" iconName="WorkFlow" onClick={(req:any)=>{
                                scheduleCustomerEnvironments();
                            }}></ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Id" fieldName="id" minWidth={30} maxWidth={30} />
                        <ListHeaderEntry name="Versione" fieldName="customerVersion" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                            if (item.customerVersion === 0)
                                return "Empty";
                            else if (item.customerVersion === 10)
                                return "V1.0";
                            else if (item.customerVersion === 20)
                                return "V2.0";
                            else if (item.customerVersion === 30)
                                return "V3.0";
                        }} />
                        <ListHeaderEntry name="Stato DS" fieldName="statusCode" minWidth={60} maxWidth={60} />
                        <ListHeaderEntry name="Nome Azienda" fieldName="companyName" minWidth={100} maxWidth={100} />
                        <ListHeaderEntry name="Nome partner" fieldName="partnerId" minWidth={100} maxWidth={100} onRender={(item: any, index, column)=>{
                            return partners.find(partner => partner.id === item.partnerId)?.name;
                        }}/>
                        <ListHeaderEntry name="Numero Interazioni" fieldName="monthInteractionCount" minWidth={130} maxWidth={130} />
                        <ListHeaderEntry name="Numero ERP Cliente" fieldName="erpCustomerNo" minWidth={130} maxWidth={130} />
                        <ListHeaderEntry name="Id Account CRM" fieldName="crmAccountId" minWidth={230} maxWidth={230} />
                        <ListHeaderEntry name="Id Shop Cliente" fieldName="shopCustomerId" minWidth={100} maxWidth={100} />
                        <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={90} maxWidth={100} />
                        <ListHeaderEntry name="Data aggiornamento" fieldName="updateFrom" minWidth={120} maxWidth={150} />
                        <ListHeaderEntry name="Aggiornamento necessario" fieldName="updateNeeded" minWidth={120} maxWidth={150} />
                        <ListHeaderEntry name="Aggiornamento forzato" fieldName="forceUpdate" minWidth={120} maxWidth={150} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCustomerCard ref={cardRef} />
                <AdminCustomerDialog hidden={showCodeDialog}
                                              title="Inserisci il Group Code"
                                              subText="Devi inserire il group code per poter procedere"
                                              txtLabel="Group Code" 
                                              fieldType={SystemFieldType.String}
                                              onSuccess={(groupCode:string)=>{createTaskGroup(groupCode)}} 
                                              onDismiss={()=>{setShowCodeDialog(false)}}
                                              errorMessage={dialogError} />
                <AdminCustomerDialog hidden={showDsCodeDialog}
                                            title="Inserisci il nuovo stato"
                                            subText="Devi inserire il codice dello stato per poter effettuare il passaggio"
                                            txtLabel="Codice data security:"
                                            cmbOptions={dsCmbOptions} 
                                            fieldType={SystemFieldType.Option}
                                            onSuccess={(dsCode:string)=>{changeCustomerDsStatus(dsCode,customerId)}} 
                                            onDismiss={()=>{setShowDsCodeDialog(false)}}
                                            errorMessage={dialogError} />
            </>
        );
}