import React, { useContext, useRef, useState } from "react";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { SystemActionCategory, SystemPageType } from "../../../Model/SystemModels";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { CommonModalPage } from "../../Common/CommonModalPage/CommonModalPage";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { DistributorInformationContext } from "../../../ApplicationCode/Distributor/DistributorInformationContext";
import { DistributorLeadOfferCard } from "../DistributorLeadOfferCard/DistributorLeadOfferCard";

export const DistributorLeadOfferList : React.FC = () => {
    const navigate = useNavigate();
    
    const adminService = new AdminService();
    
    const currentPage = useRef<PageContainerBase>(null);
    const cardRef = useRef<PageContainerBase>(null);

    const distributorInfoContext = useContext(DistributorInformationContext);
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [offerHtml, setOfferHtml] = useState("");
    
    const parse = require('html-react-parser');
    
    let listTitle = t('partnerCenter:partnerLeadOfferList:title')!;
    let leadId = 0;
    
    const buildListEndpoint = () : string => {
        const leadIdParam = HttpHelper.getParameter("leadId");
        const partnerIdParam  = HttpHelper.getParameter("partnerId");
        
        if (!partnerIdParam || !leadIdParam)
            navigate("/app/distributor/home");
        
        leadId = leadIdParam;
        
        return "/odata/SalesHeaderTemplate?$filter=partnerId eq "+partnerIdParam+" and leadId eq "+leadIdParam;
    }
    
    const getOfferEmail = (salesHeaderTemplateId: number) => {
        adminService.getOfferEmail(salesHeaderTemplateId)
        .then((resp) => {
            setOfferHtml(resp);
            setIsModalOpen(true);
        })
    }
    
    const copyOfferToClipboard = () => {
        let recipient = "";
        let subject = t('partnerCenter:partnerLeadOfferList:email:subject')!
        
        navigator.clipboard.write([
            new ClipboardItem({
                "text/html": new Blob([offerHtml], { type: "text/html" }),
            }),
        ])
        .then(() => {
            adminService.getLead(leadId)
            .then((resp) => {
                recipient = resp.email;
                subject += resp.companyName;
            })
            .catch(() => {
                recipient = "";
            })
            .finally(() => {
                const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
                window.location.href = mailtoLink;
                ToastService.showMessage(MessageType.Success, t('partnerCenter:partnerLeadOfferList:email:success')!);
            })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, t('partnerCenter:partnerLeadOfferList:email:success')!);
        })
    };
    
    const downloadSalesTemplate = (salesHeaderTemplateId: number) => {
        let instance = HttpHelper.buildAxiosInstance();
        let endpoint = "/api/ecommerce/excel/distributor/sales/template/"+salesHeaderTemplateId;
        var promise = new Promise((resolve,reject)=>{
            adminService.getLead(leadId)
            .then((resp) => {
                const leadCompanyName = resp.companyName;
                
                instance.get(endpoint, {responseType: 'blob'})
                .then((resp)=>{
                    if (resp.data.size > 0) {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', "So Smart - Offer for "+leadCompanyName+".xlsx");
                        document.body.appendChild(link);
                        link.click();
                        ToastService.showMessage(MessageType.Success, t('partnerCenter:partnerLeadOfferList:components:downloadSuccess')!);
                        resolve(true);
                    } else {
                        ToastService.showMessage(MessageType.Information, t('partnerCenter:partnerLeadOfferList:components:noOffer')!);
                        resolve(true);
                    }
                })
                .catch((resp)=>{
                    console.error(resp.response.data.message);
                    reject(resp.response.data.message);
                })
            })
            .catch((err) => {
                console.error(err.response.data.message);
                reject(err.response.data.message);
            })
        })
        
        ToastService.showPromiseMessage(promise, t('partnerCenter:partnerLeadOfferList:components:downloadStart')!, "", t('partnerCenter:partnerLeadOfferList:components:downloadError')!);
    }
    
    return (
        <>
            <PageContainer pageType={SystemPageType.List} ref={currentPage} cardRef={cardRef} title={listTitle} endpoint={buildListEndpoint()} backAllowed refreshAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenLeadOfferDetailList" label={t('partnerCenter:partnerLeadOfferList:actions:openLeadOfferDetailList')!} iconName="ClipboardList" isPromoted runOnRec onClick={(req:any)=>{
                            navigate("/app/distributor/leadOfferDetailList?headerId="+req.id);
                        }} />
                        <ActionEntry name="GetOfferEmail" label={t('partnerCenter:partnerLeadOfferList:actions:getOfferEmail')!} iconName="MailAttached" isPromoted runOnRec onClick={(req:any) => {
                            getOfferEmail(req.id);
                        }} />
                    </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="downloadSalesTemplate" label={t('partnerCenter:partnerLeadOfferList:actions:downloadSalesTemplate')!} iconName="Download" isPromoted runOnRec onClick={(req:any) => {
                                downloadSalesTemplate(req.id);
                            }} />
                        </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferList:fields:id')!} fieldName="id" minWidth={50} maxWidth={60} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferList:fields:createdAt')!} fieldName="createdAt" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferList:fields:description')!} fieldName="description" minWidth={500} maxWidth={600} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferList:fields:totalAmount')!} fieldName="totalAmount" minWidth={200} maxWidth={250} onRender={(item: any, index, column) => {
                        const currency = distributorInfoContext.distributor.legalEntityCode === "ESS" ? "$ " : "€ ";
                        return currency + item.totalAmount
                    }} />
                </PageDefinitionContainer>
            </PageContainer>
            <DistributorLeadOfferCard />
            <CommonModalPage
                isOpen={isModalOpen}
                modalTitle={t('partnerCenter:partnerLeadOfferList:modal:title')!}
                primaryButtonEnabled
                primaryButtonText={t('partnerCenter:partnerLeadOfferList:modal:success')!}
                primaryButtonTooltip={t('partnerCenter:partnerLeadOfferList:modal:tooltip')!}
                secondaryButtonEnabled
                secondaryButtonText={t('partnerCenter:partnerLeadOfferList:modal:close')!}
                dragOptionEnabled={false}
                onDismissModal={() => {setIsModalOpen(false)}}
                onConfirmModal={() => {copyOfferToClipboard()}}
                onCancelModal={() => {setIsModalOpen(false)}}
            >
                {parse(''+offerHtml+'')}
            </CommonModalPage>
        </>
    );
}
