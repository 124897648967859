import { forwardRef, useState } from "react";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { SystemFieldType, SystemPageType, SystemUser, SystemUserType } from "../../../Model/SystemModels";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { CardField } from "../../Common/Template/Card/CardField";
import { t } from "i18next";
import { DefaultButton, Dialog, DialogFooter, DialogType, MessageBar, MessageBarType } from "@fluentui/react";

export const DistributorPartnerUserCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null);
    const [userType, setUserType] = useState<SystemUserType | null>(null);
    
    const checkRecord = (record: SystemUser): boolean => {
        setErrorMessage(null);
        let errors = checkUserData(record);
        if (errors.length > 0) {
            displayErrorMessage(errors);
            return false;
        }
        
        return true;
    }
    
    const checkUserData = (user: SystemUser): Object[] => {
        let validationErrors : object[] = [];

        if (!user.firstName)
            validationErrors.push({
                reference: "firstName",
                error: t('distributorCenter:distributorPartnerUserCard:fieldError:firstName')!,
                applyToField: true
            });
        if (!user.lastName)
            validationErrors.push({
                reference: "lastName",
                error: t('distributorCenter:distributorPartnerUserCard:fieldError:lastName')!,
                applyToField: true
            });
        if (!user.displayName)
            validationErrors.push({
                reference: "displayName",
                error: t('distributorCenter:distributorPartnerUserCard:fieldError:displayName')!,
                applyToField: true
            });
        if (!user.privateEmail && !user.userPrincipleName)
            validationErrors.push({
                reference: "displayName",
                error: t('distributorCenter:distributorPartnerUserCard:fieldError:email')!,
                applyToField: true
            });
        
        return validationErrors;
    }
    
    const displayErrorMessage = (errors: Object[]) => {
        setErrorMessage(
            <MessageBar messageBarType={MessageBarType.error}>
                <ul>
                    {errors.map((value: any, index: number) => (
                        <li style={{marginBottom: "3px"}} key={index}>{value.error}</li>
                    ))}
                </ul>
            </MessageBar>
        );
    };
    
    return (
        <>
            <PageContainer pageType={SystemPageType.Card} endpoint="/odata/user" title={t('distributorCenter:distributorPartnerUserCard:title')!} ref={ref} onCheckRecord={(record: SystemUser) => checkRecord(record)}>
                <CardGroup name="general"  label="Generale" isOpen>
                    <CardField name="firstName" label={t('distributorCenter:distributorPartnerUserList:fields:firstName')!} type={SystemFieldType.String}
                        onValidate={(fieldName: string, record: SystemUser, oldValue: any, newValue: SystemUserType) => {
                            record.displayName = record.firstName + ' ' + record.lastName;
                            return record;
                        }}
                    />
                    <CardField name="lastName" label={t('distributorCenter:distributorPartnerUserList:fields:lastName')!} type={SystemFieldType.String}
                        onValidate={(fieldName: string, record: SystemUser, oldValue: any, newValue: SystemUserType) => {
                            record.displayName = record.firstName + ' ' + record.lastName;
                            return record;
                        }}
                    />
                    <CardField name="displayName" label={t('distributorCenter:distributorPartnerUserList:fields:displayName')!} type={SystemFieldType.String} />
                    <CardField name="active" type={SystemFieldType.Boolean} label={t('distributorCenter:distributorPartnerUserList:fields:active')!} />
                    <CardField name="userType" type={SystemFieldType.Option} label={t('distributorCenter:distributorPartnerUserList:fields:userType')!}
                        cmbOptions={[
                            {key: 0, text: "AAD"},
                            {key: 1, text: "Private"},
                        ]}
                        onValidate={(fieldName: string, record: SystemUser, oldValue: any, newValue: SystemUserType) => {
                            setUserType(newValue);

                            if (newValue === SystemUserType.AAD) {
                                record.userPrincipleName = record.privateEmail;
                                record.privateEmail = "";
                            }
                            else if (newValue === SystemUserType.Private) {
                                record.privateEmail = record.userPrincipleName;
                                record.userPrincipleName = "";
                            }

                            return record;
                        }}
                        />
                    <CardField name="phone" type={SystemFieldType.String} label={t('distributorCenter:distributorPartnerUserList:fields:phone')!} />
                    <CardField name="privateEmail" label={t('distributorCenter:distributorPartnerUserList:fields:privateEmail')!} type={SystemFieldType.String} readonly={userType === SystemUserType.AAD}
                        onGetIsVisible={(record: SystemUser) => {
                            setUserType(record.userType);
                            return true;
                        }}
                    />
                    <CardField name="userPrincipleName" label={t('distributorCenter:distributorPartnerUserList:fields:userPrincipleName')!} type={SystemFieldType.String} readonly={userType === SystemUserType.Private}
                        onGetIsVisible={(record: SystemUser) => {
                            setUserType(record.userType);
                            return true;
                        }}
                    />
                </CardGroup>
            </PageContainer>
            <Dialog hidden={!errorMessage} dialogContentProps={{
                type: DialogType.normal,
                title: t('distributorCenter:distributorPartnerCard:validationErrors')!,
            }}>
                {errorMessage}
                <DialogFooter>
                    <DefaultButton text={t('distributorCenter:distributorPartnerCard:close')!} onClick={() => {setErrorMessage(null);}} />
                </DialogFooter>
            </Dialog>
        </>
    );
});