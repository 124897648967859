import { useContext, useEffect, useRef, useState } from "react";
import { DistributorInformationContext, IDistributorInformationContext } from "../../../ApplicationCode/Distributor/DistributorInformationContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { SystemDataLoadingStatus, SystemOperation } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { t } from "i18next";
import { ICommandBarItemProps, Image, PrimaryButton, Stack, mergeStyles } from "@fluentui/react";
import { DistributorActionBar, DistributorCarouselItem, DistributorDashboardContainer, DistributorSocialSlide } from "./DistributorDashboardComponents";
import { Banner, CueBox, DashboardCarousel, DashboardContentColumn, DashboardContentContainer, DashboardContentRow, DataBox, UserProfileCard } from "../../Common/Dashboard/DashboardComponents";
import { DistributorService } from "../../../ApplicationCode/Distributor/DistributorService";
import { DistributorDashboardCue } from "../../../Model/DistributorModel";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { PageContainerBase } from "../../Common/Template/PageContainer";
import { Lead, Partner } from "../../../Model/PartnerModels";
import { DistributorLeadCard } from "../DistributorLeadCard/DistributorLeadCard";
import { DistributorPartnerCard } from "../DistributorPartnerCard/DistributorPartnerCard";

const dashboardCarouselClassName = mergeStyles([{
  width: "100%",
  height: "auto",
  position: "relative",
  margin: "auto"
}])

const DistributorDashboard = () => {
  const distributorService = new DistributorService();
  
  const distributorInfoContext = useContext<IDistributorInformationContext>(DistributorInformationContext);
  const sessionInfoContext = useContext(SessionStateContext);
  
  const [dataStatus, setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
  const [dashboardCue, setDashboardCue] = useState<DistributorDashboardCue>(new DistributorDashboardCue());
  
  const partnerCardRef = useRef<PageContainerBase>(null);
  const leadCardRef = useRef<PageContainerBase>(null);
  
  useEffect(() => {
    if(dataStatus === SystemDataLoadingStatus.ToLoad) {
      distributorService.getDistributorDashboardCue()
      .then((resp: DistributorDashboardCue) => {
        setDashboardCue(resp);
        setDataStatus(SystemDataLoadingStatus.Loaded);
      })
      .catch(() => {
        setDataStatus(SystemDataLoadingStatus.Loaded);
      })
    }
  }, [dataStatus])

  const buildMailTo = (): string => {
    if (distributorInfoContext.distributor.legalEntityCode === 'ESS')
      return "mailto:info@so-smart.us";
    else
      return "mailto:info@so-smart.it";
  }

  const buildSiteUrl = (): string => {
      if (distributorInfoContext.distributor.legalEntityCode === 'ESS')
          return "https://www.so-smart.us/";
      else
          return "https://www.so-smart.it/";
  }

  const buildShopUrl = (): string => {
    if (distributorInfoContext.distributor.legalEntityCode === 'ESS')
          return process.env.REACT_APP_SHOP_URL+".us/";
      else
          return process.env.REACT_APP_SHOP_URL+".it/";
  }

    //#region ActionProps

    const items: ICommandBarItemProps[] = [
      {
        key: 'NewPartner',
        text: t('distributorCenter:distributorDashboard:actions:newPartner')!,
        iconProps: { iconName: 'AddWork' },
        onClick: () => {
          let newPartner = new Partner();
          newPartner.legalEntityCode = distributorInfoContext.distributor.legalEntityCode;
          newPartner.distributorCode = distributorInfoContext.distributor.code;
          newPartner.partnerBusinessContractCode = distributorInfoContext.distributor.defaultPartnerBusinessContractCode;
          partnerCardRef.current?.addAfterSaveCallback(() => { setDataStatus(SystemDataLoadingStatus.ToLoad); });
          partnerCardRef.current?.setRecord(newPartner);
          partnerCardRef.current?.openPage(SystemOperation.Insert);
        }
      },
      {
        key: 'NewLead',
        text: t('distributorCenter:distributorDashboard:actions:newLead')!,
        iconProps: { iconName: 'AddFriend' },
        onClick: () => {
          let newRecord = new Lead();
          newRecord.legalEntityCode = distributorInfoContext.distributor.legalEntityCode;
          newRecord.distributorCode = distributorInfoContext.distributor.code;
          newRecord.partnerBusinessContractCode = distributorInfoContext.distributor.defaultPartnerBusinessContractCode;
          newRecord.marketingQualificationCarriedOut = true;
          leadCardRef.current?.addAfterSaveCallback(() => { setDataStatus(SystemDataLoadingStatus.ToLoad); });
          leadCardRef.current?.setRecord(newRecord);
          leadCardRef.current?.openPage(SystemOperation.Insert);
        }
      },
      {
          key: 'WebSite',
          text: t('distributorCenter:distributorDashboard:actions:webSite')!,
          iconProps: { iconName: 'Globe' },
          onClick: () => { window.open(buildSiteUrl(),'_blank') }
      },
  ]

  //#endregion
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
      return <Loader text={t('distributorCenter:distributorDashboard:loadingMessage')!} />
    else
      return (
        <>
          <DistributorDashboardContainer>
            <DistributorActionBar items={items} />
            <Banner
              leftContent={<UserProfileCard />}
              rightContent={
                <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                  <DistributorCarouselItem
                    title={t('distributorCenter:distributorDashboard:carousel:newLeads')!}
                    description={t('distributorCenter:distributorDashboard:carousel:newLeadsDescription', {value: dashboardCue.newLead})!}
                    iconName="UserFollowed"
                    />
                  <DistributorCarouselItem
                    title={t('distributorCenter:distributorDashboard:carousel:newCustomers')!}
                    description={t('distributorCenter:distributorDashboard:carousel:newCustomersDescription', {value: dashboardCue.newCustomer})!}
                    iconName="FabricUserFolder"
                    />
                  <DistributorCarouselItem
                    title={t('distributorCenter:distributorDashboard:carousel:turnover', {value: dashboardCue.newTurnover})!}
                    description={t('distributorCenter:distributorDashboard:carousel:turnoverDescription', {value: dashboardCue.newTurnover})!}
                    iconName="Money"
                    />
                  <DistributorCarouselItem
                    title={t('distributorCenter:distributorDashboard:carousel:subscriptions', {value: dashboardCue.newSubscription})!}
                    description={t('distributorCenter:distributorDashboard:carousel:subscriptionsDescription', {value: dashboardCue.newSubscription})!}
                    iconName="Subscribe"
                    />
                </DashboardCarousel>
              }
              />
            <DashboardContentRow id={'1'}>
              <Stack key={"dashboardGeneralIndicator"} styles={{root: {width: '55%'}}} tokens={{childrenGap: 20}}>
                <DashboardContentColumn>
                  <DashboardContentContainer title={t('distributorCenter:distributorDashboard:generalIndicators')!} name="generalIndicators">
                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                      <DataBox
                        name="ActivePartner"
                        title={t('distributorCenter:distributorDashboard:activePartners')!}
                        value={dashboardCue.partner}
                        link={"/app/distributor/partners"}
                        />
                      <DataBox
                        name="ActiveLead"
                        title={t('distributorCenter:distributorDashboard:activeLeads')!}
                        iconName="UserFollowed"
                        value={dashboardCue.activeLead}
                        link={"/app/distributor/leads?activeLeads=true"}
                        />
                      <DataBox
                        name="TotalLead"
                        title={t('distributorCenter:distributorDashboard:totalLeads')!}
                        value={dashboardCue.lead}
                        iconName="CheckList"
                        link={"/app/distributor/leads"}
                        />
                        <DataBox
                            name="ActiveCustomer"
                            title={t('partnerCenter:partnerDashboard:activeCustomers')!}
                            value={dashboardCue.customer}
                            iconName="UserEvent"
                            link={"/app/distributor/customers"}
                        />
                    </Stack>
                    
                <Stack key={"dashboardSecondRowWidgets"} styles={{root: { marginTop: 15}}} tokens={{childrenGap: 20}}>
                  <DashboardContentColumn>
                    <DashboardContentContainer title={t('distributorCenter:distributorDashboard:yourApps')!} name="appsContainer">
                      <Stack id="appsSection" horizontal tokens={{childrenGap: 10}}>
                        <CueBox name="Teams" isIconBox title="Teams" iconName="TeamsLogo" link={"https://aka.ms/mstfw?login_hint_safe=" + sessionInfoContext.currentUser.email} />
                        <CueBox name="Outlook" isIconBox title="Outlook" iconName="OutlookLogo" link={"https://outlook.office365.com/mail/inbox"} />
                        <CueBox name="OneDrive" isIconBox title="OneDrive" iconName="OneDriveLogo" link={"https://onedrive.live.com/login/"} />
                        <CueBox name="PowerBi" isIconBox title="PowerBI" iconName="PowerBILogo16" link={"https://app.powerbi.com/home"} />
                        <CueBox name="BusinessCentral" title="Business Central" iconName="Dynamics365Logo" link={"https://businesscentral.dynamics.com/"} value={
                          <Image src="/business-central-logo-white_50.png" alt="Business Central" width={50} height={50} />
                        } />
                      </Stack>
                    </DashboardContentContainer>
                  </DashboardContentColumn>
                </Stack>
                  </DashboardContentContainer>
                </DashboardContentColumn>
              </Stack>
                <Stack key={"dashboardSocial"} styles={{root: {width: '45%'}}} tokens={{childrenGap: 20}}>
                  <DashboardContentColumn>
                    <DashboardContentContainer name="messageCarouselContainer" title={t('distributorCenter:distributorDashboard:tipsForYou')!}>
                      <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                        <DistributorSocialSlide />
                        <Stack
                          key={"message2"}
                          style={{
                            width: '100%',
                            display: 'flex',
                            minHeight: '350px',
                            padding: 40,
                            backgroundColor: 'rgb(217, 240, 242)'
                          }}>
                            <Stack.Item style={{
                              fontSize: 32,
                              padding: 10,
                              display: 'flex',
                              alignItems: 'stretch'
                            }}>
                              {t('distributorCenter:distributorDashboard:contacts:title')!}
                            </Stack.Item>
                            <Stack.Item style={{
                              fontSize: 18,
                              padding: 10,
                              display: 'flex',
                              alignItems: 'stretch'
                            }}>
                              <div>
                                {t('distributorCenter:distributorDashboard:contacts:text0001Lbl')!}<br />
                                {t('distributorCenter:distributorDashboard:contacts:text0002Lbl')!}
                                <br /><br />
                                <PrimaryButton
                                  text={t('distributorCenter:distributorDashboard:contacts:contactUs')!}
                                  styles={{
                                    root: {
                                      backgroundColor: '#008089',
                                      borderColor: '#008089'
                                    },
                                    rootHovered: {
                                      backgroundColor: 'rgb(1, 93, 99)',
                                      borderColor: 'rgb(1, 93, 99)'
                                    }
                                  }}
                                  onClick={() => {window.open(buildMailTo(), "_blank");}}
                                />
                              </div>
                            </Stack.Item>
                        </Stack>
                      </DashboardCarousel>
                    </DashboardContentContainer>
                  </DashboardContentColumn>
                </Stack>
              </DashboardContentRow>
            </DistributorDashboardContainer>
            <DistributorPartnerCard ref={partnerCardRef} distributor={distributorInfoContext.distributor} />
            <DistributorLeadCard ref={leadCardRef} />
          </>
      );
};

export default DistributorDashboard;
