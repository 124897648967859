export class Distributor {
    public code: string = "";
    public name: string = "";
    public legalEntityCode: string = "";
    public defaultPartnerBusinessContractCode: string = "";
}

export class DistributorDashboardCue {
    public partner: number = 0;
    public lead: number = 0;
    public activeLead: number = 0;
    public newLead: number = 0;
    public customer: number = 0;
    public activeCustomer: number = 0;
    public newCustomer: number = 0;
    public newSubscription: number = 0;
    public newTurnover: number = 0;
}
