import { HttpHelper } from "../../Core/Http/HttpHelper";
import { Distributor, DistributorDashboardCue } from "../../Model/DistributorModel";

export class DistributorService {
    public getDistributor(distributorCode: string): Promise<Distributor>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "odata/distributor?$filter=code eq '"+distributorCode+"'";

        return new Promise((resolve, reject) => {
            instance.get(url).then((resp) => {
                let result: Distributor = resp.data[0];
                resolve(result);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public getDistributorDashboardCue() : Promise<DistributorDashboardCue>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/system/distributor/dashboard/cue";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
}