import { useContext, useEffect, useRef, useState } from "react";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { Lead } from "../../../Model/PartnerModels";
import { SystemActionCategory, SystemDataLoadingStatus, SystemOperation, SystemPageType } from "../../../Model/SystemModels";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Loader } from "../../Common/Loader/Loader";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import { t } from "i18next";
import { CommonLeadModal } from "../../Common/CommonLeadModal/CommonLeadModal";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { LeadConfigChecklistDetails, LeadToLiveChecklist, SaveLeadConfigChecklist } from "../../Common/LeadDashboard/LeadDashboard";
import { IndustyType } from "../../../Model/DemoModels";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { DistributorLeadCard } from "../DistributorLeadCard/DistributorLeadCard";
import { DistributorInformationContext, IDistributorInformationContext } from "../../../ApplicationCode/Distributor/DistributorInformationContext";
import { DataSecurityStatus } from "../../../Model/AdminModel";
import { useNavigate } from "react-router-dom";

export const DistributorLeadList : React.FC = () => {
    const adminService = new AdminService();
    
    const distributorInfoContext = useContext<IDistributorInformationContext>(DistributorInformationContext);
    
    const cardRef = useRef<PageContainerBase>(null);
    const currentPage = useRef<PageContainerBase>(null);

    const [dataStatus, setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [message, setMessage] = useState<string>(t('partnerCenter:partnerLeadList:loading')!);
    const [endpoint, setEndpoint] = useState<string>("");
    const [listTitle, setListTitle] = useState<string>("");
    const [dsStates, setDsStates] = useState<DataSecurityStatus[]>([]);
    const [showLeadModal, setShowLeadModal] = useState<boolean>(false);
    const [selectedLead, setSelectedLead] = useState<Lead | undefined>(undefined);
    const [leadConfigDetails, setLeadConfigDetails] = useState<LeadConfigChecklistDetails>(new LeadConfigChecklistDetails());
    const [industryTypes, setIndustryTypes] = useState<IndustyType[]>([]);
    const [leadDataSaveInProgress, setLeadDataSaveInProgress] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<Object[]>([]);

    const navigate = useNavigate();
    
    const buildListEndpoint = () => {
        setDataStatus(SystemDataLoadingStatus.Loading);
        
        const activeLeads = HttpHelper.getParameter('activeLeads');
        let buildedEndpoint = "/odata/lead?$filter=distributorCode eq '"+distributorInfoContext.distributor.code+"'";
        let currentTitle = t('partnerCenter:partnerLeadList:leadList');
        
        if (activeLeads != null && activeLeads !== "") {
            currentTitle += t('partnerCenter:partnerLeadList:active');
            buildedEndpoint += ' and statusCode le \'85\'&$orderby=createdOn desc';
        } else {
            currentTitle += t('partnerCenter:partnerLeadList:total');
            buildedEndpoint += '&$orderby=systemCreationDate desc';
        }
        
        setEndpoint(buildedEndpoint);
        setListTitle(currentTitle);
        setDataStatus(SystemDataLoadingStatus.Loaded);
    }
    
    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad) {            
            adminService.getDsStatusValue('Lead')
            .then((resp) => {
                setDsStates(resp);
                buildListEndpoint();
            })
        }
    }, [dataStatus]);
    
    const setNewRecord = () => {
        let newRecord = new Lead();
        newRecord.legalEntityCode = distributorInfoContext.distributor.legalEntityCode;
        newRecord.distributorCode = distributorInfoContext.distributor.code;
        newRecord.partnerBusinessContractCode = distributorInfoContext.distributor.defaultPartnerBusinessContractCode;
        newRecord.marketingQualificationCarriedOut = true;
        cardRef.current?.setRecord(newRecord);
        cardRef.current?.openPage(SystemOperation.Insert);
    }

    const getLeadData = (lead: Lead): Promise<boolean> => {
        return new Promise((resolve, reject) =>{            
            adminService.getLeadConfigDetails(lead).then((details) => {
                setLeadConfigDetails(details);
        
                adminService.getIndustryTypes().then((respIndustries) => {
                    setIndustryTypes(respIndustries);
                    resolve(true);
                })
                .catch(err => {
                    ToastService.showMessage(MessageType.Error, err);
                    reject(false);
                })
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err);
                reject(false);
            });
        })
    }
    
    const checkLeadModalData = (configuration: SaveLeadConfigChecklist): Object[] => {
        let validationErrors : object[] = [];

        if (! configuration.lead.firstname)
            validationErrors.push({
                reference: "firstname",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00001Text')!,
                applyToField: true
            });
        if (! configuration.lead.lastname)
            validationErrors.push({
                reference: "lastname",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00002Text')!,
                applyToField: true
            });
        if (! configuration.lead.companyName)
            validationErrors.push({
                reference: "companyName",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00003Text')!,
                applyToField: true
            });
        if (! configuration.lead.address)
            validationErrors.push({
                reference: "address",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.zipCode)
            validationErrors.push({
                reference: "zipCode",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.city)
            validationErrors.push({
                reference: "city",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.county)
            validationErrors.push({
                reference: "county",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.country)
            validationErrors.push({
                reference: "country",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });

        if (! configuration.lead.email)
            validationErrors.push({
                reference: "email",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        
        if (configuration.lead.full_Users_No === 0)
            validationErrors.push({
                reference: "fullUsersNo",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        
        let riskIndex = configuration.details.businessNeedsRiskLines.findIndex((element) => element.description === "");
        if (riskIndex == 0){
            validationErrors.push({
                reference: "LostRisk1",
                error: t('leadDashboard:errors:text00001Err')!,
                applyToField: true
            });
        }

        if (configuration.lead.evaluationMode === 0)
            validationErrors.push({
                reference: "evaluationMode",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00007Text')!,
                applyToField: true,
            });

        setValidationErrors(validationErrors);
        return validationErrors;
    }

    const onSaveLeadModal = (configuration: SaveLeadConfigChecklist) => {
        if (checkLeadModalData(configuration).length > 0)
            return false;
   
        setLeadDataSaveInProgress(true);
        let client = HttpHelper.buildAxiosInstance();

        client.post('/api/system/lead/checklist/config/save', configuration).then(() => {
            setShowLeadModal(false);
            setLeadDataSaveInProgress(false);
            currentPage.current?.reloadData();
        })
        .catch(() => {
            ToastService.showMessage(MessageType.Error, t('leadDashboard:errors:text00002Err')!);
            setLeadDataSaveInProgress(false);
        });

        return true;
    }

    const weekday = [
        t('partnerCenter:partnerLeadList:weekday:sunday')!,
        t('partnerCenter:partnerLeadList:weekday:monday')!,
        t('partnerCenter:partnerLeadList:weekday:tuesday')!,
        t('partnerCenter:partnerLeadList:weekday:wednesday')!,
        t('partnerCenter:partnerLeadList:weekday:thursday')!,
        t('partnerCenter:partnerLeadList:weekday:friday')!,
        t('partnerCenter:partnerLeadList:weekday:saturday')!,
    ];
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={message}></Loader>;
    else
    return (
        <>
            <PageContainer onNewRecord={setNewRecord} endpoint={endpoint} pageType={SystemPageType.List} title={listTitle} ref={currentPage} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="LeadQualification" label={t("adminCenter:leadList:actions:leadQualification")} iconName="PartyLeader" isPromoted runOnRec onClick={(req:any)=>{
                                setSelectedLead(req);
                                getLeadData(req).then(()=>{setShowLeadModal(true)});
                            }}>
                        </ActionEntry>
                        <ActionEntry name="ManageOffer" label={t('partnerCenter:partnerLeadList:actions:manageOffer')!} iconName="AccountActivity" runOnRec isPromoted onClick={(req:any)=>{
                            navigate("/app/distributor/leadOfferList?partnerId="+req.partnerId+"&leadId="+req.id);
                        }}></ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:firstName')!} fieldName="firstname" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:lastName')!} fieldName="lastname" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:companyName')!} fieldName="companyName" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:email')!} fieldName="email" minWidth={300} maxWidth={350} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:partnerName')!} fieldName="partnerName" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:phone')!} fieldName="phone" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:createdOn')!} fieldName="createdOn" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.createdOn !== undefined)
                        {
                            let convert: Date = new Date(item.createdOn);
                            let day, month, year, hours, minutes;
                            
                            if (convert.getFullYear() === 1)
                                return undefined;
                                
                            if (convert !== undefined)
                            {
                                day = convert.getDate();
                                month = convert.getMonth() + 1;
                                year = convert.getFullYear();
                                hours = ("0" + convert.getHours()).slice(-2);
                                minutes = ("0" + convert.getMinutes()).slice(-2);
                                return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                            }
                        }
                        
                        return undefined;
                    }} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:internalMeetingDate')!} fieldName="internalMeetingDate" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.internalMeetingDate !== undefined)
                        {
                           let convert: Date = new Date(item.internalMeetingDate);
                           let day, month, year, hours, minutes;

                           if (convert.getFullYear() === 1)
                               return undefined;
                               
                           if (convert !== undefined)
                           {
                               day = convert.getDate();
                               month = convert.getMonth() + 1;
                               year = convert.getFullYear();
                               hours = ("0" + convert.getHours()).slice(-2);
                               minutes = ("0" + convert.getMinutes()).slice(-2);
                               return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                           }
                       }
               
                       return undefined;
                    }} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:demoDate')!} fieldName="demoDate" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.demoDate !== undefined)
                        {
                           let convert: Date = new Date(item.demoDate);
                           let day, month, year, hours, minutes;

                           if (convert.getFullYear() === 1)
                               return undefined;
                               
                           if (convert !== undefined)
                           {
                               day = convert.getDate();
                               month = convert.getMonth() + 1;
                               year = convert.getFullYear();
                               hours = ("0" + convert.getHours()).slice(-2);
                               minutes = ("0" + convert.getMinutes()).slice(-2);
                               return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                           }
                       }
               
                       return undefined;
                    }} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerList:fields:statusCode')!} fieldName="statusCode" minWidth={200} maxWidth={500} onRender={(item: any, index, column) => {
                        return dsStates.find(ds => ds.code === item.statusCode)?.description;
                    }} />
                </PageDefinitionContainer>
            </PageContainer>
            <DistributorLeadCard ref={cardRef} />
            <CommonLeadModal
                showModal={showLeadModal}
                savingData={leadDataSaveInProgress}
                canScroll={false}
                leadRef={selectedLead!}
                leadConfigDetail={leadConfigDetails}
                leadToLiveChecklist={new LeadToLiveChecklist()}
                industryTypes={industryTypes}
                validationErrors={validationErrors}
                onSave={onSaveLeadModal}
                onCancel={() =>{
                    setShowLeadModal(false);
                }}
                checkLeadData={(configuration: SaveLeadConfigChecklist) => {
                    checkLeadModalData(configuration);
                }}
            />
        </>
    );
}