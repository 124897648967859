import { Checkbox, DefaultButton, Dialog, DialogFooter, IColumn, IModalProps, mergeStyles, PrimaryButton, SelectionMode, ShimmeredDetailsList, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { AssignedChatbotPartnerBusinessContract } from "../../../../Model/AdminModel";
import { SystemOperation } from "../../../../Model/SystemModels";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important',
            position: 'absolute',
            top: 50
        }
    }
  };

const contentBodyClassName = mergeStyles([{
    width: '100%',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);


export interface IAdminChatbotPartnerBusinessContractLinkDialogProps {
    showDialog: boolean;
    profileCode: string;
    onSuccessModal: (modifiedBusinessLine: AssignedChatbotPartnerBusinessContract[]) => void;
    onCancelModal: () => void;
}

export const AdminChatbotPartnerBusinessContractLinkDialog : React.FC<IAdminChatbotPartnerBusinessContractLinkDialogProps> = (props) => {
    const [businessLineAssigned, setAssignedBusinessLine] = useState([] as AssignedChatbotPartnerBusinessContract[]);
    const [showShimmer, setShowShimmer] = useState<boolean>(false);

    const adminService = new AdminService();
    
    const buildListColumns = (): IColumn[] => {
        return [
            {
                key: "enabled",
                fieldName: "enabled",
                name: "",
                minWidth: 50,
                maxWidth: 50,
                onRender: (item: AssignedChatbotPartnerBusinessContract | undefined, index?, column?) => {

                    return (
                        <Checkbox 
                                checked={item?.enabled}
                                onChange={(ev, checked) => {
                                    let newArray: AssignedChatbotPartnerBusinessContract[] = JSON.parse(JSON.stringify(businessLineAssigned));

                                    let index = newArray.findIndex(e => e.partnerBusinessContractCode == item!.partnerBusinessContractCode && e.profileCode == item!.profileCode);
                                    if (index >= 0)
                                    {
                                        item!.enabled = checked!;

                                        if(!checked && item?.systemId)
                                        {
                                            item!.operationType = SystemOperation.Delete;
                                        }
                                        else if (checked && !item?.systemId)
                                        {
                                            item!.operationType = SystemOperation.Insert;
                                        }
                                        else
                                        {
                                            item!.operationType = SystemOperation.None;
                                        }

                                        newArray[index] = item!;
                                        setAssignedBusinessLine(newArray);
                                    }
                                }} />
                    );
                }
            },
            {
                key: "description",
                fieldName: "description",
                name: "Partner business contract",
                minWidth: 100,
                maxWidth: 200
            },
            {
                key: "knowledgeDocumentEnableSync",
                fieldName: "knowledgeDocumentEnableSync",
                name: "Sincronizzazione documenti abilitata",
                minWidth: 100,
                maxWidth: 200,
                onRender: (item: AssignedChatbotPartnerBusinessContract | undefined, index?, column?) => {

                    return (
                        <Checkbox 
                                checked={item?.knowledgeDocumentEnableSync}
                                onChange={(ev, checked) => {
                                    let newArray: AssignedChatbotPartnerBusinessContract[] = JSON.parse(JSON.stringify(businessLineAssigned));

                                    let index = newArray.findIndex(e => e.partnerBusinessContractCode == item!.partnerBusinessContractCode && e.profileCode == item!.profileCode);
                                    if (index >= 0)
                                    {
                                        item!.knowledgeDocumentEnableSync = checked!;

                                        if(item?.systemId)
                                        {
                                            item!.operationType = SystemOperation.Update;
                                        }

                                        newArray[index] = item!;
                                        setAssignedBusinessLine(newArray);
                                    }
                                }} />
                    );
                }
            }
        ];
    };

    useEffect(() => {
        if (props.showDialog)
        {
            setShowShimmer(true);
            adminService.getCurrentAssignedPartnerBusinessContracts(props.profileCode)
                .then((resp) => {
                    setAssignedBusinessLine(resp);
                    setShowShimmer(false);
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err);
                    props.onCancelModal();
                });
        }
        else
            setShowShimmer(false);
    }, [props.showDialog]);

    return (
        <Dialog hidden={! props.showDialog}
                modalProps={modalProps}
                onDismiss={props.onCancelModal}
                dialogContentProps={{
                    title: "Associa partner business contract a profilo " + props.profileCode
                }}>
                <Stack key="modalContent" className={modalContentClassName}>
                    <Stack.Item key="modalBodyContent" className={contentBodyClassName}>
                        <ShimmeredDetailsList items={businessLineAssigned}
                            columns={buildListColumns()}
                            selectionMode={SelectionMode.none}
                            enableShimmer={showShimmer}
                        />
                    </Stack.Item>
                    <Stack.Item key="modalFooterContent">
                        <DialogFooter>
                            <DefaultButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerLicenseCard:Dialog:defaultButtonText')!}  onClick={() => {
                                props.onCancelModal();
                            }} />
                            <PrimaryButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerLicenseCard:Dialog:primaryButtonText')!} onClick={() => {
                                setShowShimmer(true);
                                props.onSuccessModal(businessLineAssigned);
                            } } />
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
        </Dialog>
    );

}