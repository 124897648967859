import { forwardRef } from "react";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { SystemFieldType, SystemPageType } from "../../../Model/SystemModels";
import { CardField } from "../../Common/Template/Card/CardField";
import { t } from "i18next";

export const PartnerPartnerCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {    
    return (
        <PageContainer pageType={SystemPageType.Card} title={t('partnerCenter:partnerPartnerCard:title')!} endpoint="odata/partner" ref={ref}>
            <CardGroup name="general" label={t('partnerCenter:partnerPartnerCard:groups:general')!} isOpen>
                <CardField name="name" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:name')!} />
                <CardField name="email" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:email')!} />
                <CardField name="firstName" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:firstName')!} />
                <CardField name="lastName" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:lastName')!} />
                <CardField name="legalRepresentativeFirstName" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:legalRepresentativeFirstName')!} />
                <CardField name="legalRepresentativeLastName" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:legalRepresentativeLastName')!} />
                <CardField name="phoneNo" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:phoneNo')!} />
            </CardGroup>
            <CardGroup name="personalData" label={t('partnerCenter:partnerPartnerCard:groups:personalData')!} isOpen>
                <CardField name="streetAddress" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:streetAddress')!} />
                <CardField name="city" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:city')!} />
                <CardField name="zipCode" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:zipCode')!} />
                <CardField name="county" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:county')!} />
                <CardField name="country" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:country')!} />
            </CardGroup>
        </PageContainer>
    );
});