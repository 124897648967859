import { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminDistributorCard } from "../AdminDistributorCard/AdminDistributorCard";

export const AdminDistributorList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/distributor" pageType={SystemPageType.List} title="Lista Distributori" backAllowed insertAllowed updateAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Legal Entity" fieldName="legalEntityCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Default Partner Business Contract" fieldName="defaultPartnerBusinessContractCode" minWidth={250} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminDistributorCard ref={cardRef} />
        </>
    );
}