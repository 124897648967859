import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminDistributorCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda Distributore"} endpoint="odata/distributor" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>                
                <CardField label="Codice" name="code" type={SystemFieldType.String} />
                <CardField label="Nome" name="name" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField label="Legal Entity" name="legalEntityCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Default Partner Business Contract" name="defaultPartnerBusinessContractCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description" />
            </CardGroup>
        </PageContainer>
    );
});