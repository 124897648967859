import React, { useContext, useEffect, useRef, useState } from "react";
import { SystemActionCategory, SystemDataLoadingStatus, SystemOperation, SystemPageType, SystemUser, SystemUserRelationshipType } from "../../../Model/SystemModels";
import { useNavigate, useParams } from "react-router-dom";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Loader } from "../../Common/Loader/Loader";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { DistributorInformationContext, IDistributorInformationContext } from "../../../ApplicationCode/Distributor/DistributorInformationContext";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { t } from "i18next";
import { Partner } from "../../../Model/PartnerModels";
import { Toggle } from "@fluentui/react";
import { DistributorPartnerUserCard } from "../DistributorPartnerUserCard/DistributorPartnerUserCard";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";

export const DistributorPartnerUserList : React.FC = () => {
    const adminService = new AdminService();
    const partnerService = new PartnerService();

    const { id: partnerId } = useParams();
    
    const distributorInfoContext = useContext<IDistributorInformationContext>(DistributorInformationContext);
    
    const cardRef = useRef<PageContainerBase>(null);

    const navigate = useNavigate();

    const [dataStatus, setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState<string>(t('distributorCenter:distributorPartnerUserList:loader')!);
    const [endpoint, setEndpoint] = useState<string>("");
    const [listTitle, setListTitle] = useState<string>("");
    const [partner, setPartner] = useState<Partner>(new Partner());

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad) {
            partnerService.loadPartner(Number(partnerId))
            .then((resp) => {
                if (resp.distributorCode !== distributorInfoContext.distributor.code) {
                    navigate("/app/distributor/home");
                    ToastService.showMessage(MessageType.Error, t('distributorCenter:distributorPartnerUserList:forbidden')!);
                }
                
                setPartner(resp);
                buildListEndpoint(resp);
            })
            .catch((err) => {
                console.log(err);
                navigate("/app/distributor/home");
            });
        }
    },[dataStatus])
    
    const buildListEndpoint = (partner: Partner) => {
        setEndpoint("/odata/user?$filter=partnerId eq "+partnerId+" and userRelationshipType eq 'Partner'");
        setListTitle(t('distributorCenter:distributorPartnerUserList:title')!+partner.name)
        setDataStatus(SystemDataLoadingStatus.Loaded);
    }

    const sendWelcomeEmail = (user: SystemUser) => {
        ToastService.showPromiseMessage(
            adminService.sendWelcomeEmail(user),
            t('distributorCenter:distributorPartnerUserList:actions:sendWelcomeEmail:pending')!,
            t('distributorCenter:distributorPartnerUserList:actions:sendWelcomeEmail:success')!,
            t('distributorCenter:distributorPartnerUserList:actions:sendWelcomeEmail:error')!
        );
    }
    
    const setNewRecord = () => {
        let newRecord = new SystemUser();
        newRecord.active = true;
        newRecord.partnerId = partner.id;
        newRecord.userRelationshipType = SystemUserRelationshipType.Partner;
        cardRef.current?.setRecord(newRecord);
        cardRef.current?.openPage(SystemOperation.Insert);
    }

    if(!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>
    else
        return (
            <>
                <PageContainer onNewRecord={setNewRecord} endpoint={endpoint} pageType={SystemPageType.List} title={listTitle} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="sendWelcomeEmail" label={t('distributorCenter:distributorPartnerUserList:actions:sendWelcomeEmail:title')!} iconName="Message" runOnRec
                                onClick={(req: SystemUser) => {
                                    sendWelcomeEmail(req);
                                }}
                            />
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:displayName')!} fieldName="displayName" minWidth={250} maxWidth={250} />
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:firstName')!} fieldName="firstName" minWidth={200} maxWidth={250} />
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:lastName')!} fieldName="lastName" minWidth={200} maxWidth={250} />
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:userType')!} fieldName="userType" minWidth={150} maxWidth={200} onRender={(item: any, index, column) => {
                            if (item.userType === 0)
                                return "AAD"
                            else if (item.userType === 1)
                                return "Private"
                        }} />
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:privateEmail')!} fieldName="privateEmail" minWidth={250} maxWidth={250} />
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:userPrincipleName')!} fieldName="userPrincipleName" minWidth={250} maxWidth={250} />
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:phone')!} fieldName="phone" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:active')!} fieldName="active" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                            return <Toggle checked={item.active}></Toggle>
                        }} />
                        <ListHeaderEntry name={t('distributorCenter:distributorPartnerUserList:fields:id')!} fieldName="id" minWidth={80} maxWidth={250} />
                    </PageDefinitionContainer>
                </PageContainer>
                <DistributorPartnerUserCard ref={cardRef} />
            </>
        );
}