import React, { useContext, useRef } from "react";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { SystemActionCategory, SystemOperation, SystemPageType } from "../../../Model/SystemModels";
import { t } from "i18next";
import { DistributorInformationContext, IDistributorInformationContext } from "../../../ApplicationCode/Distributor/DistributorInformationContext";
import { DistributorPartnerCard } from "../DistributorPartnerCard/DistributorPartnerCard";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { useNavigate } from "react-router-dom";

export const DistributorPartnerList : React.FC = () => {
    const distributorInfoContext = useContext<IDistributorInformationContext>(DistributorInformationContext);
    
    const cardRef = useRef<PageContainerBase>(null);

    const navigate = useNavigate();

    const buildListEndpoint = () => "/odata/partner?$filter=distributorCode eq '"+distributorInfoContext.distributor.code+"'";
    
    const onNewRecord = () => {
        cardRef.current?.setRecord({
            legalEntityCode: distributorInfoContext.distributor.legalEntityCode,
            distributorCode: distributorInfoContext.distributor.code,
            partnerBusinessContractCode: distributorInfoContext.distributor.defaultPartnerBusinessContractCode
        });
        
        cardRef.current?.openPage(SystemOperation.Insert);
    }

    return (
        <>
            <PageContainer pageType={SystemPageType.List} title={t('distributorCenter:distributorPartnerList:title')!} endpoint={buildListEndpoint()} cardRef={cardRef} backAllowed refreshAllowed updateAllowed insertAllowed onNewRecord={onNewRecord}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="openPartnerUsers" label={t('distributorCenter:distributorPartnerList:action:openPartnerUsers')!} iconName="People" isPromoted runOnRec onClick={(req:any)=>{
                            navigate('/app/distributor/partner/'+req.id+'/user');
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('distributorCenter:distributorPartnerList:fields:name')!} fieldName="name" minWidth={500} maxWidth={600} />
                    <ListHeaderEntry name={t('distributorCenter:distributorPartnerList:fields:email')!} fieldName="email" minWidth={350} maxWidth={500} />
                    <ListHeaderEntry name={t('distributorCenter:distributorPartnerList:fields:phone')!} fieldName="phoneNo" minWidth={250} maxWidth={500} />
                    <ListHeaderEntry name={t('distributorCenter:distributorPartnerList:fields:firstName')!} fieldName="firstName" minWidth={250} maxWidth={500} />
                    <ListHeaderEntry name={t('distributorCenter:distributorPartnerList:fields:lastName')!} fieldName="lastName" minWidth={250} maxWidth={500} />
                    <ListHeaderEntry name={t('distributorCenter:distributorPartnerList:fields:id')!} fieldName="id" minWidth={60} maxWidth={100} />
                </PageDefinitionContainer>
            </PageContainer>
            <DistributorPartnerCard ref={cardRef} distributor={distributorInfoContext.distributor} />
        </>
    );
}