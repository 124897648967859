import { Icon, ISearchBoxStyleProps, ISearchBoxStyles, IStyleFunctionOrObject, keyframes, Text, mergeStyles, SearchBox, Spinner, SpinnerSize, Stack, Link, ComboBox, ContextualMenu, IContextualMenuItem, TooltipHost, Image, ImageFit, DirectionalHint, TooltipDelay, IStackTokens, Panel, PanelType, IPanelProps, IPanelHeaderRenderer, Button, PrimaryButton, FontSizes } from "@fluentui/react";
import { t } from "i18next";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { CustomerService } from "../../ApplicationCode/Customer/CustomerService";
import { DocsIndexStructure, DocsProcessStructure, DocsProcessTestStructure, DocsSearchIndexStructure } from "../../Model/CustomerModels";
import { CustomerInformationContext, ICustomerInformationContext } from "../../ApplicationCode/Customer/CustomerInformationContext";
import { SystemDataLoadingStatus } from "../../Model/SystemModels";
import rehypeVideo from "rehype-video";
import ReactPlayer from "react-player";
import { remarkAlert } from 'remark-github-blockquote-alert';
import 'remark-github-blockquote-alert/alert.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { CSSProperties } from "styled-components";
import videoThumbnail from "../../Assets/Images/cover_video.png";
import { SystemCore } from "../../Core/System/SystemCore";

import errorImage from '../../Assets/Images/droidi2.gif';
import MediaQuery, { useMediaQuery } from "react-responsive";
import { ISessionStateContext, SessionStateContext } from "../../Core/State/SessionStateContext";

//#region DOCS - Sidebar

export interface ISidebarProps {
    isPanelMenuOpen: boolean;
    onDismissPanelMenu: () => void;
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
    const navigate = useNavigate();

    //#region Styles

    const titleStyle = mergeStyles([{
        textRendering: 'optimizeLegibility',
        fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontWeight: 600,
        fontSize: '18px',
        color: '#161616',
        margin: 15,
        selectors: {
            ":hover": {
                textDecoration: "underline",
                cursor: "pointer"
            }
        }
    }]);

    const panelTitleStyle = mergeStyles([{
        textRendering: 'optimizeLegibility',
        fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontWeight: 600,
        fontSize: '18px',
        color: '#161616',
        margin: '3px',
        position: 'absolute',
        top: '1.3rem',
        left: '1.3rem',
        selectors: {
            ":hover": {
                textDecoration: "underline",
                cursor: "pointer"
            }
        },
        '@media screen and (max-width: 910px)': {
            fontSize: '3.4vw',
        },
        '@media screen and (min-width: 768px)': {
            fontSize: '2.4vw',
            top: '1.1rem',
            left: '1.1rem',
        }
    }]);

    const sidebarClassName = mergeStyles([{
        position: 'sticky',
        marginRight: '25px',
        left: '0px',
        top: '51px',
        width: '25%',
        padding: 0,
        height: 'calc(100vh - 120px)',
        maxHeight: 'calc(100vh - 51px)',
        '@media screen and (max-width: 910px)': {
            display: 'none',
        }
    }]);

    //#endregion

    return (
        <>
            <MediaQuery minWidth={910}>
                <Stack
                    className={sidebarClassName}
                >
                    <Text className={titleStyle} onClick={()=>navigate("index")}>Dynamics 365 Business Central</Text>
                    {props.children}
                </Stack>
            </MediaQuery>
            <MediaQuery maxWidth={910}>
                <Panel
                    styles={{
                        content:{
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            height: 'calc(100% - 50px)'
                        },
                        contentInner: {
                            height: '100%'
                        },
                        scrollableContent: {
                            height: '100%'
                        },
                        root: {
                            top: "50px",
                            height: 'calc(100% - 50px)'
                        }
                    }}
                    onRenderHeader={(props?: IPanelProps | undefined, defaultRender?: IPanelHeaderRenderer | undefined, headerTextId?: string | undefined) => {
                        return <Text className={panelTitleStyle} onClick={()=>navigate("index")}>Dynamics 365 Business Central</Text>
                    }}
                    onDismiss={props.onDismissPanelMenu}
                    isOpen={props.isPanelMenuOpen}
                    type={PanelType.customNear}
                    customWidth='75vw'
                    closeButtonAriaLabel="Close"
                    isLightDismiss
                >
                    {props.children}
                </Panel>
            </MediaQuery>
        </>
    );
}

//#endregion

//#region Docs MD Index

export const DocsMarkdownIndex: React.FC = (props) => {
    
    const mdIndexClassName = mergeStyles([{
        position: 'sticky',
        top: "51px !important",
        marginLeft: '25px',
        right: 0,
        width: '15%',
        padding: 0,
        height: '100%',
        paddingRight: '10px',
        '@media screen and (max-width: 1090px)': {
            paddingRight: '0px',
            marginLeft: '0px',
            width: '100%',
        }
    }]);

    return(
        <Stack
            className={mdIndexClassName}
        >
            {props.children}
        </Stack>
    )
}

//#endregion

//#region DOCS - SidebarSearchBar

export interface ISidebarSearchBarProps {
    placeholder: string;
    text: string;
    onSearch: (newValue: string) => void;
}

export const SidebarSearchBar: React.FC<ISidebarSearchBarProps> = (props) => {

    const searchbarStyle: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = {
        root: {
            borderRadius: 20,
            height: 40,
            borderColor: '#A29A9A',
            selectors: {
                "&::after": {
                    borderRadius: 20
                }
            }
        }
    }
    
    return (
        <Stack.Item style={{
            paddingTop: 15,
            paddingRight: 15,
            paddingLeft: 15
        }}>
            <SearchBox value={props.text} placeholder={props.placeholder} onChange={(e, newVal) => props.onSearch(newVal!)} styles={searchbarStyle} />
        </Stack.Item>
    );
}

//#endregion

//#region DOCS - SidebarSearchItems

export interface IDocsSearchEntryProps {
    id: string;
    label: string;
    onClick: () => void
}

export const DocsSearchEntry: React.FC<IDocsSearchEntryProps> = (props) => {
    return (
        <Stack key={props.id} id={props.id} onClick={() => props.onClick()} styles={{
            root: {
                padding: 10,
                color: 'black',
                paddingLeft: 25,
                selectors: {
                    "&:hover": {
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }
                }
            }
        }}
        tokens={{
            childrenGap: 10
        }}
        >
            <Stack.Item style={{
                fontWeight: 400,
                fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
            }}>
                {props.label}
            </Stack.Item>
        </Stack>
    );
}

//#endregion

//#region DOCS - SidebarItem

export interface ISidebarProcessTestItemProps {
    id: string;
    title: string;
    fileName: string;
    isActive: boolean;
    onClick: (id: string, fileName: string) => void;
}

export const SidebarProcessTestItem : React.FC<ISidebarProcessTestItemProps> = (props) => {

    const containerClass = mergeStyles([{
        color: "#161616",
        fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
        cursor: 'pointer',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '20px',
        paddingRight: '20px'
    }])

    return (
        <Stack className={containerClass} key={props.id} id={props.id} onClick={() => props.onClick(props.id, props.fileName)}>
            <Stack.Item styles={{
                root: {
                    color: "#161616",
                    fontSize: '.875rem',
                    wordBreak : 'break-word',
                    wordWrap: 'break-word',
                    fontWeight: props.isActive ? 700 : 400,
                    display: 'flex',
                    alignItems: 'center',
                    selectors: {
                        "&:hover": {
                            textDecoration: 'underline'
                        }
                    }
                }
            }}>
                {props.title}
            </Stack.Item>
        </Stack>
    );
}

export interface ISidebarProcessItemProps {
    id: string;
    title: string;
    hasChild: boolean;
}

export const SidebarProcessItem : React.FC<ISidebarProcessItemProps> = (props) => {
    const [childrenHidden, setChildrenHidden] = useState<boolean>(true);

    const rotate = keyframes({
        'from': {
            transform: 'rotate(0)',
        },
        'to': {
            transform: 'rotate(90deg)',
        }
    });

    const showAnimation = keyframes({
        'from': {
            opacity: 0,
        },
        'to': {
            opacity: 1,
        }
    });

    let classEntry = mergeStyles([{
        fontSize: '0.5vw',
        display: 'flex',
        alignItems: 'center',
        marginRight: 5
    }]);

    let contentClass = mergeStyles([{
        opacity: 1,
        animation: '0.5s ease-in ' + showAnimation
    }]);

    const containerClass = mergeStyles([{
        width: '100%',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '15px',
        paddingRight: '15px'
    }]);

    if (!childrenHidden)
    {
        classEntry = mergeStyles([{
            fontSize: '0.5vw',
            display: 'flex',
            alignItems: 'center',
            animation: '0.5s ease-in ' + rotate,
            transform: 'rotate(90deg)',
            marginRight: 5
        }]); 
    }

    const setGroupSelected = () => {
        let element = document.getElementById(props.id);

        if(childrenHidden){
            if(element !== null){
                element.setAttribute("groupActive", "true");
            }
        }
        else {
            if(element !== null){
                if(element.hasAttribute("groupActive")){
                    element.removeAttribute("groupActive");
                }
            }
        }

        setChildrenHidden(!childrenHidden);
    }

    return (
        <Stack className={containerClass}>
            <Stack key={props.id} id={props.id} horizontal styles={{
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    color: "black",
                    selectors: {
                        "&:hover": {
                            cursor: 'pointer'
                        }
                    }
                }
            }}
            onClick={() => {setGroupSelected()}}
            >
                {
                    props.hasChild ?
                    <Stack.Item>
                        <Icon iconName='ChevronRightSmall' className={classEntry} />
                    </Stack.Item>
                    :
                    <Stack.Item>
                        <Icon iconName='' className={classEntry} />
                    </Stack.Item>
                }
                <Stack.Item style={{
                        fontWeight: 450,
                        fontSize: '.875rem',
                        fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#161616',
                        wordBreak : 'break-word',
                        wordWrap: 'break-word',
                        hyphens: 'manual'
                    }}>
                    {props.title}
                </Stack.Item>
            </Stack>
            <Stack key={"content-" + props.id} className={contentClass} hidden={childrenHidden}>
                {props.children}
            </Stack>
        </Stack>
    );
}

export interface ISidebarProcessModuleItemProps {
    id: string;
    title: string;
    hasChild: boolean;
}

export const SidebarProcessModuleItem : React.FC<ISidebarProcessModuleItemProps> = (props) => {
    const [childrenHidden, setChildrenHidden] = useState<boolean>(true);

    const rotate = keyframes({
        'from': {
            transform: 'rotate(0)',
        },
        'to': {
            transform: 'rotate(90deg)',
        }
    });

    const showAnimation = keyframes({
        'from': {
            opacity: 0,
        },
        'to': {
            opacity: 1,
        }
    });

    let classEntry = mergeStyles([{
        fontSize: '0.5vw',
        display: 'flex',
        alignItems: 'center',
        marginRight: 5
    }]);

    let contentClass = mergeStyles([{
        opacity: 1,
        padding: 3,
        animation: '0.5s ease-in ' + showAnimation
    }]);

    const containerClass = mergeStyles([{
        width: '100%',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '15px',
        paddingRight: '15px'
    }]);

    if(!childrenHidden){
        classEntry = mergeStyles([{
            fontSize: '0.5vw',
            display: 'flex',
            alignItems: 'center',
            animation: '0.5s ease-in ' + rotate,
            transform: 'rotate(90deg)',
            marginRight: 5
        }]); 
    }

    const setGroupSelected = () => {
        let element = document.getElementById(props.id);

        if(childrenHidden){
            if(element !== null){
                element.setAttribute("groupActive", "true");
            }
        }
        else {
            if(element !== null){
                if(element.hasAttribute("groupActive")){
                    element.removeAttribute("groupActive");
                }
            }
        }

        setChildrenHidden(!childrenHidden);
    }

    return (
        <Stack className={containerClass}>
            <Stack key={props.id} id={props.id} horizontal styles={{
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    color: "black",
                    selectors: {
                        "&:hover": {
                            cursor: 'pointer'
                        }
                    }
                }
            }}
            onClick={() => setGroupSelected()}
            >
                {
                    props.hasChild ?
                    <Stack.Item>
                        <Icon iconName='ChevronRightSmall' className={classEntry} />
                    </Stack.Item>
                    :
                    <Stack.Item>
                        <Icon iconName='' className={classEntry} />
                    </Stack.Item>
                }
                <Stack.Item style={{
                        fontWeight: 400,
                        fontSize: '.875rem',
                        fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#161616'
                    }}>
                    {props.title}
                </Stack.Item>
            </Stack>
            <Stack key={"content-" + props.id} className={contentClass} hidden={childrenHidden}>
                {props.children}
            </Stack>
        </Stack>
    );
}

//#endregion

//#region DOCS - Container

export const DocsContainer : React.FC = (props) => {
    const siteContainerClass = mergeStyles([{
        position: 'relative',
        height: 'calc(100% - 51px)',
        width: '100%'
    }]);

    return (
        <Stack
            key={"siteContainer"}
            className={siteContainerClass}
            horizontal
        >
            {props.children}
        </Stack>
    );
}

//#endregion

//#region DOCS - Content

export const DocsContent: React.FC = (props) => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [content, setContent] = useState<string>("");
    const [environment, setEnvironment] = useState<string>("Educational");
    const [indexContent, setIndexContent] = useState<JSX.Element>();
    const customerService = new CustomerService();
    const customerSetupConfig: ICustomerInformationContext = useContext(CustomerInformationContext);
    const navigate = useNavigate();
    
    let { cultureCode, fileName } = useParams<string>();

    const contentContainerClassName = mergeStyles([{
        position: 'relative',
        width: '60%',
        marginTop: '30px',
        '@media screen and (max-width: 910px)': {
            width: '100%',
            paddingLeft: '25px',
            paddingRight: '25px',
            position: 'none',
            marginTop: '50px'
        },
        '@media screen and (max-width: 1090px) and (min-width: 910px)': {
            width: '70%',
        }
    }]);

    const mdStyle: CSSProperties | undefined = {
        textRendering: 'optimizeLegibility',
        fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        fontSize: '16px',
        color: '#161616'
    }

    //#region Video resource data

    const getVideoSource = (videoUrl: string, videoName: string): Plyr.SourceInfo => {
        let result: Plyr.SourceInfo = {} as Plyr.SourceInfo;
        result.type = "video";
        result.title = videoName;
        result.sources = [];
        result.poster= videoThumbnail;
        
        let isYoutubeVideo: boolean = videoUrl.indexOf("youtube.com") >= 0;
        if (isYoutubeVideo)
        {
            let srcUrl = videoUrl;
            if (srcUrl.indexOf("https://youtube.com/embed/") >= 0)
                srcUrl = srcUrl.replace("https://youtube.com/embed/", "");
            else if (srcUrl.indexOf("https://www.youtube.com/embed/") >= 0)
                srcUrl = videoUrl.replace("https://www.youtube.com/embed/", "");

            result.sources.push({
                src: srcUrl,
                provider: 'youtube'
            });
        }
        else
        {
            let videoType = "";
            if (videoUrl.indexOf(".mp4"))
                videoType = "video/mp4";
            else if (videoUrl.indexOf(".webm"))
                videoType = "video/webm";

            result.sources.push({
                src: videoUrl,
                type: videoType,
            });
        }
        return result;
    }

    const transformVideoTag = (props: any) => {
        let source = getVideoSource(props.src!, "Video");

        const playerIconClassName = mergeStyles([{
            backgroundColor: 'white',
            color: '#2A7DE1',
            fontFamily: 'Nunito',
            padding: 15,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            cursor: 'pointer',
            transition: 'all 0.5s',
            selectors: {
                "&:hover": {
                    backgroundColor: '#143e70',
                    color: 'white',
                    transition: 'all 0.5s'
                }
            }
        }]);

        return(
            <ReactPlayer
                width={"98%"} 
                height={"450px"} 
                style={{
                    border: '1px solid #bebebe',
                    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)'
                }}
                playIcon={
                    <div className={playerIconClassName}> 
                        <Icon iconName='Play' style={{
                            fontSize: 35,
                        }}></Icon>
                        <label style={{
                            textTransform: 'uppercase',
                            fontSize: 20,
                            fontWeight: 700,
                            cursor: 'pointer'
                        }}>{t('docs:Site:Text000004Lbl')}</label>
                    </div>
                }
                controls={true}
                pip={true}
                playing={true}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                light={source.poster} 
                url={props.src!}
            />
        )
    }

    //#endregion

    //#region Link resource data

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLElement | HTMLButtonElement, MouseEvent> | Event, rawUrl: string) => {
        event.preventDefault();
        let url = getUrl(rawUrl);

        if(isSelfLink(rawUrl) && !isAnchor(rawUrl)){
            navigate(url);
        }
        else if(isAnchor(rawUrl)){
            navigate(url, {replace: true});
            navigateToAnchor();
        }
        else{
            window.open(url, '_blank');
        }
    };

    const navigateToAnchor = () => {
        if(window.location.hash.length !== 0) {
            let elementId: string = window.location.hash.substring(1);
            let element = document.getElementById(elementId);
            if(element !== null){
                var headerOffset = 60;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.scrollY - headerOffset;
            
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }
    }

    const getUrl = (rawUrl: string): string => {
        if(isSelfLink(rawUrl)){
            let parts: string[] = rawUrl.split('?');
            return window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')) + '/' + parts[0].substring(0, parts[0].length - 3);
        }

        let externalUrl: string = decodeURIComponent(rawUrl);
        if(isAnchor(rawUrl)){
            return rawUrl;
        }
        else{
            if(externalUrl.includes("businesscentral.dynamics.com")){
                externalUrl = externalUrl.replace('%1', customerSetupConfig.setupConfig.tenantId);
                externalUrl = externalUrl.replace('%2', environment);
            }

            return externalUrl;
        }
    };

    const isSelfLink = (rawUrl: string): boolean => {
        let urlParts: string[] = [];
        if(rawUrl.includes('?')){
            urlParts = rawUrl.split('?');
            return(urlParts[1].includes('self=true'));
        }

        return false;
    };

    const isAnchor = (rawUrl: string): boolean => {
        let url: string = decodeURIComponent(rawUrl);
        return url.includes('#');
    };

    const transformLinkTag = (props: any) => {
        const {node, ...rest} = props;

        //We use tag <a> for links and all other custom behavior, if type is not defined we use the classic link
        switch(props.type){
            case "tooltipImg":
                return buildTooltipImg(props, rest);
            default:
                return (
                    <Link
                        onClick={(event) => {handleLinkClick(event, props.href)}}
                        {...rest}
                        styles={{
                            root: {
                                fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
                                padding: '0px !important',
                                marginBottom: 10,
                                selectors: {
                                    '::first-letter': {
                                        textTransform: 'capitalize'
                                    }
                                }
                            }
                        }}
                    >
                        {props.children}
                    </Link>
                )
        }
    }
    

    const buildTooltipImg = (props: any, rest: any) => {
        return (
            <TooltipHost
                tooltipProps={{
                    onRenderContent: () => {
                        return <Image styles={{root: {width: '100%', height: 'auto'}}} src={props.src} imageFit={ImageFit.cover} />
                    }
                }}
                styles={{ 
                    root: { 
                        display: 'inline-block',
                        fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
                        color: '#0078D4',
                    }
                }}
                directionalHint={DirectionalHint.topCenter}
                delay={TooltipDelay.zero}
                calloutProps={{
                    calloutWidth: 600,
                    calloutMaxWidth: 600,
                    styles: {
                        root: {
                            border: '1px solid black',
                        }
                    }
                }}
            >
                {props.children[0]}
            </TooltipHost>
        );
    }

    //#endregion

    //#region Table resource data

    const transformTableTag = (props: any) => {
        const {node, ...rest} = props;

        return (
            <Stack
                styles={{
                    root: {
                        overflowX: "auto",
                        marginRight: 10
                    }
                }}
            >
                <table
                    {...rest}
                >

                </table>
            </Stack>
        )
    }

    //#endregion

    const loadContentData = async (fileName: string | undefined) : Promise<boolean> => {
        return new Promise(async (resolve, rejects) => {
            try 
            {
                if(cultureCode !== undefined && fileName == "index"){
                    customerService.getDocsIndexMdContent(cultureCode).then((resp) => {
                        if(resp.statusCode !== 200)
                            rejects(false);
                        
                        setContent(resp.content);
                        resolve(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        rejects(false);
                    });
                }
                else if(cultureCode !== undefined && fileName !== undefined){
                    customerService.getDocsDocumentData(cultureCode, fileName!).then((resp) => {
                        if(resp.statusCode !== 200)
                            rejects(false);
                        
                        setContent(resp.content);
                        resolve(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        rejects(false);
                    });
                }
                else{
                    rejects(false);
                }
            }
            catch
            {
                rejects(false);
            }
        });
    }

    const scrollIntoViewSidebar = () => {
        let anchor: string = window.location.hash;
        
        const elements = document.querySelectorAll('[id]');
        const regex = new RegExp(`^${fileName}.*@`);

        let element = Array.from(elements).find(el => regex.test(el.id));
        if(element !== undefined){
            let groupId: string[] = element?.id.split('@')[1].split('.');

            //Open process group
            let processModuleId: string = "MODULE-" + groupId[0];
            let processId: string = "MODULE-" + groupId[0] + "-PROCESS-" + groupId[1];
            let processModuleElement = document.getElementById(processModuleId);
            let processElement = document.getElementById(processId);
            
            if(processModuleElement !== null){
                if(!processModuleElement.hasAttribute("groupActive")){
                    processModuleElement.click();
                }

                if(processElement !== null){
                    if(!processElement.hasAttribute("groupActive")){
                        processElement.click();
                    }
    
                    element!.scrollIntoView({behavior: 'smooth', block: 'nearest'});
                    let htmlELement = document.getElementById(element.id);
                    if(htmlELement !== null){
                        htmlELement.click();

                        if(anchor.length > 0){
                            navigate(anchor, {replace: true});
                            navigateToAnchor();
                        }
                    }
                }
            }
        }
    }

    const loadMDIndex = (): JSX.Element => {
        let elements: JSX.Element[] = [];
        let paragraphs = document.getElementsByClassName('anchor');

        for (let i = 1; i < paragraphs.length; i++) {
            const current = paragraphs[i] as HTMLElement;

            elements.push(
                <Link 
                    onClick={(event) => handleLinkClick(event, current.getAttribute('href')?.toString()!)}
                    id={'ANCHOR-' + current.getAttribute('href')?.toString()!}
                    styles={{
                        root: {
                            fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
                            padding: '0px !important',
                            marginBottom: 10,
                            selectors: {
                                '::first-letter': {
                                    textTransform: 'capitalize'
                                }
                            }
                        }
                    }}
                >
                    {buildLinkIndexContent(current.getAttribute('href')?.toString()!)}
                </Link>
            )
        }

        return(
            <Stack
                verticalAlign="start"
                verticalFill
            >
                <Stack>
                    <ComboBox
                        label={t('docs:Site:EnvironmentLbl')!}
                        defaultSelectedKey={'Educational'}
                        options={[
                            {
                                text: 'Educational',
                                key: 'Educational'
                            },
                            {
                                text: 'Production',
                                key: 'Production'
                            }
                        ]}
                        onChange={(ev, option, index, value) => setEnvironment(option?.key.toString()!)}
                    />
                </Stack>
                <Stack>
                    <Text
                        styles={{
                            root: {
                                fontSize: '18px',
                                fontFamily: 'Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif',
                                marginBottom: 10,
                                marginTop: 30,
                                borderBottom: '1px solid #161616'
                            }
                        }}
                    >
                        {t('docs:Site:IndexLbl')!}
                    </Text>
                </Stack>
                <Stack>
                    {elements}
                </Stack>
            </Stack>
        );
    };

    const buildLinkIndexContent = (value: string): string => {
        let newValue: string = value;
        newValue = newValue.replaceAll('#', ' ').replaceAll('-', ' ');

        return newValue;
    }

    useEffect(() => {
        document.documentElement.setAttribute('data-color-mode', 'light');

        setDataStatus(SystemDataLoadingStatus.Loading);
        loadContentData(fileName!).then(() => {
            scrollIntoViewSidebar();
            setDataStatus(SystemDataLoadingStatus.Loaded);
            setIndexContent(loadMDIndex());
        })
        .catch(() => {
            setDataStatus(SystemDataLoadingStatus.Error);
        });
    },[window.location.pathname]);

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.Loaded){
            navigateToAnchor();
        }
    }, [dataStatus])

    if(dataStatus == SystemDataLoadingStatus.Loading){
        return (
            <Stack styles={{
                root: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    marginTop: '30px',
                    width: '100%',
                    height: '100%'
                }
            }}>
                <Spinner size={SpinnerSize.large} label={t('docs:Site:Text000003Lbl')!} />
            </Stack>
        );
    }
    else if(dataStatus == SystemDataLoadingStatus.Error){
        return (
            <PageNotWorking>
                <strong>{t('docs:Site:Text000001Err')}</strong>
                <br />
                {t('docs:Site:Text000002Err')}<a href='mailto:helpdesk@so-smart.it'>{t('docs:Site:Text000003Err')}</a>
            </PageNotWorking>
        );
    }
    else {
        return(
            <>
                <Stack
                    className={contentContainerClassName}
                >
                    <MarkdownPreview
                        source={content}
                        style={mdStyle}
                        rehypePlugins={[[rehypeVideo, {test: new RegExp(/\/(.*)(.mp4|.mov|.webm)$/), details: false}]] as any}
                        remarkPlugins={[remarkAlert]}
                        components={{
                            video(props) {
                                return transformVideoTag(props);
                            },
                            a(props) {
                                return transformLinkTag(props);
                            },
                            table(props) {
                                return transformTableTag(props)
                            }
                        }}
                    />
                    <MediaQuery maxWidth={1090}>
                        <DocsMarkdownIndex>
                            {
                                indexContent
                            }
                        </DocsMarkdownIndex>
                    </MediaQuery>
                </Stack>
                <MediaQuery minWidth={1090}>
                    <DocsMarkdownIndex>
                        {
                            indexContent
                        }
                    </DocsMarkdownIndex>
                </MediaQuery>
            </>
        );
    }
};

//#endregion

//#region DOCS - PageNotWorking

export const PageNotWorking: React.FC = (props) => {
    
    const containerClassName = mergeStyles([{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }]);

    const imageContainerClassName = mergeStyles([{
        backgroundImage: "url('" + errorImage + "')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '25%',
        height: '30vh'
    }]);

    const textContainerClassName = mergeStyles([{
        fontSize: '1.2vw',
        fontFamily: 'tondo',
        color: 'rgb(42, 125, 225)',
        textAlign: 'center'
    }]);

    const containerTokens: IStackTokens = {
        childrenGap: 20
    }
    
    return (
        <Stack className={containerClassName} tokens={containerTokens}>
            <Stack.Item className={imageContainerClassName}>
                &nbsp;
            </Stack.Item>
            <Stack.Item className={textContainerClassName}>
                {props.children}
            </Stack.Item>
        </Stack>
    );
}

//#endregion

export const DocumentationPortal : React.FC = () => {
    const [indexResponse, setIndexResponse] = useState<DocsIndexStructure[]>([]);
    const [searchIndexResponse, setSearchIndexResponse] = useState<DocsSearchIndexStructure[]>([]);
    const [filteredIndex, setFilteredIndex] = useState<IContextualMenuItem[]>([]);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [filterDataStatus, setFilterDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [showSearchMenu, setShowSearchMenu] = useState<boolean>(false);
    const [showMenuPanel, setShowMenuPanel] = useState<boolean>(false);
    const [searchTextValue, setSearchTextValue] = useState<string>("");  
    const searchLinkRef = useRef(null);
    const customerService = new CustomerService();
    let sessionStateContext: ISessionStateContext = useContext<ISessionStateContext>(SessionStateContext);

    let { cultureCode, fileName } = useParams<string>();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 910 });

    //#region Styles

    const panelMenuButtonStyle = mergeStyles([{
        position: 'absolute',
        backgroundColor: 'transparent',
        border: '1px solid black',
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
        marginLeft: 25,
        color: 'black',
        selectors: {
            ':hover': {
                cursor: 'pointer',
                backgroundColor: '#0078D4'
            }
        }
    }]);

    //#endregion

    const loadData = async () : Promise<boolean> => {
        return new Promise(async (resolve, rejects) => {
            try
            {
                if(cultureCode !== undefined && fileName === undefined){
                    navigate('index');
                    resolve(true);
                }
                if(cultureCode !== undefined){
                    customerService.getDocsIndexContent(cultureCode).then((resp) => {
                        if(resp.statusCode !== 200)
                            rejects(false);
    
                        let structure: DocsIndexStructure[] = JSON.parse(resp.content);
                        setIndexResponse(structure);
                        buildSearchItems(structure);
                        resolve(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        rejects(false);
                    });
                }
                else{
                    rejects(false);
                }
            }
            catch
            {
                rejects(false);
            }
        });
    }

    const scrollIntoViewSidebar = (id: string) => {
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) 
                element.scrollIntoView({ behavior: 'smooth' });
        }, 250);
    }

    const clearSelectedProcessTest = () => {
        let elements = document.querySelectorAll('[processActive="true"]');
        if(elements !== null){
            elements.forEach((item: Element, index: number, array) => {
                item.removeAttribute("processActive");
            });
        }
    }

    const setSelectedProcessTest = (id: string) => {
        clearSelectedProcessTest();

        let element = document.getElementById(id);
        if(element !== null){
            element.setAttribute("processActive", "true");
        }
    }

    const checkElementSelected = (id: string): boolean => {
        let element = document.getElementById(id);
        if(element !== null){
            if(element.hasAttribute("processActive")){
                return true;
            }
            else {
                return false;
            }
        }

        return false;
    }

    const buildSearchItems = (index: DocsIndexStructure[]) => {
        let searchItems: DocsSearchIndexStructure[] = [];
        index.forEach((processModule: DocsIndexStructure, processModuleIndex: number, processModuleArray: DocsIndexStructure[]) => {
            processModule.processes.forEach((processItem: DocsProcessStructure, processIndex: number, processArray: DocsProcessStructure[]) => {
                processItem.tests.forEach((processTest: DocsProcessTestStructure, processTestIndex: number, processTestArray: DocsProcessTestStructure[]) => {
                    let elementId: string = processTest.fileName + "@" + processModuleIndex + "." + processIndex;

                    //Handle item for search functionality
                    let newSearchItem: DocsSearchIndexStructure = new DocsSearchIndexStructure();
                    newSearchItem.key = processTest.fileName.substring(0, processTest.fileName.length - 3);
                    newSearchItem.title = processTest.title;
                    newSearchItem.processModuleTitle = processModule.title;
                    newSearchItem.processTitle = processItem.title;
                    searchItems.push(newSearchItem);
                })
            })
        });

        setSearchIndexResponse(searchItems);
    }

    const buildSidebar = (): JSX.Element[] => { 
        let elements: JSX.Element[] = [];

        elements = indexResponse.map((processModule: DocsIndexStructure, processModuleIndex: number, processModuleArray: DocsIndexStructure[]) => {
            let elementId: string = "MODULE-"+processModuleIndex;

            return (
                <SidebarProcessModuleItem
                    id={elementId}
                    hasChild={processModule.processes.length > 0}
                    title={processModule.title}
                >
                    {
                        processModule.processes.map((processItem: DocsProcessStructure, processIndex: number, processArray: DocsProcessStructure[]) => {
                            let elementId: string = "MODULE-"+processModuleIndex + "-PROCESS-" + processIndex;

                            return(
                                <SidebarProcessItem
                                    id={elementId}
                                    hasChild={processItem.tests.length > 0}
                                    title={processItem.title}
                                >
                                    {
                                        processItem.tests.map((processTest: DocsProcessTestStructure, processTestIndex: number, processTestArray: DocsProcessTestStructure[]) => {
                                            let elementId: string = processTest.fileName + "@" + processModuleIndex + "." + processIndex;
                                            
                                            return(
                                                <SidebarProcessTestItem
                                                    id={elementId}
                                                    isActive={checkElementSelected(elementId)}
                                                    title={processTest.title}
                                                    onClick={(id: string, fileName: string) => {
                                                        setSelectedProcessTest(elementId);
                                                        if(isMobile){
                                                            setShowMenuPanel(false);
                                                        }

                                                        document.title = processTest.title;
                                                        navigate(encodeURIComponent(processTest.fileName.substring(0, processTest.fileName.length - 3)));
                                                    }}
                                                    fileName={processTest.fileName}
                                                />
                                            );
                                        })
                                    }
                                </SidebarProcessItem>
                            );
                        })
                    }
                </SidebarProcessModuleItem>
            )
        });

        return elements;
    }

    let timeOutId: number | undefined = undefined;
    const findDataFromInput = (input: string | undefined) => {
        window.clearTimeout(timeOutId);
        timeOutId = window.setTimeout(() => {
            setFilterDataStatus(SystemDataLoadingStatus.Loading);
            if(input == undefined){
                setFilteredIndex([]);
                return;
            }
            if(input.length <= 2){
                setFilteredIndex([]);
                return;
            }
    
            let filteredItems: DocsSearchIndexStructure[] = [];
            let searchText: string = input.toLowerCase();
            let searchArray: string[] = input.split(' ');

            if(searchArray.length > 0){
                filteredItems = filteredItems.concat(searchIndexResponse.filter((value: DocsSearchIndexStructure) => {
                    const wordPresent = searchArray.filter(word => new RegExp(word.toLowerCase(), "i").test(value.title.toLowerCase()));
                    const percentage = wordPresent.length / searchArray.length;
                    return percentage >= 0.7;
                }));
            }
            else {
                filteredItems = searchIndexResponse.filter((value: DocsSearchIndexStructure) => {
                    return value.title.toLowerCase().includes(searchText);
                });
            }
    
            let menuValue: IContextualMenuItem[] = []
            filteredItems.forEach((value: DocsSearchIndexStructure) => {
                menuValue.push(
                    {
                        key: value.key,
                        text: value.title,
                        data: value.processModuleTitle + "|" + value.processTitle + "|" + value.key
                    }
                )
            })

            setFilterDataStatus(SystemDataLoadingStatus.Loaded);
            setFilteredIndex(menuValue);
        }, 1000);
    }

    const onRenderSearchItems = (item: IContextualMenuItem | undefined): JSX.Element => {
        let parentData: string[] = item?.data.split("|");
        return (
            <Stack 
                verticalAlign="center" 
                verticalFill 
                tokens={{childrenGap: 10}}
                styles={{
                    root: {
                        marginBottom: 10,
                        marginTop: 5,
                        marginLeft: 5,
                        marginRight: 5,
                        selectors: {
                            "&:hover": {
                                backgroundColor: "#f2f2f2",
                                cursor: "pointer"
                            }
                        }
                    }
                }}
                onClick={() => {
                    setShowSearchMenu(false);
                    navigate(item?.key.toString()!);
                }}
            >
                <Stack.Item>
                    <span
                        style={{
                            fontWeight: 700
                        }}
                    >
                        {item?.text}
                    </span>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal horizontalAlign="start" tokens={{childrenGap: 2}}>
                        <span
                            style={{
                                fontWeight: 400
                            }}
                        >
                            {
                                parentData[0].length > 20 ? <TooltipHost content={parentData[0]}> {parentData[0].substring(0, 17) + "..."} </TooltipHost> : parentData[0]
                            }
                        </span> 
                        <span
                            style={{
                                fontWeight: 400
                            }}
                        >
                            &gt; 
                        </span>
                        <span
                            style={{
                                fontWeight: 400
                            }}
                        >
                            {
                                parentData[1].length > 20 ? <TooltipHost content={parentData[1]}> {parentData[1].substring(0, 17) + "..."} </TooltipHost> : parentData[1]
                            }
                        </span>
                    </Stack>
                </Stack.Item>
            </Stack>
        )
    }

    const onRenderSearchLoaderItems = (item: IContextualMenuItem | undefined): JSX.Element => {
        if(!SystemCore.isFinishedDataStatus(filterDataStatus))
            return (
                <Spinner label={t("common:loadingData")!} ariaLive="assertive" labelPosition="right" style={{
                    padding: 10,
                    width: '200px'
                }} />
            )
        
        return (
            <Stack 
                verticalAlign="center" 
                verticalFill 
                tokens={{childrenGap: 10}}
                styles={{
                    root: {
                        marginBottom: 10,
                        marginTop: 5,
                        marginLeft: 5,
                        marginRight: 5,
                        selectors: {
                            "&:hover": {
                                backgroundColor: "#f2f2f2",
                                cursor: "pointer"
                            }
                        }
                    }
                }}
            >
                {t("common:noData")!}
            </Stack>
        )
    }

    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            setDataStatus(SystemDataLoadingStatus.Loading);
            loadData().then(() => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch(() => {
                setDataStatus(SystemDataLoadingStatus.Error);
            });
        }
    }, [dataStatus]);

    useEffect(() => {
        return () => {
            //Reset title
            document.title = sessionStateContext.currentApp.title;
        };
    }, [])

    if ((dataStatus == SystemDataLoadingStatus.Loading) || (dataStatus == SystemDataLoadingStatus.ToLoad))
        return (
            <Stack style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                width: '100px',
                height: '100px'
            }}>
                <Spinner size={SpinnerSize.large} label={t('docs:Site:Text000003Lbl')!} />
            </Stack>
        );
    else if (dataStatus == SystemDataLoadingStatus.Error)
        return (
            <PageNotWorking>
                <strong>{t('docs:Site:Text000001Err')}</strong>
                <br />
                {t('docs:Site:Text000002Err')}<a href='mailto:helpdesk@so-smart.it'>{t('docs:Site:Text000003Err')}</a>
            </PageNotWorking>
        );

    return (
        <DocsContainer>
            <PrimaryButton 
                hidden={!isMobile}
                className={panelMenuButtonStyle}
                onClick={() => setShowMenuPanel(true)}
                iconProps={{iconName: 'DoubleChevronRight8'}}
            >
                {t("docs:navigationPanelButton")}
            </PrimaryButton>
            <Sidebar
                isPanelMenuOpen={showMenuPanel}
                onDismissPanelMenu={() => setShowMenuPanel(false)}
            >
                <Stack verticalFill verticalAlign="start">
                    <SearchBox 
                        placeholder={t("common:filterText")!}
                        ref={searchLinkRef}
                        onSearch={(text: string) => {
                            setShowSearchMenu(true);
                        }}
                        onClick={()=> setShowSearchMenu(true)}
                        onChange={(event?: ChangeEvent<HTMLInputElement>, newValue?: string)=> {
                            event?.preventDefault();
                            setSearchTextValue(newValue!);
                            findDataFromInput(newValue!);
                        }}
                        onClear={() => {
                            setShowSearchMenu(false);
                            setFilteredIndex([]); 
                        }}
                        styles={{
                            root: {
                                margin: 15,
                            }
                        }}      
                        iconProps={{iconName: 'Filter'}}
                        disabled={indexResponse.length === 0}
                        value={searchTextValue}
                    />
                    <ContextualMenu
                        items={[{key: "a"}]}
                        hidden={filteredIndex.length > 0 || searchTextValue == ""}
                        target={searchLinkRef}
                        onRenderContextualMenuItem={onRenderSearchLoaderItems}
                    />
                    <ContextualMenu
                        items={filteredIndex}
                        hidden={!showSearchMenu}
                        target={searchLinkRef}
                        onItemClick={(ev, item)=>{
                            setShowSearchMenu(false);
                            navigate(item?.key.toString()!);
                        }}
                        onDismiss={()=>{setShowSearchMenu(false)}}
                        onRenderContextualMenuItem={onRenderSearchItems}
                    />
                    <Stack
                        styles={{
                            root: {
                                height: 'calc(100% - 51px)',
                                overflowY: 'auto'
                            }
                        }}
                    >
                        {buildSidebar()}
                    </Stack>
                </Stack>
            </Sidebar>
            <Outlet></Outlet>
        </DocsContainer>
    );


}