import { ComboBox, Dialog, DialogFooter, DialogType, IComboBox, IComboBoxOption, IComboBoxStyles, Icon, IModalProps, IStackTokens, Label, Link, mergeStyles, MessageBar, MessageBarButton, MessageBarType, NeutralColors, PrimaryButton, Separator, SharedColors, Stack, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { CustomerDomain, CustomerGDAPRelationship, GDAPRelationshipStatus, RelationshipStatus, TenantInformationSetup } from "../../../Model/CustomerModels";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { t } from "i18next"

const tenantDetailBoxClassName = mergeStyles([{
}]);

const titleClassName = mergeStyles([{
    fontSize: 20,
    fontWeight: 500
}]);

const textClassName = mergeStyles([{
    fontSize: 15
}]);

const separatorStyles = {
    root: [{
      selectors: { // add selectors here
        '::before': {
          background: '#bbb',
        },
      }
    }]
};

const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 }, label: {fontSize: 12} };

const contentContainerClassName = mergeStyles([{
    marginTop: 20
}]);

const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 10
  };

const statusContainerClassName = mergeStyles([{
    paddingTop: 15
}]);

const partnerContainerClassName = mergeStyles([{
    paddingTop: 15
}]);

const pageCardContentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const pageCardModalContentClassName = mergeStyles([{
    width: '100%',
    marginTop: 20
}]);

const pageCardFormContentClassName = mergeStyles([{
    width: '100%'
}]);

export interface ICustomerTenantDetailBoxProps {
    tenantInfo: TenantInformationSetup;
    showModal: boolean;
    saveInProgress: boolean;
    onDismiss: (tenantInfo: TenantInformationSetup) => void;
    onCancel: () => void;
}

export const CustomerTenantDetailBox : React.FC<ICustomerTenantDetailBoxProps> = (props) => {
    const [relationshipStatus, SetRelationshipStatus] = useState(RelationshipStatus.Checking);
    const [gdapRelationshipStatus, setGDAPRelationshipStatus] = useState(GDAPRelationshipStatus.Checking)
    const [gdapRelationship, setGDAPRelationship] = useState(new CustomerGDAPRelationship());
    const [domains, setDomains] = useState([] as CustomerDomain[]);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const customerInfoContext = useContext(CustomerInformationContext);
    const customerService = new CustomerService();
    const [tenantInfo, setTenantInfo] = useState(props.tenantInfo);
    const [showSendEmailPart, setShowSendEmailPart] = useState<boolean>(true);
    const [adminMailErrorMessage, setAdminMailErrorMessage] = useState<string>("");
    const [mailSuccessLabelHidden, setMailSuccessLabelHidden] = useState<boolean>(true);
    const [isSendInProgress, setIsSendInProgress] = useState<boolean>(false);
    const mailAddress = useRef<string>("");

    const pageCardModalProps: Partial<IModalProps> = {
        isBlocking: false,
        dragOptions: undefined,
        styles: {
            main: {
                minWidth: '550px !important',
                position: 'absolute',
                top: 50
            }
        }
    };

    useEffect(() => {
        if ((dataStatus == SystemDataLoadingStatus.ToLoad) && (props.showModal))
        {
            setDataStatus(SystemDataLoadingStatus.Loading);
            customerService.getCustomerTenantDomains(customerInfoContext.setupConfig.customerId)
                .then((resp) => {
                    let tenantInfo = props.tenantInfo;
                    let domainsResult = resp;
                    for(let i = 0; i < resp.length; i++)
                    {
                        if (resp[i].useAsDefault)
                            tenantInfo.domain = resp[i].tenantDomain;
                        else if ((! tenantInfo.domain) && (resp[i].isDefault))
                            tenantInfo.domain = resp[i].tenantDomain;
                    }

                    setDomains(resp);
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch((err) => {
                    setDataStatus(SystemDataLoadingStatus.Error);
                });
        }
    }, [props.showModal]);

    useEffect(() => {
        if (SystemCore.isFinishedDataStatus(dataStatus))
        {
            if (customerInfoContext.setupConfig.hasTenant)
            {
                checkRelationshipStatus();
                checkGDAPRelationshipStatus();
            }
        }
    }, [dataStatus])

    const buildDomainOptions = () : IComboBoxOption[] => {
        let options: IComboBoxOption[] = [];
        for(let i = 0; i < domains.length; i++)
        {
            let domain = domains[i];
            options.push({
                key: domain.systemId,
                text: domain.tenantDomain,
                selected: domain.isDefault
            });
        }
        return options;
    };

    const checkRelationshipStatus = () => {
        SetRelationshipStatus(RelationshipStatus.Checking);

        customerService.getCustomerRelationshipStatus(customerInfoContext.setupConfig.customerId)
            .then((resp) => {
                if (resp)
                    SetRelationshipStatus(RelationshipStatus.Accepted);
                else
                SetRelationshipStatus(RelationshipStatus.NotAccepted);
            })
            .catch((err) => {
                SetRelationshipStatus(RelationshipStatus.NotAccepted);
                ToastService.showMessage(MessageType.Error, err);
            });
    }

    const checkGDAPRelationshipStatus = () => {
        setGDAPRelationshipStatus(GDAPRelationshipStatus.Checking);

        customerService.getCustomerGDAPRelationship(customerInfoContext.setupConfig.customerId)
            .then((resp) => {
                setGDAPRelationship(resp);

                if (resp === undefined || resp.id === undefined || !resp.id)
                    setGDAPRelationshipStatus(GDAPRelationshipStatus.NotFound);
                else if (resp.status.toLowerCase() === "approvalpending")
                    setGDAPRelationshipStatus(GDAPRelationshipStatus.NotAccepted);
                else if (resp.status.toLowerCase() === "active")
                    setGDAPRelationshipStatus(GDAPRelationshipStatus.Accepted);                    
            })
            .catch((err) => {
                setGDAPRelationshipStatus(GDAPRelationshipStatus.NotFound);
                ToastService.showMessage(MessageType.Error, err);
            })
    }

    const onChangeDomainComboBox = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) => {
        let newTenantInfo = new TenantInformationSetup();
        newTenantInfo.domain = value!;
        newTenantInfo.hasTenant = tenantInfo.hasTenant;
        newTenantInfo.tenantId = tenantInfo.tenantId;
        setTenantInfo(newTenantInfo);
    };

    const onDomainTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        let newTenantInfo: TenantInformationSetup = JSON.parse(JSON.stringify(tenantInfo));
        newTenantInfo.domain = newValue!;
        setTenantInfo(newTenantInfo);
    }

    const isGdapLinkHidden = () => {
        if (relationshipStatus !== RelationshipStatus.Accepted)
            return true;

        if (gdapRelationshipStatus === GDAPRelationshipStatus.Accepted)
            return true;

        if (gdapRelationship.signLink)
            return false;

        return true;
    }

    const isGdapStepHidden = () => {
        if (relationshipStatus === RelationshipStatus.Accepted)
            return false;

        return isGdapLinkHidden();
    }

    const partnerIconColor = () : string => {
        if (relationshipStatus === RelationshipStatus.Accepted)
            return "green"

        else
            return "black"
    }

    const gdapIconColor = () : string => {
        if (gdapRelationshipStatus === GDAPRelationshipStatus.Accepted)
            return "green"

        else
            return "black"
    }

    const getResellerLink = () : string => {
        let result = process.env.REACT_APP_CSP_RELATIONSHIP_URL!;
        if (customerInfoContext.customer.legalEntityCode === "EOL")
            result = result.replace("%TENANT_ID%", process.env.REACT_APP_CSP_TENANT_IT!);
        else if (customerInfoContext.customer.legalEntityCode === "ESS")
            result = result.replace("%TENANT_ID%", process.env.REACT_APP_CSP_TENANT_US!);

        return result;
    }
    
    const partnerLink = () : JSX.Element => {
        if (relationshipStatus === RelationshipStatus.Accepted)
            return <Label styles={{root: {color: 'green'}}}>{t('customerCenter:CustomerTenantDetailBox:Dialog:partnerLabelAccepted')}</Label>;
        else
            return <>
                <Label>
                    <a href={getResellerLink()} target="blank" style={{
                        color: 'black',
                    }}>{t('customerCenter:CustomerTenantDetailBox:Dialog:partnerLabel')}</a>
                </Label>
            </>;
    }
    
    const gdapLink = () : JSX.Element => {
        if (gdapRelationshipStatus === GDAPRelationshipStatus.Accepted)
            return <Label styles={{root: {color: 'green'}}}>{t('customerCenter:CustomerTenantDetailBox:Dialog:gdapLabelAccepted')}</Label>;
        else
            return <>
                <Label>
                    <a href={gdapRelationship.signLink} target="blank" style={{
                        color: 'black',
                    }}>{t('customerCenter:CustomerTenantDetailBox:Dialog:gdapLabel')}</a>
                </Label>
            </>;
    }
    
    function trySendEmailToAdmin() {
        setAdminMailErrorMessage("");
        setMailSuccessLabelHidden(true);
        setIsSendInProgress(true);

        if(mailAddress.current === ""){
            setAdminMailErrorMessage(t("customerCenter:CustomerTenantDetailBox:Dialog:errorMailLabel")!);
            setMailSuccessLabelHidden(true);
            setIsSendInProgress(false);

            return;
        }

        let linkUrl: string = getResellerLink();

        if (relationshipStatus === RelationshipStatus.Accepted)
            linkUrl = gdapRelationship.signLink;

        customerService.sendGDAPRequestToAdmin(mailAddress.current, linkUrl).then((resp)=>{
            setAdminMailErrorMessage("");
            setMailSuccessLabelHidden(false);
            setIsSendInProgress(false);
        })
        .catch((jqXHR)=>{
            setAdminMailErrorMessage(jqXHR);
            setIsSendInProgress(false);
        });
    }

    let messageBar = <></>;
    if (props.saveInProgress)
        messageBar = <Stack.Item>
                        <MessageBar>
                            {t('customerCenter:CustomerTenantDetailBox:MessageBar:messageContent1')}
                        </MessageBar>
                    </Stack.Item>;

    if (customerInfoContext.setupConfig.hasTenant)
    {
        let msgStatusBar: JSX.Element = <></>;
        let gdapStatusBar: JSX.Element = <></>;
        let partnerEmailBoxContent: JSX.Element = <></>;

        switch(relationshipStatus)
        {
            case RelationshipStatus.NotAccepted:
                msgStatusBar = <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    actions={
                    <div>
                        <MessageBarButton onClick={() => checkRelationshipStatus()}>{t('customerCenter:CustomerTenantDetailBox:MessageBar:messageBarButtonText')}</MessageBarButton>
                    </div>
                    }
                >
                    {t('customerCenter:CustomerTenantDetailBox:MessageBar:messageContent2')}
                </MessageBar>;

                partnerEmailBoxContent = <>
                </>;
                break;
            case RelationshipStatus.Checking:
                    msgStatusBar = <MessageBar
                        messageBarType={MessageBarType.info}
                        isMultiline={false}
                    >
                        {t('customerCenter:CustomerTenantDetailBox:MessageBar:messageContent3')}
                    </MessageBar>;
                    break;
            case RelationshipStatus.Accepted:
                msgStatusBar = <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                >
                    {t('customerCenter:CustomerTenantDetailBox:MessageBar:messageContent4')}
                </MessageBar>;
                break;
        }

        switch(gdapRelationshipStatus)
        {
            case GDAPRelationshipStatus.NotFound:
                gdapStatusBar = <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>{t('customerCenter:CustomerTenantDetailBox:GDAPMessageBar:notFound')}</MessageBar>;
                break;
            case GDAPRelationshipStatus.Checking:
                gdapStatusBar = <MessageBar messageBarType={MessageBarType.info} isMultiline={false}>{t('customerCenter:CustomerTenantDetailBox:GDAPMessageBar:checking')}</MessageBar>;
                break;
            case GDAPRelationshipStatus.NotAccepted:
                gdapStatusBar = <MessageBar messageBarType={MessageBarType.error} isMultiline={false} actions={
                    <div>
                        <MessageBarButton onClick={() => checkGDAPRelationshipStatus()}>{t('customerCenter:CustomerTenantDetailBox:GDAPMessageBar:messageBarButtonText')}</MessageBarButton>
                    </div>
                }>{t('customerCenter:CustomerTenantDetailBox:GDAPMessageBar:notAccepted')}</MessageBar>
                break;
            case GDAPRelationshipStatus.Accepted:
                gdapStatusBar = <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>{t('customerCenter:CustomerTenantDetailBox:GDAPMessageBar:accepted')}</MessageBar>
                break;
        }

        if (! props.showModal)
            return <></>;
        else if (!SystemCore.isFinishedDataStatus(dataStatus) && props.showModal)
            return <Loader text={t('customerCenter:CustomerTenantDetailBox:Loader:text')!}></Loader>;
        else
        {
            return (
                <Dialog hidden={! props.showModal}
                        modalProps={pageCardModalProps}
                        onDismiss={props.onCancel}
                        dialogContentProps={{
                            title: t('customerCenter:CustomerTenantDetailBox:Dialog:title')!,
                            type: DialogType.largeHeader
                        }}>
                            <Stack key="modalContent" className={pageCardModalContentClassName} tokens={{
                                childrenGap: 15
                            }}>
                                <Stack.Item key="modalBodyContent" className={pageCardContentBodyClassName}>
                                    <Stack key="tenantDetailBox" className={tenantDetailBoxClassName}>
                                        <Stack.Item>
                                            <Separator>1° Step</Separator>
                                            <ComboBox
                                                label={t('customerCenter:CustomerTenantDetailBox:Dialog:ComboBox:label')!}
                                                options={buildDomainOptions()}
                                                styles={comboBoxStyles}
                                                calloutProps={{ doNotLayer: true }}
                                                onChange={onChangeDomainComboBox}
                                                defaultValue={tenantInfo.domain}
                                                disabled={props.saveInProgress}
                                            />
                                        </Stack.Item>

                                        <Separator className={contentContainerClassName}>2° Step</Separator>
                                        <Stack key="resellerRelationship" hidden={relationshipStatus === RelationshipStatus.Accepted}>
                                            <Stack.Item >
                                                <Label style={{
                                                    fontSize: 12,
                                                }}>{t('customerCenter:CustomerTenantDetailBox:Dialog:partnerDescription')}</Label>
                                            </Stack.Item>
                                            <Stack horizontal tokens={horizontalGapStackTokens}>
                                                <Stack.Item align="center">
                                                    <Icon iconName={(relationshipStatus === RelationshipStatus.Accepted) ? "Lock" : "Unlock"} styles={{
                                                        root: { color: partnerIconColor() }
                                                    }}></Icon>
                                                </Stack.Item>
                                                <Stack.Item align="center">
                                                    {partnerLink()}
                                                </Stack.Item>
                                            </Stack>
                                        </Stack>

                                        <Stack className={contentContainerClassName} key="gdapRelationshipStep" hidden={isGdapStepHidden()}>
                                            <Separator>3° Step</Separator>
                                        </Stack>
                                        <Stack key="gdapRelationship" hidden={isGdapLinkHidden()}>
                                            <Stack.Item>
                                                <Label style={{
                                                    fontSize: 12
                                                }}>{t('customerCenter:CustomerTenantDetailBox:Dialog:gdapDescription')}</Label>
                                            </Stack.Item>
                                            <Stack horizontal tokens={horizontalGapStackTokens}>
                                                <Stack.Item align="center">
                                                    <Icon iconName={(gdapRelationshipStatus === GDAPRelationshipStatus.Accepted) ? "Lock" : "Unlock"} styles={{
                                                        root: { color: gdapIconColor() }
                                                    }}></Icon>
                                                </Stack.Item>
                                                <Stack.Item align="center">
                                                    {gdapLink()}
                                                </Stack.Item>
                                            </Stack>
                                        </Stack>

                                        <Stack key="emailHelper" hidden={(relationshipStatus === RelationshipStatus.Accepted) && isGdapLinkHidden()}>
                                            <Stack.Item className={contentContainerClassName}>
                                                <Label>
                                                    {t('customerCenter:CustomerTenantDetailBox:Dialog:sendEmailLabel')}
                                                    &nbsp;
                                                    <Link
                                                        styles={{ root:{ userSelect: 'none' } }}
                                                        onClick={()=>setShowSendEmailPart(!showSendEmailPart)}>
                                                            {t('customerCenter:CustomerTenantDetailBox:Dialog:sendEmailAction')}
                                                    </Link>
                                                </Label>
                                                <Stack styles={{
                                                    root:{
                                                        marginTop: 10,
                                                        marginBottom: 10
                                                    }}}
                                                    hidden={showSendEmailPart}
                                                    tokens={{childrenGap:10}}>
                                                        <TextField
                                                            placeholder={t('customerCenter:CustomerTenantDetailBox:Dialog:sendEmailTxtPlaceholder')!}
                                                            errorMessage={adminMailErrorMessage}
                                                            disabled={isSendInProgress}
                                                            onChange={(ev, newValue)=>{
                                                                mailAddress.current = newValue!
                                                                setAdminMailErrorMessage("");
                                                                setMailSuccessLabelHidden(true);
                                                            }}
                                                        />
                                                        <Stack 
                                                            horizontal
                                                            horizontalAlign="start"
                                                            tokens={{
                                                                childrenGap: 10
                                                            }}
                                                        >
                                                            <PrimaryButton
                                                                iconProps={{ iconName: 'Send' }}
                                                                onClick={() => {trySendEmailToAdmin()}}
                                                                disabled={isSendInProgress}
                                                            >
                                                                {t('customerCenter:CustomerTenantDetailBox:Dialog:sendEmailBtn')}
                                                            </PrimaryButton>
                                                            <Label
                                                                hidden={mailSuccessLabelHidden}
                                                                styles={{
                                                                    root:{
                                                                        color: '#008089'
                                                                    }
                                                                }}
                                                            >
                                                                {t('customerCenter:CustomerTenantDetailBox:Dialog:successLabel')}
                                                            </Label>
                                                        </Stack>
                                                </Stack>
                                            </Stack.Item>
                                        </Stack>
                                        <Stack.Item className={statusContainerClassName}>
                                            {msgStatusBar}
                                        </Stack.Item>
                                        {partnerEmailBoxContent}
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item hidden={!(relationshipStatus === RelationshipStatus.Accepted)}>
                                    {gdapStatusBar}
                                </Stack.Item>
                                {messageBar}
                                <Stack.Item key="modalFooterContent">
                                    <DialogFooter>
                                        <PrimaryButton text={t('leadDashboard:buttonText')!} onClick={() => {
                                            if (tenantInfo.domain == "")
                                                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerTenantDetailBox:Err00001Text'));
                                            else
                                            {
                                                /*if (relationshipStatus != RelationshipStatus.Accepted)
                                                    ToastService.showMessage(MessageType.Error, "Devi prima autorizzarci come partner della tua organizzazione");
                                                else*/
                                                    props.onDismiss(tenantInfo);
                                            }
                                        }} 
                                        disabled={props.saveInProgress} />
                                    </DialogFooter>
                                </Stack.Item>
                            </Stack>
                </Dialog>
            );
        }
    }
    else
    {
        if (!SystemCore.isFinishedDataStatus(dataStatus) && props.showModal)
            return <></>;
        else
        {
            return (
                <Dialog hidden={! props.showModal}
                        modalProps={pageCardModalProps}
                        onDismiss={props.onCancel}
                        dialogContentProps={{
                            title: t('customerCenter:CustomerTenantDetailBox:Dialog:title')!,
                            type: DialogType.largeHeader
                        }}>
                            <Stack key="modalContent" className={pageCardModalContentClassName} tokens={{
                                childrenGap: 15
                            }}>
                                <Stack.Item key="modalBodyContent" className={pageCardContentBodyClassName}>
                                    <Stack key="tenantDetailBox" className={tenantDetailBoxClassName}>
                                        <Stack.Item className={contentContainerClassName}>
                                        <TextField
                                            value={tenantInfo.domain}
                                            onChange={onDomainTextFieldChange}
                                            label={t('customerCenter:CustomerTenantDetailBox:Dialog:domainLabel')!}
                                            suffix=".onmicrosoft.com"
                                            disabled={props.saveInProgress}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                {messageBar}
                                <Stack.Item key="modalFooterContent">
                                    <DialogFooter>
                                        <PrimaryButton text="Ok" onClick={() => {
                                            if (tenantInfo.domain == "")
                                                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerTenantDetailBox:Err00001Text'));
                                            else
                                                props.onDismiss(tenantInfo);
                                        }}
                                        disabled={props.saveInProgress} />
                                    </DialogFooter>
                                </Stack.Item>
                        </Stack>
                </Dialog>
            );
        }
    }
}