import { createContext, useState } from "react";
import { Distributor } from "../../Model/DistributorModel";

export interface IDistributorInformationContext {
    distributor: Distributor;
}

export interface IDistributorInformationContextProviderProps {
    distributor: Distributor;
}

let defaultValue : IDistributorInformationContext = {} as IDistributorInformationContext;
defaultValue.distributor = new Distributor();

export const DistributorInformationContext = createContext<IDistributorInformationContext>(defaultValue);
export const DistributorInformationContextProvider : React.FC<IDistributorInformationContextProviderProps> = (props) => {
    const [distributor, setDistributor] = useState<Distributor>(props.distributor);

    return <DistributorInformationContext.Provider value={{ distributor: distributor }}>{props.children}</DistributorInformationContext.Provider>
}