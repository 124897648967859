import { ICommandBarItemProps,Image,mergeStyles,PrimaryButton,Stack} from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Lead, PartnerDashboardCue, SharepointFile } from "../../../Model/PartnerModels";
import { SystemDataLoadingStatus, SystemOperation } from "../../../Model/SystemModels";
import { Banner, CueBox, DashboardCarousel, DashboardContentColumn, DashboardContentContainer, DashboardContentRow, DataBox, UserProfileCard } from "../../Common/Dashboard/DashboardComponents";
import { Loader } from "../../Common/Loader/Loader";
import { PageContainerBase } from "../../Common/Template/PageContainer";
import { PartnerLeadCard } from "../PartnerLeadCard/PartnerLeadCard";
import { ActionBar, CarouselItem, DashboardContainer, PartnerContractStatus, PartnerSocialSlide, SharepointDocumentList} from "./PartnerDashboardComponents";
import { t } from "i18next";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import { PartnerPartnerCard } from "../PartnerPartnerCard/PartnerPartnerCard";

const dashboardCarouselClassName = mergeStyles([{
    width: "100%",
    height: "auto",
    position: "relative",
    margin: "auto"
}])

export const PartnerDashboard : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [dashboardCue,setDashboardCue] = useState<PartnerDashboardCue>({} as PartnerDashboardCue);
    const [sharepointFiles, setSharepointFiles] = useState<SharepointFile[]>([] as SharepointFile[]);
    const sessionInfoContext = useContext(SessionStateContext);
    const partnerInfoContext = useContext(PartnerInformationContext);
    const leadCardRef = useRef<PageContainerBase>(null);
    const partnerCardRef = useRef<PageContainerBase>(null);
    const partnerService = new PartnerService();
    const navigate = useNavigate();
    const [isContractSigned, setIsContractSigned] = useState<boolean>(false);

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            partnerService.getPartnerDashboardCue()
            .then((resp: PartnerDashboardCue) => {
                setDashboardCue(resp);
                partnerService.getSharepointFiles(resp.partnerId)
                .then((resp: SharepointFile[]) =>{
                    setSharepointFiles(resp);                    
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch(() => {
                    setSharepointFiles([])
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
            })
        }
    },[dataStatus])

    const handleIsContractSigned = (isSigned: boolean) => { setIsContractSigned(isSigned); };

    const buildSiteUrl = (): string => {
        if (partnerInfoContext.partner.legalEntityCode === 'ESS')
            return "https://www.so-smart.us/";
        else
            return "https://www.so-smart.it/";
    }

    const buildShopUrl = (): string => {
        if (partnerInfoContext.partner.legalEntityCode === 'ESS')
            return process.env.REACT_APP_SHOP_URL+".us/";
        else
            return process.env.REACT_APP_SHOP_URL+".it/";
    }

    const buildMailTo = (): string => {
        if (partnerInfoContext.partner.legalEntityCode === 'ESS')
            return "mailto:orders@so-smart.us";
        else
            return "mailto:orders@so-smart.it";
    }
    
    const openCompanyDataCard = () => {
        partnerCardRef.current?.addAfterSaveCallback(() => {window.location.replace("/app/partner/home");})
        partnerCardRef.current?.setRecord(partnerInfoContext.partner);
        partnerCardRef.current?.openPage(SystemOperation.Update);
    }

    //#region ActionProps

    const Items: ICommandBarItemProps[] = [
        {
            key: 'NewLead',
            text: t('partnerCenter:partnerDashboard:actions:newLead')!,
            iconProps: { iconName: 'AddFriend' },
            // disabled: !isContractSigned,
            onClick: () => {
                let newRecord = new Lead();
                newRecord.partnerId = partnerInfoContext.partner.id;
                newRecord.legalEntityCode = partnerInfoContext.partner.legalEntityCode;
                newRecord.distributorCode = partnerInfoContext.partner.distributorCode;
                newRecord.partnerBusinessContractCode = partnerInfoContext.partner.partnerBusinessContractCode;
                newRecord.businessLineCode = partnerInfoContext.partner.businessLineCode;
                newRecord.languageCode = partnerInfoContext.partner.languageCode;
                newRecord.marketingQualificationCarriedOut = true;
                leadCardRef.current?.addAfterSaveCallback(() => { setDataStatus(SystemDataLoadingStatus.ToLoad); });
                leadCardRef.current?.setRecord(newRecord);                
                leadCardRef.current?.openPage(SystemOperation.Insert);
            }
        },
        {
            key: 'WebSite',
            text: t('partnerCenter:partnerDashboard:actions:webSite')!,
            iconProps: { iconName: 'Globe' },
            onClick: () => { window.open(buildSiteUrl(),'_blank') }
        },
        {
            key: 'Ticket',
            text: t('partnerCenter:partnerDashboard:actions:incident')!,
            iconProps: { iconName: 'Comment' },
            onClick: () => { navigate("/app/partner/incidents") }
        },
        {
            key: 'CompanyData',
            text: t('partnerCenter:partnerDashboard:actions:companyData')!,
            iconProps: { iconName: 'ContactCard' },
            onClick: () => {
                openCompanyDataCard();
            }
        }
    ]

    //#endregion

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={t('partnerCenter:partnerDashboard:loadingMessage')!} />
    else
    {
        return (
            <>
                <DashboardContainer>
                    <ActionBar items={Items}></ActionBar>
                    <Banner 
                        leftContent={
                            <UserProfileCard/>
                        }
                        rightContent={
                            <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                                <CarouselItem
                                    title={t('partnerCenter:partnerDashboard:carousel:newLeads')!}
                                    description={t('partnerCenter:partnerDashboard:carousel:newLeadsDescription', {value: dashboardCue.newLead})!}
                                    iconName="UserFollowed"
                                />
                                <CarouselItem
                                    title={t('partnerCenter:partnerDashboard:carousel:newCustomers')!}
                                    description={t('partnerCenter:partnerDashboard:carousel:newCustomersDescription', {value: dashboardCue.newCustomer})!}
                                    iconName="FabricUserFolder"
                                />
                                <CarouselItem
                                    title={t('partnerCenter:partnerDashboard:carousel:turnover', {value: dashboardCue.totalRevenue})!}
                                    description={t('partnerCenter:partnerDashboard:carousel:turnoverDescription', {value: dashboardCue.newRevenue})!}
                                    iconName="Money"
                                />
                                <CarouselItem
                                    title={t('partnerCenter:partnerDashboard:carousel:subscriptions', {value: dashboardCue.totalSubscription})!}                               
                                    description={t('partnerCenter:partnerDashboard:carousel:subscriptionsDescription', {value: dashboardCue.newSubscription})!}
                                    iconName="Subscribe"
                                />
                            </DashboardCarousel>
                        }
                    />
                    <DashboardContentRow id={'1'}>
                        <Stack key={"dashboardGeneralIndicator"} styles={{root: {width: '55%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t('partnerCenter:partnerDashboard:generalIndicators')!} name="generalIndicators">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="ActiveLead"
                                            title={t('partnerCenter:partnerDashboard:activeLeads')!}
                                            iconName="UserFollowed" 
                                            value={dashboardCue.activeLead}
                                            link={"/app/partner/leads?activeLeads=true"}
                                            // disabled={!isContractSigned}
                                        />
                                        <DataBox
                                            name="TotalLead"
                                            title={t('partnerCenter:partnerDashboard:totalLeads')!}
                                            value={dashboardCue.lead}
                                            iconName="CheckList"
                                            link={"/app/partner/leads"}
                                            // disabled={!isContractSigned}
                                        />
                                        <DataBox
                                            name="ActiveCustomer"
                                            title={t('partnerCenter:partnerDashboard:activeCustomers')!}
                                            value={dashboardCue.customer}
                                            iconName="UserEvent"
                                            link={"/app/partner/customers"}
                                            // disabled={!isContractSigned}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                        <Stack key={"dashboardPartnerContract"} styles={{root: {width: '45%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t('partnerCenter:partnerDashboard:contractTitle')!} name="generalIndicators">
                                    <PartnerContractStatus partner={partnerInfoContext.partner} fillCompanyData={openCompanyDataCard} handleIsContractSigned={handleIsContractSigned} />
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                    </DashboardContentRow>
                    <DashboardContentRow id={'2'}>
                        <Stack key={"dashboardSecondRowWidgets"} styles={{root: {width: '55%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t('partnerCenter:partnerDashboard:yourApps')!} name="appsContainer">
                                    <Stack id="appsSection" horizontal tokens={{childrenGap: 10}}>
                                        <CueBox name="Teams" isIconBox title="Teams" iconName="TeamsLogo" link={"https://aka.ms/mstfw?login_hint_safe=" + sessionInfoContext.currentUser.email} />
                                        <CueBox name="Outlook" isIconBox title="Outlook" iconName="OutlookLogo" link={"https://outlook.office365.com/mail/inbox"} />
                                        <CueBox name="OneDrive" isIconBox title="OneDrive" iconName="OneDriveLogo" link={"https://onedrive.live.com/login/"} />
                                        <CueBox name="PowerBi" isIconBox title="PowerBI" iconName="PowerBILogo16" link={"https://app.powerbi.com/home"} />
                                        <CueBox name="BusinessCentral" title="Business Central" iconName="Dynamics365Logo" link={"https://businesscentral.dynamics.com/"} value={
                                            <Image src="/business-central-logo-white_50.png" alt="Business Central" width={50} height={50} />
                                        } />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                        <Stack key={"dashboardSharepointDocs"} styles={{root: {width: '45%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer  title={t('partnerCenter:partnerDashboard:sharepointDocs')!} name="sharepointDocumentation">
                                    <SharepointDocumentList items={sharepointFiles} partnerId={dashboardCue.partnerId} />
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                    </DashboardContentRow>
                    <DashboardContentRow id={'3'}>
                        <Stack key={"dashboardSecondRowWidgets"} styles={{root: {width: '100%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer name="messageCarouselContainer" title={t('partnerCenter:partnerDashboard:tipsForYou')!}>
                                    <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                                        <PartnerSocialSlide />
                                        <Stack
                                            key={"message2"}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                minHeight: '320px',
                                                padding: 40,
                                                backgroundColor: 'rgb(217, 240, 242)'
                                        }}>
                                            <Stack.Item style={{
                                                fontSize: 32,
                                                padding: 10,
                                                display: 'flex',
                                                alignItems: 'stretch'
                                            }}>
                                                {t('partnerCenter:partnerDashboard:contacts:title')!}
                                            </Stack.Item>
                                            <Stack.Item style={{
                                                fontSize: 18,
                                                padding: 10,
                                                display: 'flex',
                                                alignItems: 'stretch'
                                            }}>
                                                <div>
                                                    {t('partnerCenter:partnerDashboard:contacts:text0001Lbl')!}<br />
                                                    {t('partnerCenter:partnerDashboard:contacts:text0002Lbl')!}
                                                    <br /><br />
                                                    <PrimaryButton
                                                        text={t('partnerCenter:partnerDashboard:contacts:contactUs')!}
                                                        styles={{
                                                            root: {
                                                                backgroundColor: '#008089',
                                                                borderColor: '#008089'
                                                            },
                                                            rootHovered: {
                                                                backgroundColor: 'rgb(1, 93, 99)',
                                                                borderColor: 'rgb(1, 93, 99)'
                                                            }
                                                        }}
                                                        onClick={() => {window.open(buildMailTo(), "_blank");}}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>
                                    </DashboardCarousel>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                    </DashboardContentRow>
                </DashboardContainer>
                <PartnerLeadCard ref={leadCardRef} />
                <PartnerPartnerCard ref={partnerCardRef} />
            </>
        );
    }
}
