import { ContextualMenu, DefaultButton, FontWeights, IButtonStyles, IconButton, IDragOptions, IIconProps, mergeStyles, Modal, PrimaryButton, Stack, Text, TooltipHost } from "@fluentui/react";

export interface ICommonModalPageProps {
    modalTitle: string;
    modalSubTitle?: string;
    primaryButtonEnabled: boolean;
    secondaryButtonEnabled: boolean;
    primaryButtonText?: string;
    primaryButtonTooltip?: string;
    secondaryButtonText?: string;
    dragOptionEnabled: boolean;
    isOpen: boolean;
    onDismissModal: () => void;
    onConfirmModal?: () => void;
    onCancelModal?: () => void;
}

export const CommonModalPage : React.FC<ICommonModalPageProps> = (props) => {

    //#region Modal CSS

    const modalContainerClassName = mergeStyles([{
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    }])

    const modalHeaderClassName = mergeStyles([{
        flex: '1 1 auto',
        borderTop: `4px solid #2B7DE1`,
        color: 'black',
        display: 'flex',
        alignItems: 'space-around',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
    }])

    const modalBodyClassName = mergeStyles([{
        width: 'calc(100vw - 100px)',
        height: 'calc(100vh - 200px)',
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { 
                margin: '14px 0'
            },
            'p:first-child': { 
                marginTop: 0 
            },
            'p:last-child': {
                marginBottom: 0
            },
        },
    }])

    const modalTitleClassName = mergeStyles([{
        color: 'black',
        fontWeight: FontWeights.regular,
        fontSize: '1.3rem',
        margin: '0',
        fontFamily: 'Segoe UI'
    }])

    const modalSubTitleClassName = mergeStyles([{
        color: 'black',
        fontWeight: FontWeights.light,
        fontSize: '1.1rem',
        margin: '0',
        fontFamily: 'Nunito'
    }])

    const footerButtonStyles = mergeStyles([{
        margin: '8px'
    }])

    const iconButtonStyles: IButtonStyles = {
        root: {
            color: 'gray',
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px'
        },
        rootHovered: {
            color: 'black'
        }
    };

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    //#endregion

    const dragOptions:IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
    }

    return (
        <Modal
            titleAriaId="CommonModalPage"
            isOpen={props.isOpen}
            containerClassName={modalContainerClassName}
            isBlocking={false}
            dragOptions={props.dragOptionEnabled ? dragOptions : undefined}
        >
            <Stack className={modalHeaderClassName} horizontal horizontalAlign="space-between">
                <Stack.Item verticalFill >
                    <Stack.Item styles={{root:{marginBottom:10}}}>
                        <Text className={modalTitleClassName}>{props.modalTitle}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text className={modalSubTitleClassName}>{props.modalSubTitle}</Text>
                    </Stack.Item>
                </Stack.Item>
                <Stack.Item>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={props.onDismissModal}
                    />
                </Stack.Item>
            </Stack>
            <Stack className={modalBodyClassName}>
                {props.children}
            </Stack>
            <Stack 
                hidden={!props.secondaryButtonEnabled || !props.primaryButtonEnabled}
                horizontal
                horizontalAlign="end"
            >
                <DefaultButton
                    className={footerButtonStyles}
                    hidden={!props.secondaryButtonEnabled}
                    onClick={props.onCancelModal}
                >
                    {props.secondaryButtonText}
                </DefaultButton>
                {props.primaryButtonTooltip ?
                    <TooltipHost
                        id={"primaryButtonTooltipId"}
                        content={props.primaryButtonTooltip}
                    >
                        <PrimaryButton
                            aria-describedby="primaryButtonTooltipId"
                            className={footerButtonStyles}
                            hidden={!props.primaryButtonEnabled}
                            onClick={props.onConfirmModal}
                        >
                            {props.primaryButtonText}
                        </PrimaryButton>
                    </TooltipHost> :
                    <PrimaryButton
                        className={footerButtonStyles}
                        hidden={!props.primaryButtonEnabled}
                        onClick={props.onConfirmModal}
                    >
                        {props.primaryButtonText}
                    </PrimaryButton>
                }
            </Stack>
        </Modal>
    )
}