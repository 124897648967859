import React, { forwardRef, useContext, useState } from "react";
import { SystemFieldType, SystemPageType } from "../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardField } from "../../Common/Template/Card/CardField";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { t } from "i18next";
import { DistributorInformationContext } from "../../../ApplicationCode/Distributor/DistributorInformationContext";
import { Lead } from "../../../Model/PartnerModels";
import { DefaultButton, Dialog, DialogFooter, DialogType, MessageBar, MessageBarType } from "@fluentui/react";

export const DistributorLeadCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    const distributorInfoContext = useContext(DistributorInformationContext);
    
    const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null);
    
    const checkRecord = (record: Lead): boolean => {
        setErrorMessage(null);
        let errors = checkLeadData(record);
        if (errors.length > 0) {
            displayErrorMessage(errors);
            return false;
        }
        
        return true;
    }
    
    const checkLeadData = (lead: Lead): Object[] => {
        let validationErrors : object[] = [];

        if (!lead.partnerId || lead.partnerId === 0)
            validationErrors.push({
                refererence: "partner",
                error: t('distributorCenter:distributorLeadCard:fieldError:partner')!,
                applyToField: true
            });
        if (!lead.companyName)
            validationErrors.push({
                reference: "companyName",
                error: t('distributorCenter:distributorLeadCard:fieldError:companyName')!,
                applyToField: true
            });
        if (distributorInfoContext.distributor.legalEntityCode === "EOL" && !lead.vat)
            validationErrors.push({
                reference: "vat",
                error: t('distributorCenter:distributorLeadCard:fieldError:vat')!,
                applyToField: (distributorInfoContext.distributor.legalEntityCode === "EOL")
            });
        if (!lead.email)
            validationErrors.push({
                reference: "email",
                error: t('distributorCenter:distributorLeadCard:fieldError:email')!,
                applyToField: true
            });
        if (!lead.firstname)
            validationErrors.push({
                reference: "firstname",
                error: t('distributorCenter:distributorLeadCard:fieldError:firstname')!,
                applyToField: true
            });
        if (!lead.lastname)
            validationErrors.push({
                reference: "lastname",
                error: t('distributorCenter:distributorLeadCard:fieldError:lastname')!,
                applyToField: true
            });
        if (!lead.phone)
            validationErrors.push({
                reference: "phone",
                error: t('distributorCenter:distributorLeadCard:fieldError:phone')!,
                applyToField: true
            });
        if (!lead.address)
            validationErrors.push({
                reference: "address",
                error: t('distributorCenter:distributorLeadCard:fieldError:address')!,
                applyToField: true
            });
        if (!lead.zipCode)
            validationErrors.push({
                reference: "zipCode",
                error: t('distributorCenter:distributorLeadCard:fieldError:zipCode')!,
                applyToField: true
            });
        if (!lead.city)
            validationErrors.push({
                reference: "city",
                error: t('distributorCenter:distributorLeadCard:fieldError:city')!,
                applyToField: true
            });
        if (!lead.county)
            validationErrors.push({
                reference: "county",
                error: t('distributorCenter:distributorLeadCard:fieldError:county')!,
                applyToField: true
            });
        if (!lead.country)
            validationErrors.push({
                reference: "country",
                error: t('distributorCenter:distributorLeadCard:fieldError:country')!,
                applyToField: true
            });
        
        return validationErrors;
    }
    
    const displayErrorMessage = (errors: Object[]) => {
        setErrorMessage(
            <MessageBar messageBarType={MessageBarType.error}>
                <ul>
                    {errors.map((value: any, index: number) => (
                        <li style={{marginBottom: "3px"}} key={index}>{value.error}</li>
                    ))}
                </ul>
            </MessageBar>
        );
    };
    
    return (
        <>
            <PageContainer pageType={SystemPageType.Card} endpoint="/odata/lead" title={t('partnerCenter:partnerLeadCard:title')!} ref={ref} onCheckRecord={(record: Lead) => checkRecord(record)}>
                <CardGroup name="partner" label={t('distributorCenter:distributorLeadCard:groupPartner')!} isOpen>
                    <CardField name="partnerId" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:partner')!} hasTableRelation tableRelationEndpoint="/odata/partner" tableRelationKey="id" tableRelationField="name" tableRelationFilter={"?$filter=distributorCode eq '"+distributorInfoContext.distributor.code+"'"} />
                </CardGroup>
                <CardGroup name="general" label={t('partnerCenter:partnerLeadCard:groupGeneral')!} isOpen={true}>
                    <CardField name="companyName" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:companyName')!}/>
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="vat" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:vat')!} />}
                    <CardField name="email" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:email')!}/>
                    <CardField name="firstname" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:firstname')!} />
                    <CardField name="lastname" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:lastname')!} />
                    <CardField name="phone" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:phone')!} />
                    <CardField name="statusCode" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:statusCode')!} hasTableRelation tableRelationEndpoint="/odata/datasecurity/status" tableRelationFilter={"?$filter=entityName eq 'Lead'"} tableRelationKey="code" tableRelationField="description" readonly />
                </CardGroup>
                <CardGroup name="principalQualification" label={t('partnerCenter:partnerLeadCard:groupPrimaryQualification')!} isOpen={true}>
                    <CardField name="full_Users_No" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:fullUsersNo')!} />
                    <CardField name="limited_User_No" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:limitedUsersNo')!} />
                    <CardField name="noOfCompany" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:noOfCompany')!} />
                    <CardField name="budget" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:budget')!} readonly />
                    <CardField name="registerRequest" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:registerRequest')!} readonly />
                </CardGroup>
                <CardGroup name="surveyQualification" label={t('partnerCenter:partnerLeadCard:groupSurveyQualification')!} isOpen={true}>
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="atecoCode" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:atecoCode')!} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="atecoDescription" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:atecoDescription')!} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="companyStartDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyStartDate")} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="companyRegistrationDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyRegistrationDate")} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="companyEndDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyEndDate")} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="balanceYear" type={SystemFieldType.Number} label={t("adminCenter:leadCard:balanceYear")} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="netWorth" type={SystemFieldType.Number} label={t("adminCenter:leadCard:netWorth")} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="shareCapital" type={SystemFieldType.Number} label={t("adminCenter:leadCard:shareCapital")} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="totalStaffCost" type={SystemFieldType.Number} label={t("adminCenter:leadCard:totalStaffCost")} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="totalAssets" type={SystemFieldType.Number} label={t("adminCenter:leadCard:totalAssets")} readonly />}
                    {distributorInfoContext.distributor.legalEntityCode === 'EOL' && <CardField name="avgGrossSalary" type={SystemFieldType.Number} label={t("adminCenter:leadCard:avgGrossSalary")} readonly />}
                    <CardField name="noOfEmployees" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:noOfEmployees')!} />
                    <CardField name="currentERPName" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:currentERPName')!} />
                    <CardField name="decisionDate" type={SystemFieldType.Date} label={t('partnerCenter:partnerLeadCard:fields:decisionDate')!} />
                    <CardField name="expectedLiveDate" type={SystemFieldType.Date} label={t('partnerCenter:partnerLeadCard:fields:expectedLiveDate')!} />
                    <CardField name="monthlyBudget" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:monthlyBudget')!} />
                    <CardField name="note" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:note')!} />
                    <CardField name="isAlreadyCustomer" type={SystemFieldType.Boolean} label={t('partnerCenter:partnerLeadCard:fields:isAlreadyCustomer')!} />
                    <CardField name="activeMicrosoftServices" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:activeMicrosoftServices')!} />
                </CardGroup>
                <CardGroup name="bookInternalMeeting" label={t('partnerCenter:partnerLeadCard:groupInternalMeeting')!} isOpen={true}>
                    <CardField name="internalMeetingDate" type={SystemFieldType.Date} label={t('partnerCenter:partnerLeadCard:fields:internalMeetingDate')!} readonly />
                </CardGroup>
                <CardGroup name="bookCustomerDemo" label={t('partnerCenter:partnerLeadCard:groupCustomerDemo')!} isOpen={true}>
                    <CardField name="demoDate" type={SystemFieldType.Date} label={t('partnerCenter:partnerLeadCard:fields:demoDate')!} readonly />
                </CardGroup>
                <CardGroup name="personalData" label={t('partnerCenter:partnerLeadCard:personalData')!} isOpen={true}>
                    <CardField name="address" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:address')!} />
                    <CardField name="zipCode" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:zipCode')!} />
                    <CardField name="city" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:city')!} />
                    <CardField name="county" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:county')!} />
                    <CardField name="country" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:country')!} hasTableRelation tableRelationEndpoint="/odata/countryregion" tableRelationKey="countryCode" tableRelationField="country" tableRelationFilter="?$filter=enabled eq true" />
                </CardGroup>
            </PageContainer>
            <Dialog hidden={!errorMessage} dialogContentProps={{
                type: DialogType.normal,
                title: t('distributorCenter:distributorPartnerCard:validationErrors')!,
            }}>
                {errorMessage}
                <DialogFooter>
                    <DefaultButton text={t('distributorCenter:distributorPartnerCard:close')!} onClick={() => {setErrorMessage(null);}} />
                </DialogFooter>
            </Dialog>
        </>
    );
});