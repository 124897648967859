import { forwardRef, useState } from "react";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { SystemFieldType, SystemPageType } from "../../../Model/SystemModels";
import { CardField } from "../../Common/Template/Card/CardField";
import { t } from "i18next";
import { Partner } from "../../../Model/PartnerModels";
import { DefaultButton, Dialog, DialogFooter, DialogType, MessageBar, MessageBarType } from "@fluentui/react";
import { Distributor } from "../../../Model/DistributorModel";

export interface IDistributorPartnerCard {
    distributor: Distributor;
}

export const DistributorPartnerCard = forwardRef<PageContainerBase, React.PropsWithChildren<IDistributorPartnerCard>>((props, ref) => {
    
    const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null);
    
    const checkRecord = (record: Partner): boolean => {
        setErrorMessage(null);
        let errors = checkPartnerData(record);
        if (errors.length > 0) {
            displayErrorMessage(errors);
            return false;
        }

        return true;
    }
    
    const checkPartnerData = (record: Partner): Object[] => {
        let validationErrors : object[] = [];
        
        if (!record.name)
            validationErrors.push({
                reference: "name",
                error: t('distributorCenter:distributorPartnerCard:fieldError:name')!,
            });
        if (!record.email)
            validationErrors.push({
                reference: "email",
                error: t('distributorCenter:distributorPartnerCard:fieldError:email')!,
            });
        if (props.distributor.legalEntityCode === "EOL" && !record.pecEmail)
            validationErrors.push({
                reference: "pecEmail",                
                error: t('distributorCenter:distributorPartnerCard:fieldError:pecEmail')!,
        });
        if (props.distributor.legalEntityCode === "EOL" && !record.vat)
            validationErrors.push({
                reference: "vat",                
                error: t('distributorCenter:distributorPartnerCard:fieldError:vat')!,
        });
        if (!record.phoneNo)
            validationErrors.push({
                reference: "phoneNo",                
                error: t('distributorCenter:distributorPartnerCard:fieldError:phoneNo')!,
        });
        if (!record.streetAddress)
            validationErrors.push({
                reference: "streetAddress",
                error: t('distributorCenter:distributorPartnerCard:fieldError:streetAddress')!,
            });
        if (!record.zipCode)
            validationErrors.push({
                reference: "zipCode",
                error: t('distributorCenter:distributorPartnerCard:fieldError:zipCode')!,
            });
        if (!record.city)
            validationErrors.push({
                reference: "city",
                error: t('distributorCenter:distributorPartnerCard:fieldError:city')!,
            });
        if (!record.firstName)
            validationErrors.push({
                reference: "firstName",
                error: t('distributorCenter:distributorPartnerCard:fieldError:firstName')!,
            });
        if (!record.lastName)
            validationErrors.push({
                reference: "lastName",
                error: t('distributorCenter:distributorPartnerCard:fieldError:lastName')!,
            });
        if (!record.county)
            validationErrors.push({
                reference: "county",
                error: t('distributorCenter:distributorPartnerCard:fieldError:county')!,
            });
        if (!record.country)
            validationErrors.push({
                reference: "country",
                error: t('distributorCenter:distributorPartnerCard:fieldError:country')!,
            });

        return validationErrors;
    }
    
    const displayErrorMessage = (errors: Object[]) => {
        setErrorMessage(
            <MessageBar messageBarType={MessageBarType.error}>
                <ul>
                    {errors.map((value: any, index: number) => (
                        <li style={{marginBottom: "3px"}} key={index}>{value.error}</li>
                    ))}
                </ul>
            </MessageBar>
        );
    };

    return (
        <>
        <PageContainer pageType={SystemPageType.Card} title={t('distributorCenter:distributorPartnerCard:title')!} endpoint="odata/partner" ref={ref} onCheckRecord={(record: Partner) => checkRecord(record)}>
            <CardGroup name="general" label={t('partnerCenter:partnerPartnerCard:groups:general')!} isOpen>
                <CardField name="name" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:name')!} />
                <CardField name="email" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:email')!} />
                <CardField name="firstName" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:firstName')!} />
                <CardField name="lastName" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:lastName')!} />
                <CardField name="legalRepresentativeFirstName" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:legalRepresentativeFirstName')!} />
                <CardField name="legalRepresentativeLastName" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:legalRepresentativeLastName')!} />
                <CardField name="phoneNo" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:phoneNo')!} />
                {props.distributor.legalEntityCode === "EOL" && <CardField name="pecEmail" type={SystemFieldType.String} label={t('distributorCenter:distributorPartnerCard:fields:pecEmail')!} />}
                {props.distributor.legalEntityCode === "EOL" && <CardField name="vat" type={SystemFieldType.String} label={t('distributorCenter:distributorPartnerCard:fields:vat')!} />}
            </CardGroup>
            <CardGroup name="personalData" label={t('partnerCenter:partnerPartnerCard:groups:personalData')!} isOpen>
                <CardField name="streetAddress" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:streetAddress')!} />
                <CardField name="city" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:city')!} />
                <CardField name="zipCode" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:zipCode')!} />
                <CardField name="county" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:county')!} />
                <CardField name="country" type={SystemFieldType.String} label={t('partnerCenter:partnerPartnerCard:fields:country')!} hasTableRelation tableRelationEndpoint="/odata/countryregion" tableRelationKey="countryCode" tableRelationField="country" tableRelationFilter="?$filter=enabled eq true" />
            </CardGroup>
        </PageContainer>
        <Dialog hidden={!errorMessage} dialogContentProps={{
            type: DialogType.normal,
            title: t('distributorCenter:distributorPartnerCard:validationErrors')!,
        }}>
            {errorMessage}
            <DialogFooter>
                <DefaultButton text={t('distributorCenter:distributorPartnerCard:close')!} onClick={() => {setErrorMessage(null);}} />
            </DialogFooter>
        </Dialog>
        </>
    );
});