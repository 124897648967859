import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import PageContainer from "../../../Common/Template/PageContainer";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";

export const DistributorCustomerSubscriptionList : React.FC = () => {
    const navigate = useNavigate();
    
    let listTitle = t('partnerCenter:partnerCustomerSubscriptionList:title')!;
    let partnerId = 0;
    let customerId = 0;
    let companyName = "";
     
    const buildEndpointList = () : string => {
        const partnerIdParam = HttpHelper.getParameter("partnerId");
        const customerIdParam = HttpHelper.getParameter("customerId");
        const companyNameParam = HttpHelper.getParameter("name");

        if (!partnerIdParam || !customerIdParam || !companyNameParam)
            navigate("/app/distributor/home");

        listTitle += companyNameParam;

        partnerId = partnerIdParam;
        customerId = customerIdParam;
        companyName = companyNameParam;

        return "/odata/subscription?$filter=partnerId eq "+partnerId+" and customerId eq "+customerId;
    }
    
    return (
        <>
            <PageContainer endpoint={buildEndpointList()} pageType={SystemPageType.List} title={listTitle} backAllowed>                            
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="openCustomerSubscriptionDetail" label={t('partnerCenter:partnerCustomerSubscriptionList:actions:openCustomerSubscriptionDetail')!} iconName="KnowledgeArticle" isPromoted runOnRec onClick={(req: any) => {
                                navigate("/app/distributor/administrative/customer/subscription/line?subscriptionId="+req.id+"&partnerId="+req.partnerId+"&customerId="+req.customerId+"&name="+companyName);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:documentNo')!} fieldName="documentNo" minWidth={250} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:subscriptionAmount')!} fieldName="subscriptionAmount" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:postingDate')!} fieldName="postingDate" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:status')!} fieldName="status" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:source')!} fieldName="source" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:recurringInterval')!} fieldName="recurringInterval" minWidth={130} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:currentPeriodStart')!} fieldName="currentPeriodStart" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:currentPeriodEnd')!} fieldName="currentPeriodEnd" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:cancellationDate')!} fieldName="cancellationDate" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:id')!} fieldName="id" minWidth={60} maxWidth={80} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}