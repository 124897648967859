import { CommandBar, FontIcon, Image, Stack, mergeStyles } from "@fluentui/react";
import { IActionbarProps } from "../../Partner/PartnerDashboard/PartnerDashboardComponents";
import { useContext } from "react";
import { DistributorInformationContext } from "../../../ApplicationCode/Distributor/DistributorInformationContext";
import facebookLogo from '../../../Assets/Images/facebook.png';
import instagramLogo from '../../../Assets/Images/instagram.png';
import twitterLogo from '../../../Assets/Images/twitter.png';
import linkedinLogo from '../../../Assets/Images/linkedin.png';
import youtubeLogo from '../../../Assets/Images/youtube.png';
import { t } from "i18next";

//#region DistributorDashboardContainer

export const DistributorDashboardContainer : React.FC<{}> = (props) => {

    const distributorDashboardContainerClassName = mergeStyles([{
        width: "98%",
        margin: '0 auto',
        marginTop: '10px',
        backgroundColor: 'rgb(255, 255, 255)',
        paddingLeft: '1em',
        paddingBottom: '1em',
        paddingRight: '1em',
        paddingTop: '1em',
        boxShadow: 'rgb(34 36 38 / 15%) 0px 1px 1px 1px',
        borderLeftWidth: '0px',
        borderBottomWidth: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '2px',
        borderleftStyle: 'solid',
        borderbottomStyle: 'solid',
        borderRightStyle: 'solid',
        borderTopStyle: 'solid',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: 'transparent'
    }]);

    return(
        <Stack className={distributorDashboardContainerClassName}>
            {props.children}
        </Stack>
    )
}

//#endregion

//#region DistributorActionBar

export const DistributorActionBar : React.FC<IActionbarProps> = (props: IActionbarProps) => {

    const commandBarStyle = {
        root: {
            marginBottom: 10,
            padding: 0,
            borderBottom: '1px solid rgb(224, 224, 224)',
        }
    };

    const dashboardItemActionStyle = {
        root: {
            fontSize: 16,
            color: 'rgb(0, 132, 137) !important',
            backgroundColor: 'rgb(255,255,255) !important'
        },
        rootHovered: {
            textDecoration:'underline',
        },
        icon:{
            color: 'rgb(0, 132, 137) !important'
        }
    };

    let itemList = props.items;
    for (let i = 0; i < itemList.length; i++)
        itemList[i].buttonStyles = dashboardItemActionStyle;

    return(
        <CommandBar
            items={itemList}
            overflowItems={[]}
            styles={commandBarStyle}
        />
    )
}

//#endregion

//#region DistributorCarouselItem

export interface IDistributorCarouselItemProps {
    title: string;
    description: string;
    iconName?: string;
    onClick?: () => void;
}

export const DistributorCarouselItem : React.FC<IDistributorCarouselItemProps> = (props: IDistributorCarouselItemProps) => {
    return (
        <Stack key={"message2"} style={{
            width: 'auto',
            display: 'flex',
            flexWrap:'wrap-reverse',
            flexDirection:'row',
            justifyContent: 'space-around',
            height: 'auto',
            paddingLeft: 60,
            paddingBottom:40,
            backgroundColor: 'rgb(217, 240, 242) !important',
            borderRadius:'10px',
            margin:'0 auto'
        }}>
            <Stack.Item>
                <Stack.Item style={{
                    fontSize: 32,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'stretch',
                    margin:'0 auto',
                    textAlign: 'left'
                }}>
                    {props.title}
                </Stack.Item>
                <Stack.Item style={{
                    fontSize: 18,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'stretch',
                    margin:'0 auto',
                    textAlign: 'left'
                }}>
                    <Stack verticalFill style={{
                        textAlign: 'center'
                    }}
                    onClick={props.onClick}>
                        {props.description}
                    </Stack>
                </Stack.Item>
            </Stack.Item>
            <Stack.Item style={{paddingTop:'35px',margin:'0 auto'}}>
                <FontIcon aria-label="icon" iconName={props.iconName} style={{fontSize:100,opacity:'30%'}} />
            </Stack.Item>
        </Stack>
    )
}

//#endregion

//#region DistributorSocialSlide

export const DistributorSocialSlide: React.FC = () => {
    const distributorInfoContext = useContext(DistributorInformationContext);
    
    const socialLogoImageClassName = mergeStyles([{
        cursor: 'pointer'
    }]);
    
    const buildSocialItem = (social: string): JSX.Element => {
        const legalEntityCode = distributorInfoContext.distributor.legalEntityCode;
        switch (social)
        {
            case "facebook":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={facebookLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.facebook.com/so.smart.business")} />
                    </Stack.Item>
            case "instagram":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={instagramLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.instagram.com/so_smart_biz/")} />
                    </Stack.Item>
            case "youtube":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={youtubeLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.youtube.com/channel/UCb5qwkbMINv02sPk4YGWQ-g")} />
                    </Stack.Item>
            case "twitter":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={twitterLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://twitter.com/so_smart_biz")} />
                    </Stack.Item>
            case "linkedin":
                if (legalEntityCode === 'ESS')
                    return <Stack.Item>
                        <Image src={linkedinLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.linkedin.com/showcase/so-smart-us/")} />
                    </Stack.Item>
                else
                    return <Stack.Item>
                        <Image src={linkedinLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.linkedin.com/showcase/so-smart-business/")} />
                    </Stack.Item>
            default:
                return <></>;
        }
    }
    
    return (
        <Stack
            key={"socialSlideContainer"}
            style={{
                width: '100%',
                display: 'flex',
                minHeight: '350px',
                padding: 40,
                backgroundColor: 'rgb(217, 240, 242)'
        }}>
            <Stack.Item
                style={{
                    fontSize: 32,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'stretch'
            }}>
                {t('customerCenter:DashboardContentContainer:CustomerSocialSlide:socialTitle')}
            </Stack.Item>
            <Stack.Item
                style={{
                    fontSize: 18,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'stretch'
            }}>
                <div>
                    {t('customerCenter:DashboardContentContainer:CustomerSocialSlide:socialDescription')}<br />
                    <Stack key="socialLogoContainer" horizontal tokens={{ childrenGap: 5 }} style={{ paddingTop: 20 }}>
                        {buildSocialItem("facebook")}
                        {buildSocialItem("instagram")}
                        {buildSocialItem("youtube")}
                        {buildSocialItem("twitter")}
                        {buildSocialItem("linkedin")}
                    </Stack>
                </div>
            </Stack.Item>
        </Stack>
    );
}

//#endregion