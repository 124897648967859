import React, { useRef } from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { ChatbotPartnerBusinessContract } from "../../../../Model/AdminModel";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";

export const AdminChatbotPartnerBusinessContractList : React.FC = () => {
    const adminService = new AdminService();

    const syncKnowledgeDocument = (chatbotPartnerBusinessContract: ChatbotPartnerBusinessContract) => {
        ToastService.showMessage(MessageType.Pending, "Sincronizzazione documenti per: " + chatbotPartnerBusinessContract.profileCode + " - " + chatbotPartnerBusinessContract.partnerBusinessContractCode, "CBSYNC");
        adminService.syncChatbotBusinessLineKnowledgeDocument(chatbotPartnerBusinessContract).then(() => {
            ToastService.update("CBSYNC", { type: "success", isLoading: false, render: "Sincronizzazione completata" });
        })
        .catch((err) => {
            ToastService.update("CBSYNC", { type: "error", isLoading: false, render: "Errore durante la sincronizzazione"});
        });
    }

    const clearKnowledgeDocuments = (chatbotPartnerBusinessContract: ChatbotPartnerBusinessContract) => {
        ToastService.showMessage(MessageType.Pending, "Cancellazione documenti per: " + chatbotPartnerBusinessContract.profileCode + " - " + chatbotPartnerBusinessContract.partnerBusinessContractCode, "CBSYNC");
        adminService.deleteChatbotBusinessLineKnowledgeDocuments(chatbotPartnerBusinessContract).then(() => {
            ToastService.update("CBDELETE", { type: "success", isLoading: false, render: "Cancellzione completata" });
        })
        .catch((err) => {
            ToastService.update("CBDELETE", { type: "error", isLoading: false, render: "Errore durante la cancellazione"});
        });
    }

    const buildEndpoint = (): string => {
        let url: string = "/odata/chatbot/partner/business/contract";
        const profileCode: string  = HttpHelper.getParameter('profileCode');
        if((profileCode != "") && (profileCode != null)){
            url += "?$filter=profileCode eq '" + profileCode + "'";
        }

        return url;
    }

    return (
        <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title="Chatbot partner business contract" backAllowed>
            <PageDefinitionContainer type={ContainerType.Action}>
                <ActionArea category={SystemActionCategory.Process}>
                    <ActionEntry name="syncKnowledgeDocument" isPromoted  label="Sincronizza documenti" iconName="Sync" onClick={(req:ChatbotPartnerBusinessContract)=>{
                        syncKnowledgeDocument(req);
                    }}>
                    </ActionEntry>
                    <ActionEntry name="clearKnowledgeDocuments" label="Cancella documenti" iconName="Delete" onClick={(req:ChatbotPartnerBusinessContract)=>{
                        clearKnowledgeDocuments(req);
                    }}>
                    </ActionEntry>
                </ActionArea>
            </PageDefinitionContainer>
            <PageDefinitionContainer type={ContainerType.PageStructure}>
                <ListHeaderEntry fieldName="profileCode" name="Codice profilo" minWidth={100} maxWidth={200} />
                <ListHeaderEntry fieldName="partnerBusinessContractCode" name="Partner business contract" minWidth={100} maxWidth={200} />
                <ListHeaderEntry fieldName="knowledgeDocumentEnableSync" name="Sincronizzazione documenti" minWidth={100} maxWidth={200} />
            </PageDefinitionContainer>
        </PageContainer>
    );
}