import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminApplicationSetupCard } from "../AdminApplicationSetupCard/AdminApplicationSetupCard";

export const AdminApplicationSetupList : React.FC = () => {
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage, setLoaderMessage] = useState("");
    const adminService = new AdminService();
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={"/odata/application/setup"} pageType={SystemPageType.List} title={"Application Setup"} backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry label="Chatbot" iconName="ChatBot" name="OpenChatbotSetup" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/chatbotSetup");
                            }}>
                            </ActionEntry>
                            <ActionEntry label="Contratti" iconName="ActivateOrders" name="OpenContractSetup" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/contractSetup");
                            }}></ActionEntry>
                            <ActionEntry label="Email" iconName="MailOptions" name="OpenEmailSetup" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/emailSetup");
                            }}></ActionEntry>
                            <ActionEntry iconName="WorkItem" name="OpenItemRequirements" label="Item requirements" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/itemRequirement");
                            }}></ActionEntry>
                            <ActionEntry iconName="ActivateOrders" name="OpenSalesHeader" label="Template Ordini" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/salesHeader");
                            }}></ActionEntry>
                            <ActionEntry name="openCountryRegion" label="Paesi/Aree geografiche" iconName="Globe" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/countryRegion");
                            }}></ActionEntry>    
                            <ActionEntry iconName="ActivateOrders" label="Partner business contract" name="openPartnerBusinessContract" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/partnerBusinessContract")
                            }}></ActionEntry>
                            <ActionEntry iconName="PartyLeader" name="OpenPartnerList" label="Partners" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/partnerList");
                            }}></ActionEntry>
                            <ActionEntry iconName="BacklogList" name="OpenApplicationLogEntry" label="Log" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/applicationLogEntry");
                            }}></ActionEntry>
                            <ActionEntry iconName="BulletedList2" name="OpenItemsList" label="Articoli" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/itemList");
                            }}></ActionEntry>
                            <ActionEntry iconName="BookAnswers" name="OpenQualifiedAnswer" label="Risposte qualifica" onClick={(req:any)=>{
                                navigate("/app/admin/related/qualifiedAnswer");
                            }}></ActionEntry>
                            <ActionEntry iconName="TeamsLogo" name="OpenNotificationList" label="Notifiche" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/notificationSetup");
                            }}></ActionEntry>
                            <ActionEntry iconName="EntitlementPolicy" name="ViewQualifiedAnswer" label="Risposte qualifica" onClick={(req:any)=>{
                                navigate("/app/admin/related/qualifiedAnswer");
                            }}></ActionEntry>
                            <ActionEntry iconName="AssessmentGroupTemplate" name="ViewWizard" label="Wizard" onClick={(req:any)=>{
                                navigate("/app/admin/ui/wizard/header");
                            }}></ActionEntry>
                            <ActionEntry iconName="OfflineStorage" name="OpenBusinessLineList" label="Business line" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/businessLine");
                            }}></ActionEntry>
                            <ActionEntry name="openChatbotProfile" label="Profili chatbot" iconName="Chatbot" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/chatbotProfiles");
                            }}></ActionEntry>
                            <ActionEntry name="openChatbotTicketInput" label="Chatbot Ticket" iconName="BulletedList" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/chatbotTicketInput");
                            }}></ActionEntry>
                            <ActionEntry name="openDashboardlink" label="Link dashboard" iconName="Link" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/dashboardLink");
                            }}></ActionEntry> 
                            <ActionEntry name="opneAssetsList" label="Assets" iconName="AssetLibrary" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/assets");
                            }}></ActionEntry>
                            <ActionEntry name="openStripeCoupon" label="Stripe Coupon" iconName="Coupon" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/stripeCouponList");
                            }}></ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="GetCustomersOpenAPIData" label="Download customers Open API advanced data" iconName="CloudDownload" onClick={()=>{
                                ToastService.showPromiseMessage(adminService.getCustomersOpenAPIData(), "Download in progress", "Download completed", "Error during download");
                            }}></ActionEntry>
                            <ActionEntry name="DownloadStripeItems" label="Download Stripe items" iconName="DownloadDocument" onClick={()=>{
                                ToastService.showPromiseMessage(adminService.downloadStripeItems(), "Download in progress", "Download completed", "Error during download");
                            }}></ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Id" fieldName="primaryKey" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Codice Gruppo STEPPER" fieldName="stepperGroupCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Giorni Limite Application Log Entry" fieldName="dayApplicationLogLimit" minWidth={100} maxWidth={150} />
                        <ListHeaderEntry name="Giorni Limite Http Log Entry" fieldName="dayHttpLogLimit" minWidth={100} maxWidth={150} />
                        <ListHeaderEntry name="Codice origine Partner Lead default" fieldName="defaultPartnerLeadOriginCode" minWidth={100} maxWidth={150} />
                        <ListHeaderEntry name="Controllo P.IVA abilitato" fieldName="vatControlEnabled" minWidth={100} maxWidth={150} />
                        <ListHeaderEntry name="Tipo setup Stripe" fieldName="stripeNotificationSetupType" minWidth={150} maxWidth={250} onRender={(item: any, index, column)=>{
                            if (item.type === 0)
                                return "Email"
                            else if (item.type === 1)
                                return "Chat"
                        }} />
                        <ListHeaderEntry name="Codice setup Stripe" fieldName="stripeNotificationSetupCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Codice template Stripe" fieldName="stripeNotificationTemplateCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Codice gruppo Stripe" fieldName="stripeNotificationGroupCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Abilita notifiche Stripe fattura insoluta" fieldName="stripeNotificationInvoicePaymentFailedEnabled" minWidth={150} maxWidth={300} />
                        <ListHeaderEntry name="Abilita notifiche Stripe fattura pagata" fieldName="stripeNotificationInvoicePaidEnabled" minWidth={150} maxWidth={300} />
                        <ListHeaderEntry name="Abilita notifiche Stripe sottoscrizione creata" fieldName="stripeNotificationCustomerSubscriptionCreatedEnabled" minWidth={150} maxWidth={300} />
                        <ListHeaderEntry name="Abilita notifiche Stripe sottoscrizione aggiornata" fieldName="stripeNotificationCustomerSubscriptionUpdatedEnabled" minWidth={150} maxWidth={300} />
                        <ListHeaderEntry name="Abilita notifiche Stripe sottoscrizione cancellata" fieldName="stripeNotificationCustomerSubscriptionDeletedEnabled" minWidth={150} maxWidth={300} />
                        <ListHeaderEntry name="Abilita notifiche Stripe RMR ledger entry" fieldName="stripeNotificationCustomerRMRLedgerEntryEnabled" minWidth={150} maxWidth={300} />
                        <ListHeaderEntry name="Id articolo AbleTech" fieldName="customerAbleTechItemId" minWidth={100} maxWidth={150} />
                        <ListHeaderEntry name="Codice modulo AbleTech" fieldName="customerAbleTechModuleCode" minWidth={100} maxWidth={150} />
                        <ListHeaderEntry name="Codice modulo base" fieldName="customerBaseModuleCode" minWidth={100} maxWidth={150} />
                        <ListHeaderEntry name="Dati Open API in automatico" fieldName="getOpenApiDataAutomatically" minWidth={100} maxWidth={150} />
                    </PageDefinitionContainer>
                </PageContainer>
            <AdminApplicationSetupCard ref={cardRef} />
        </>
    );
}