import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader"
import { t } from "i18next";
import { DistributorService } from "../../../ApplicationCode/Distributor/DistributorService";
import { Distributor } from "../../../Model/DistributorModel";
import { DistributorInformationContextProvider } from "../../../ApplicationCode/Distributor/DistributorInformationContext";

export const DistributorApplicationContainer : React.FC = () => {
    const distributorService = new DistributorService();

    const sessionStateContext = useContext<ISessionStateContext>(SessionStateContext);

    const [dataStatus, setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [dataStatusText, setDataStatusText] = useState<string>(t('distributorCenter:distributorApplicationContainer:loadingConfiguration')!);
    const [distributor, setDistributor] = useState<Distributor>(new Distributor());
    // const outletContext:ApplicationOutletContext = useOutlet();

    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad)
        {
            setDataStatus(SystemDataLoadingStatus.Loading);

            distributorService.getDistributor(sessionStateContext.currentUser.distributorCode)
            .then((resp) => {
                // outletContext.trySetDocsUrl(buildDocumentationPortalUrl())
                setDistributor(resp);
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setDataStatusText("");
            })
            .catch((err) => {
                console.error(err);
                setDataStatus(SystemDataLoadingStatus.Error);
            });
        }
    }, [dataStatus])
    
    // //TODO: TO DISMISS
    // const buildDocumentationPortalUrl = (): string => {
    //     if(window.location.origin.indexOf('.it') >= 0){
    //         return('/app/customer/docs/it-IT');
    //     }
    //     else if (window.location.origin.indexOf('.us') >= 0){
    //         return('/app/customer/docs/en-US');
    //     }
    //     else if (window.location.origin.indexOf('localhost') >= 0){
    //         return('/app/customer/docs/' + process.env.REACT_APP_SYSTEM_BACKEND_DEFAULT_LOCALIZATION);
    //     }
    //     else{
    //         return('/docs');
    //     };
    // }

    if (!SystemCore.isFinishedDataStatus(dataStatus))
    {
        return (
            <Loader text={dataStatusText}></Loader>
        );
    }
    else
    {
        if (dataStatus === SystemDataLoadingStatus.Error)
            return <>Error</>;
        
        return (
            <DistributorInformationContextProvider distributor={distributor}>
                <Outlet></Outlet>
            </DistributorInformationContextProvider>
        );
    }
}
