import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCountryRegionCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Paese/Area geografica"} endpoint="odata/CountryRegion" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>                
                <CardField label="Cod. paese" name="countryCode" type={SystemFieldType.String} />
                <CardField label="Nome" name="country" type={SystemFieldType.String} />
                <CardField label="Cod. iso" name="isoCode" type={SystemFieldType.String} />
                <CardField label="Cod. abletech" name="abletechCode" type={SystemFieldType.String} />
                <CardField label="Cod. zona" name="zoneCode" type={SystemFieldType.String} />
                <CardField label="Abilitato" name="enabled" type={SystemFieldType.Boolean} />
            </CardGroup>
            <CardGroup name="integration" label="Integrazione" isOpen>
                <CardField label="CRM Id" name="crmReferenceId" type={SystemFieldType.String} />
            </CardGroup>
        </PageContainer>
    );
});