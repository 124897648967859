import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { CommonUploadFileDialog } from "../../../Common/CommonUploadFileDialog/CommonUploadFileDialog";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCountryRegionCard } from "../AdminCountryRegionCard/AdminCountryRegionCard";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField, Text } from "@fluentui/react";

export const AdminCountryRegionList : React.FC = () => {
    const [fileDialogHidden, setFileDialogHidden] = useState<boolean>(true);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const cardRef = useRef<PageContainerBase>(null);
    const dialogErrorRef = useRef<string>("");

    const adminService = new AdminService();
    const navigate = useNavigate();

    const uploadFile = (file: File) => {
        let currformData:FormData = new FormData();
        currformData.append('file', file, file.name);
        
        ToastService.showPromiseMessage(
            adminService.uploadPostalCodeFile(currformData).then(() => {
                setSaveInProgress(false);
                setFileDialogHidden(true);
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err.message)
                setSaveInProgress(false);
                setFileDialogHidden(true);
            }),
            "Caricamento in corso...",
            "Caricamento completato!",
            ""
        );
    }
    
    return (
        <>
            <PageContainer endpoint="/odata/CountryRegion" pageType={SystemPageType.List} title="Paesi/Aree geografiche" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="openCountryRegion" label="Codici postali" iconName="Mail" runOnRec isPromoted onClick={(req:any)=>{
                            navigate(`/app/admin/related/postalCode?countryCode=${req.countryCode}`);
                        }}></ActionEntry>                                
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="uploadPostalCode" label="Carica codici postali" iconName="Upload" onClick={(req:any)=>{
                            setFileDialogHidden(false);
                        }}></ActionEntry>                                
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Cod. paese" fieldName="countryCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome" fieldName="country" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Cod. iso" fieldName="isoCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Cod. abletech" fieldName="abletechCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Cod. zona" fieldName="zoneCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="CRM Id" fieldName="crmReferenceId" minWidth={250} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCountryRegionCard ref={cardRef} />
            <CommonUploadFileDialog
                ext=".json"
                hidden={!fileDialogHidden}
                onDismiss={() => setFileDialogHidden(true)}
                onSuccess={(file) => {
                    setSaveInProgress(true);
                    uploadFile(file);
                }}
                saveInProgress={saveInProgress}
                subText="Seleziona il file json per importare i codici postali"
                title="Carica/Aggiorna codici postali"
                txtLabel="Carica un file"
            />
        </>
    );
}