import React, { useRef, useState } from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { useNavigate } from "react-router-dom";
import { AdminChatbotProfileCard } from "../AdminChatbotProfileCard/AdminChatbotProfileCard";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { AssignedChatbotPartnerBusinessContract, ChatbotPartnerBusinessContract, ChatbotProfile } from "../../../../Model/AdminModel";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { AdminChatbotPartnerBusinessContractLinkDialog } from "../AdminChatbotPartnerBusinessContractLinkDialog/AdminChatbotPartnerBusinessContractLinkDialog";

export const AdminChatbotProfileList : React.FC = () => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [profileCode, setProfileCode] = useState<string>("");
    const navigate = useNavigate();

    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();

    const onSaveChatbotPartnerBusinessContract = (modifiedPartnerBusinessContracts: AssignedChatbotPartnerBusinessContract[]) => {
        adminService.modifyAssignedPartnerBusinessCOntracts(modifiedPartnerBusinessContracts)
            .then(() => {
                setShowDialog(false);
            });
    }

    const syncKnowledgeDocument = (chatbotProfile: ChatbotProfile) => {
        ToastService.showMessage(MessageType.Pending, "Sincronizzazione documenti per: " + chatbotProfile.code, "CBPSYNC");
        adminService.syncChatbotProfilesKnowledgeDocument(chatbotProfile).then(() => {
            ToastService.update("CBPSYNC", { type: "success", isLoading: false, render: "Sincronizzazione completata" });
        })
        .catch((err) => {
            ToastService.update("CBPSYNC", { type: "error", isLoading: false, render: "Errore durante la sincronizzazione"});
        });
    }

    return (
        <>
            <PageContainer endpoint="/odata/chatbot/profile" pageType={SystemPageType.List} title="Profili chatbot" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="openChatbotPartnerBusinessContract" isPromoted  label="Associa partner business contract" iconName="Link" onClick={(req:any)=>{
                            setProfileCode(req.code);
                            setShowDialog(true);
                        }}>
                        </ActionEntry>
                        <ActionEntry name="syncKnowledgeDocumentProfiles" label="Sincronizza documenti per tutte le business line associate" iconName="Sync" onClick={(req:ChatbotProfile)=>{
                            syncKnowledgeDocument(req);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="cbPartnerBusinessContract" isPromoted  label="Chatbot partner business contract" iconName="LineThickness" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/chatbotPartnerBusinessContract?profileCode=" + req.code);
                        }}>
                        </ActionEntry>
                        <ActionEntry name="translations" isPromoted  label="Traduzioni" iconName="Translate" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/translations?sourceId=" + req.systemId);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="code" name="Codice" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="description" name="Descrizione" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="origin" name="Origine" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="defaultContext" name="Default context" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminChatbotProfileCard ref={cardRef} />
            <AdminChatbotPartnerBusinessContractLinkDialog 
                showDialog={showDialog}
                profileCode={profileCode}
                onSuccessModal={(result)=>{
                    onSaveChatbotPartnerBusinessContract(result);
                }}
                onCancelModal={() => {
                    setShowDialog(false);
                }}
            />
        </>
    );
}