import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { PartnerInformationContextProvider } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Partner } from "../../../Model/PartnerModels";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader"
import { ApplicationOutletContext, useOutlet } from "../../Common/ApplicationContainer/ApplicationContainer";
import { t } from "i18next";

export const PartnerApplicationContainer : React.FC = () => {
    const [dataStatusText, setDataStatusText] = useState("");
    const [partner, setPartner] = useState(new Partner());
    const [dataStatus, SetDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    let partnerService = new PartnerService();

    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            SetDataStatus(SystemDataLoadingStatus.Loading);
            setDataStatusText(t('distributorCenter:distributorApplicationContainer:loadingConfiguration')!);

            partnerService.loadPartner(sessionStateContext.currentUser.referenceId).then((resp) => {
                setPartner(resp);
                SetDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((resp) => {
                SetDataStatus(SystemDataLoadingStatus.Error);
            });
        }
    }, [dataStatus])

    if (! SystemCore.isFinishedDataStatus(dataStatus))
    {
        return (
            <Loader text={dataStatusText}></Loader>
        );
    }
    else
    {
        if (dataStatus == SystemDataLoadingStatus.Error)
            return <>Error</>;
        
        return (
            <PartnerInformationContextProvider 
                partner={partner}>
                <Outlet></Outlet>
            </PartnerInformationContextProvider>
        );
    }
}
