import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminBusinessLineCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title="Scheda business line" endpoint="odata/business/line" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="code" label="Codice" type={SystemFieldType.String} />
                <CardField name="description" label="Descrizione" type={SystemFieldType.String} />
                <CardField name="blobConnectionString" label="Blob connection string" type={SystemFieldType.String} />
                <CardField name="containerName" label="Nome container" type={SystemFieldType.String} />
                <CardField name="videoSourceContainerName" label="Nome container sorgente video" type={SystemFieldType.String} />
                <CardField name="videoDestinationContainerName" label="Nome container destinazione video" type={SystemFieldType.String} />
                <CardField name="indexName" label="Nome indice" type={SystemFieldType.String} />
                <CardField name="defaultPartnerBusinessContractCode" label="Default partner business contract" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/partner/business/contract" tableRelationKey="code" tableRelationField="description" />
            </CardGroup>
            <CardGroup name="azure" label="Azure" isOpen={true}>
                <CardField name="extensionType" label="Tipo estensione" type={SystemFieldType.String} />
                <CardField name="azureCognitiveSearchEndpoint" label="Endpoint Azure cognitive service" type={SystemFieldType.String} />
                <CardField name="azureCognitiveSearchKey" label="Chiave Azure cognitive service" type={SystemFieldType.String} />
                <CardField name="roleInformation" label="Informazioni ruolo" type={SystemFieldType.String} />
                <CardField name="minRelevanceScore" label="Punteggio minimo (documenti)" type={SystemFieldType.Number} />
                <CardField name="documentCountLimit" label="Limite documenti" type={SystemFieldType.Number} />
            </CardGroup>
            <CardGroup name="docs" label="Docs" isOpen={true}>
                <CardField name="docsConnectionString" label="Blob storage connection string" type={SystemFieldType.String} />
                <CardField name="docsContainerName" label="Nome contenitore file" type={SystemFieldType.String} />
                <CardField name="docsIndexFileName" label="Nome file indice" type={SystemFieldType.String} />
                <CardField name="docsIndexMdFileName" label="Nome file indice (markdown)" type={SystemFieldType.String} />
                <CardField name="docsLastSyncDateTime" label="Ultima data ora sincronizzazione" type={SystemFieldType.Date} />
            </CardGroup>
            <CardGroup name="assets" label="Assets" isOpen={true}>
                <CardField name="assetsContainerName" label="Nome container blob" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="notification" label="Notifiche" isOpen={false}>
                <CardField name="notificationSetupType" type={SystemFieldType.Option} label="Tipo setup" cmbOptions={[
                    {key: 0, text: "Email"},
                    {key: 1, text: "Chat"}
                ]} />
                <CardField name="notificationSetupCode" type={SystemFieldType.String} label="Codice setup"></CardField>
                <CardField name="notificationTemplateCode" type={SystemFieldType.String} label="Codice template"></CardField>
                <CardField name="notificationGroupCode" type={SystemFieldType.String} label="Codice gruppo"></CardField>
            </CardGroup>
        </PageContainer>
    );
});