import React, { useEffect, useRef, useState } from "react";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { SystemActionCategory,SystemPageType, SystemOperation, SystemDataLoadingStatus, SystemUser } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCreateUserCard } from "../AdminCreateUserCard/AdminCreateUserCard";
import { useNavigate } from "react-router-dom";
import { AdminUserPermissionPanel } from "../AdminUserPermissionPanel/AdminUserPermissionPanel";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { Loader } from "../../../Common/Loader/Loader";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { Partner } from "../../../../Model/PartnerModels";

export const AdminUserGeneralList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage,setLoaderMessage] = useState("");
    const [username,setUsername] = useState("");
    const [partners,setPartners] = useState<Partner[]>([] as Partner[]);
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    const panelRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const customerId = useRef<number>(0);
    const companyName = useRef<string>("");
    let listTitle= "Lista utenti";

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            setLoaderMessage("Caricamento in corso...");
            setDataStatus(SystemDataLoadingStatus.Loaded);

            const customerIdParam = HttpHelper.getParameter('customerId');
            if((customerIdParam !== null) && (customerIdParam !== ""))
                customerId.current = customerIdParam;
            
            const companyNameParam = HttpHelper.getParameter('name');
            if((companyNameParam !== null) && (companyNameParam !== ""))
                companyName.current = companyNameParam;

            adminService.getPartners().then((resp)=>{
                setPartners(resp);
            })
        }
    },[dataStatus])
    
    const buildEndpointList = () : string => {
        let endpoint = "odata/user";

        if((customerId.current !== null) && (customerId.current > 0)){
            endpoint += "?$filter=customerId eq " + customerId.current;
            if((companyName.current !== null) && (companyName.current !== ""))
            listTitle ="Lista utenti di " + companyName.current;
        }
        return endpoint;
    }

    const resetPrivateUserPassword = (user: SystemUser) => {
        adminService.resetPrivateUserPassword(user).then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Reset password effettuato con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const synccronizeAzureAadGuest = ()=>{
        adminService.syncronizeAzureAadGuest().then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Utenti sincronizzati con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const inviteUserInAzureAD = (userId: number)=>{
        adminService.inviteUserInAzureAD(userId).then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Utente invitato con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }
    
    const handleCleanAzureAADGuest = () => {
        if (customerId.current === 0)
            ToastService.showMessage(MessageType.Error, "Devi aver selezionato gli utenti di un cliente");
        else {
            var promise = adminService.cleanAzureAADGuest(customerId.current);
            ToastService.showPromiseMessage(promise, "Pulizia utenti guest in corso", "Pulizia utenti guest completata con successo", "Errore durante la puizia utenti guest");
        }
    }

    if(!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>
    else
        return (
            <>
                <PageContainer endpoint={buildEndpointList()} pageType={SystemPageType.List} title={listTitle} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="giveUserPermission" label="Assegna permesso" iconName="Assign" runOnRec onClick={(req:any)=>{
                                    setUsername(req.lastName+" "+req.firstName)
                                    panelRef.current?.openPage(SystemOperation.Insert);
                                    panelRef.current?.setRecord({
                                        userId: req.id
                                    });
                                }}>
                            </ActionEntry>
                            <ActionEntry name="ResetPrivateUserPassword" label="Resetta password utente privato" iconName="PasswordField" runOnRec onClick={(req:any) => {
                                setLoaderMessage("Reset password in corso. Attendere...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                resetPrivateUserPassword(req);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="SyncAzureAADGuest" label="Sincronizza utenti guest" iconName="SchoolDataSyncLogo" onClick={(req:any)=>{
                                    setLoaderMessage("Sincronizzazione account guest di Azure in corso. Attendere...");
                                    setDataStatus(SystemDataLoadingStatus.Loading);
                                    synccronizeAzureAadGuest();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="CleanAzureAADGuest" label="Pulisci utenti guest" iconName="ClearFormattingEraser" onClick={()=>{
                                handleCleanAzureAADGuest();
                            }}></ActionEntry>
                            <ActionEntry name="InviteUserInAzureAD" label="Invita utente guest" iconName="ChatInviteFriend" onClick={(req:any)=>{
                                setLoaderMessage("Invito utente in corso. Attendere...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                inviteUserInAzureAD(req.id);
                            }}></ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="OpenUserPermission" label="Permessi utente" iconName="GroupedList" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/userPermission?userId='+req.id);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenFrontEndLogEntry" label="FrontEnd Log Entry" iconName="GroupedList" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/demoLog?Id='+req.id);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenUserApplicationTaskList" label="Lista Task Utente" iconName="GroupedList" runOnRec onClick={(item:any)=>{
                                    navigate('/app/admin/related/user/applicationTask?userId='+item.id);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenUserSettings" label="Impostazioni utente" iconName="Settings" runOnRec onClick={(req:any)=>{
                                    navigate("/app/admin/related/userSettings?userId="+req.id);
                                }}>
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Nome" fieldName="firstName" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Cognome" fieldName="lastName" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Nome Visualizzato" fieldName="displayName" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Email Privata" fieldName="privateEmail" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="User Principle Name" fieldName="userPrincipleName" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Partner" fieldName="partnerId" minWidth={150} maxWidth={250} onRender={(item:any,index,column)=>{
                            return partners.find(partner => partner.id === item.partnerId)?.name;
                        }} />
                        <ListHeaderEntry name="Usage Location" fieldName="usageLocation" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id" fieldName="id" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id Azure" fieldName="azureId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id Azure Guest" fieldName="guestAzureId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Phone" fieldName="phone" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Active" fieldName="active" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Amministratore So Smart" fieldName="mainUser" minWidth={170} maxWidth={250} />
                        <ListHeaderEntry name="Cliente" fieldName="customerId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Lead" fieldName="leadId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Distributore" fieldName="distributorId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Tipo Utente" fieldName="userType" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                            if (item.userType === 0)
                                return "AAD"
                            else if (item.userType === 1)
                                return "Private"
                        }} />
                        <ListHeaderEntry name="Tipo relazione utente" fieldName="userRelationshipType" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                            if (item.userRelationshipType === 0)
                                return "Customer"
                            else if (item.userRelationshipType === 1)
                                return "Internal"
                            else if (item.userRelationshipType === 2)
                                return "Partner"
                            else if (item.userRelationshipType === 3)
                                return "Distributor"
                        }} />
                        <ListHeaderEntry name="Stato" fieldName="status" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id Contatto CRM" fieldName="crmContactId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id Contatto Partner CRM" fieldName="crmPartnerContactId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id utente CRM" fieldName="crmSystemUserId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id staff member Booking" fieldName="bookingStaffMemberId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCreateUserCard ref={cardRef} />
                <AdminUserPermissionPanel Username={username} ref={panelRef} />
            </>
        );
}