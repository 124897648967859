import { CommandBar, CommandBarButton, IButtonProps, IButtonStyles, ICommandBarItemProps, ICommandBarStyles, IContextualMenuItem, IContextualMenuItemStyleProps, IContextualMenuItemStyles, IContextualMenuProps, IContextualMenuStyleProps, IContextualMenuStyles, IIconStyleProps, IIconStyles, IStyleFunctionOrObject, Image, ImageFit, Layer, Stack, mergeStyles } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MenuEntry, SystemDataLoadingStatus, SystemLanguage } from "../../../Model/SystemModels";
import { FeatureSearchBox } from "../FeatureSearchBox/FeatureSearchBox";
import i18next, { t } from "i18next";
import './ApplicationNavBar.css';
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import copilotIcon from "../../../Assets/Images/copilot.png";
import { useMediaQuery } from 'react-responsive';

const overflowProps: IButtonProps = { 
    ariaLabel: 'More commands',
    style: {
            color: 'white'
    },
    styles: {
        root: {
            backgroundColor: 'black',
            border: '1px solid white',
            borderRadius: '10px',
        },
        icon: {
            color: 'white'
        },
        menuIcon: {
            color: 'white'
        }
    },
    menuIconProps: {
        iconName: 'GlobalNavButton'
    },

};

const _styles: ICommandBarStyles = {
    root: {
        backgroundColor: 'inherit'
    }
};

const _iconButtonStyle: IButtonStyles = {
    root:{
        backgroundColor: "black",
        borderRadius: "0",
        color: "white"
    },
    rootHovered:{
        backgroundColor: "black",
        color: "#008089 !important"
    },
    rootPressed:{
        backgroundColor: "black",
        color: "#015D63 !important"
    },
    icon:{
        backgroundColor: "black",
        color: "white"
    },
    iconHovered:{
        backgroundColor: "black",
        color: "#008089 !important"
    },
    iconPressed:{
        backgroundColor: "black",
        color: "#015D63 !important"
    },
    rootExpanded:{
        backgroundColor: "black",
        color: "white"
    },
    rootExpandedHovered: {
        backgroundColor: "black",
        color: "#015D63 !important"
    },
    menuIcon: {
        backgroundColor: "black",
        color: "white",
    },
    menuIconHovered: {
        backgroundColor: "black",
        color: "#008089",
    },
    menuIconPressed: {
        backgroundColor: "black",
        color: "#015D63",
    },
    menuIconExpanded:{
        backgroundColor: "black",
        color: "white !important"
    },
    menuIconExpandedHovered:{
        backgroundColor: "black",
        color: "#008089 !important"
    }
};

const _subMenuStyles: IStyleFunctionOrObject<IContextualMenuItemStyleProps, IContextualMenuItemStyles> = {
    root: {
        color: "black",
        ':hover': {
            color: "#008089 !important"
        },
        ':active': {
            color: "#015D63 !important"
        }
    },
    icon: {
        color: "black !important"
    }
}

const contentStyle = mergeStyles([{
    backgroundColor: "black",
    borderBottom: "1px solid black",
    color: "white",
    lineHeight: "50px",
    fontSize: "18px",
    display: "inline-block",
    width: "100%",
    '@media screen and (max-width: 910px)': {
        fontSize: '14px',
    }
}])

const imageContainerStyle = mergeStyles([{
    float: 'left', 
    paddingLeft: '50px', 
    paddingRight: 8,
    cursor: 'pointer',
    '@media screen and (max-width: 910px)': {
        paddingLeft: '20px',
    }
}]);

export interface IApplicationNavBarProps {
    onOpenGuidePanel?: () => void;
    onHandleContainerStyle?: (isOpen: boolean) => void;
}


export const ApplicationNavBar : React.FC<IApplicationNavBarProps> = (props) => {
    const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
    const [dataStatus, setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [languageMenuProps, setLanguageMenuProps] = useState<IContextualMenuProps>({items: []});
    const [commandBarItems, setCommandBarItems] = useState<ICommandBarItemProps[]>([]);

    const sessionStateContext = useContext(SessionStateContext);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isMobilePortrait = useMediaQuery({ maxWidth: 910 });
    const navigate = useNavigate();

    const systemCore = new SystemCore();

    function generateCommandBarItemStyle(isSubMenu: boolean): Partial<IButtonStyles | IStyleFunctionOrObject<IContextualMenuStyleProps, IContextualMenuStyles> | IStyleFunctionOrObject<IIconStyleProps, IIconStyles>> {
        if(isSubMenu){
            return _subMenuStyles;
        }
        else{
            return _iconButtonStyle;
        }
    }

    const CustomButton: React.FC<IButtonProps> = (props) => {
        return <CommandBarButton {...props} onClick={props.onClick} styles={generateCommandBarItemStyle(false)} />;
    };

    const getItems = () => {
        let items: ICommandBarItemProps[] = [
            {
              key: 'Language',
              text: i18next.resolvedLanguage.toUpperCase(),
              ariaLabel: 'Language',
              subMenuProps: languageMenuProps,
              disabled: dataStatus !== SystemDataLoadingStatus.Loaded
            },
            {
              key: 'Logout',
              text: t('common:ApllicationNavBar:Text000002Action')!,
              ariaLabel: 'Logout',
              iconOnly: true,
              ariaDescribedBy: 'true',
              iconProps: { iconName: 'PowerButton' },
              onClick: () => systemCore.logOutUser(sessionStateContext, navigate),
            }
        ];

        if (enableIcon("Chatbot"))
            items.unshift(
                {
                    key: 'Chatbot',
                    text: 'Chatbot',
                    ariaLabel: 'Chatbot',
                    iconOnly: true,
                    tooltipHostProps: {
                        styles: {
                            root: {
                                display: 'flex',
                                alignItems: 'center',
                            }
                        }
                    },
                    iconProps: {
                        styles: {
                            root: {
                                height: 25,
                                width: 25,
                            }
                        },
                        imageProps: {
                            src: copilotIcon,
                            alt: 'Chatbot',
                            height: 25,
                            width: 25,
                            imageFit: ImageFit.centerContain
                        }
                    },
                    onClick: () => {
                        props.onOpenGuidePanel!();
                    }
                }
            );

        if (enableIcon("Guide")){
            items.unshift(
                {
                    key: 'Guide',
                    text: t('common:ApllicationNavBar:Text000003Action')!,
                    ariaLabel: 'Help',
                    iconOnly: true,
                    ariaDescribedBy: 'true',
                    iconProps: { iconName: 'Help' },
                    onClick: () => {
                        let culture: string = sessionStateContext.currentApp.culture;
                        if((culture === '') || (culture === null))
                            culture = process.env.REACT_APP_SYSTEM_BACKEND_DEFAULT_LOCALIZATION!;

                        const docsUrl: string = '/app/customer/docs/' + culture + "/index";
                        navigate(docsUrl);
                    }
                }
            )
        }

        if (enableIcon("Search"))
            items.unshift(
                {
                    key: 'Search',
                    text: t('common:ApllicationNavBar:Text000004Action')!,
                    ariaLabel: 'Search',
                    iconOnly: true,
                    ariaDescribedBy: 'true',
                    iconProps: { iconName: 'Search' },
                    onClick: () => {
                        setShowSearchBox(true);
                    }
                }
            )

        setCommandBarItems(items);
    };

    const handlePageSelection = (selected: MenuEntry) => {
        setShowSearchBox(false);
        navigate(selected.endpoint);
    }

    const changeLanguage = (language: string) => {
        i18next.changeLanguage(language);
        
        if(sessionStateContext.currentUserSettings.userLanguageCode !== systemCore.formatLanguage(i18next.language))
        systemCore.setUserLanguageCode(sessionStateContext.currentUserSettings).then(()=>{
            sessionStateContext.currentUserSettings.userLanguageCode = systemCore.formatLanguage(i18next.language);
            window.location.reload();
        })
        .catch((jqXHR)=>{
            window.location.reload()
        });
    }

    const enableIcon = (key: string) : boolean => {

        switch(key){
            case "Search":
                if(window.location.pathname.indexOf("demo") > 0 || 
                   window.location.pathname.indexOf("docs") > 0) 
                   return false;
                   
                return true;
            case "Guide":
                if(!sessionStateContext.currentApp.helpPageEnable || 
                    window.location.pathname.indexOf("docs") > 0)
                    return false;

                return true;
            case "Chatbot":
                if(!sessionStateContext.currentApp.showChatBot || isMobilePortrait)
                    return false;

                return true;
            default: return true;
        }
    }

    const getLanguages = () => {
        setDataStatus(SystemDataLoadingStatus.Loading);

        let availableLanguages:IContextualMenuItem[] = [];
        CommonFunctions.getEnabledLanguage().then((resp)=>{
            
            resp.forEach((language:SystemLanguage)=>{
                availableLanguages.push(
                    {
                      key: language.code.toUpperCase(),
                      text: language.name,
                      iconProps: {iconName: 'Globe' },
                      itemProps: { styles: generateCommandBarItemStyle(true) },
                      disabled: i18next.resolvedLanguage.toUpperCase() === language.code.toUpperCase(),
                      onClick: () =>{changeLanguage(language.code.toLowerCase());}
                    }
                );
            })

            setLanguageMenuProps({items: availableLanguages});
            setDataStatus(SystemDataLoadingStatus.Loaded);
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error,t('common:ApllicationNavBar:Text000001Err'));
            setDataStatus(SystemDataLoadingStatus.Error);
        })
    }

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            getLanguages();
        }
    },[dataStatus]);

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.Loaded)
            getItems();
    },[isMobile, isMobilePortrait, dataStatus]);

    return (
        <Layer>
            <Stack className={contentStyle} id="applicationNavbar">
                <Stack.Item 
                    className={imageContainerStyle}
                    onClick={() => {
                        props.onHandleContainerStyle!(false);
                        navigate(sessionStateContext.currentApp.homepageUrl);
                    }}
                >
                    <Image src={"data:image/png;base64," + sessionStateContext.currentApp.appIcon} className="brand-image" imageFit={ImageFit.centerContain} style={{padding: 5, paddingLeft: 0, marginTop: 8}}></Image>
                </Stack.Item>
                <label
                    onClick={() => {
                        props.onHandleContainerStyle!(false);
                        navigate(sessionStateContext.currentApp.homepageUrl);
                    }}
                >
                    {sessionStateContext.currentApp.title}
                </label>
                <Stack.Item style={{float: 'right', paddingRight: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px'}}>
                    <CommandBar
                        items={[]}
                        overflowItems={isMobile ? commandBarItems : []}
                        overflowButtonProps={overflowProps}
                        buttonAs={CustomButton}
                        farItems={isMobile ? [] : commandBarItems}
                        ariaLabel="Inbox actions"
                        styles = {_styles}
                    />
                </Stack.Item>
            </Stack>
            <FeatureSearchBox showDialog={showSearchBox}
                              onSelectFeatute={handlePageSelection}
                              onCancelModal={() => setShowSearchBox(false)}/>
        </Layer>
    );

}